'use strict';

exports.__esModule = true;
exports.default = {
  el: {
    colorpicker: {
      "confirm": "កំណត់\n",
      "clear": "ជម្រះកាយ\n"
  },
    datepicker: {
      "now": "នៅពេលនេះ\n",
      "today": "ថ្ងៃនេះ\n",
      "cancel": "លុបចោល\n",
      "clear": "ជម្រះកាយ\n",
      "confirm": "កំណត់\n",
      "selectDate": "កាលបរិច្ឆេទជ្រើសរើស\n",
      "selectTime": "ជ្រើសរើសពេលវេលា\n",
      "startDate": "កាលបរិច្ឆេទចាប់ផ្តើម\n",
      "startTime": "ពេលវេលាចាប់ផ្តើម\n",
      "endDate": "កាលបរិច្ឆេទបញ្ចប់\n",
      "endTime": "ពេលវេលាបញ្ចប់\n",
      "prevYear": "ឆ្នាំមុន\n",
      "nextYear": "ឆ្នាំក្រោយ\n",
      "prevMonth": "ខែមុនតូសុផល\n",
      "nextMonth": "ខែក្រោយ\n",
      "year": "年",
      "month1": "មករា\n",
      "month2": "ខែកុម្ភៈ\n",
      "month3": "ខែមីនា\n",
      "month4": "ខែមេសា\n",
      "month5": "ខែឧសភា\n",
      "month6": "ខែមិថុនា\n",
      "month7": "ខែកក្កដា\n",
      "month8": "ខែសីហា\n",
      "month9": "ខែកញ្ញា\n",
      "month10": "ខែតុលា\n",
      "month11": "ខែវិច្ឆិកា\n",
      "month12": "ខែធ្នូ\n",
      // week: '周次',
      weeks: {
        sun: '日',
        mon: '一',
        tue: '二',
        wed: '三',
        thu: '四',
        fri: '五',
        sat: '六'
      },
      months: {
        "jan": "មករា\n",
        "feb": "ខែកុម្ភៈ\n",
        "mar": "ខែមីនា\n",
        "apr": "ខែមេសា\n",
        "may": "ឧសភា\n",
        "jun": "មិថុនា\n",
        "jul": "ខែកក្កដា\n",
        "aug": "សីហា\n",
        "sep": "ខែកញ្ញា\n",
        "oct": "ខែតុលា\n",
        "nov": "ខែវិច្ឆិកា\n",
        "dec": "ធ្នូ\n"
    }
    },
    select: {
      "loading": "ក្នុងការផ្ទុក\n",
      "noMatch": "ទិន្នន័យដែលមិនត្រូវគ្នា\n",
      "noData": "គ្មានទិន្នន័យ\n",
      "placeholder": "សូមជ្រើសរើស\n"
  },
    cascader: {
      "noMatch": "ទិន្នន័យដែលមិនត្រូវគ្នា\n",
      "loading": "ក្នុងការផ្ទុក\n",
      "placeholder": "សូមជ្រើសរើស\n",
      "noData": "ទិន្នន័យបណ្ដោះអាសន្ន\n"
  },
    pagination: {
      "goto": "ទៅកាន់\n",
      "pagesize": "របារ/ទំព័រ\n",
      "total": "សរុបមាត្រា{total}\n",
      "pageClassifier": "页"
  },
    messagebox: {
      "title": "ព័ត៌មានជំនួយ\n",
      "confirm": "កំណត់\n",
      "cancel": "លុបចោល\n",
      "error": "ទិន្នន័យដែលបានបញ្ចូលមិនស្របច្បាប់ទេ!\n"
  },
    upload: {
      "deleteTip": "ចុចDeleteKeyអាចលុបបាន\n",
      "delete": "លុប\n",
      "preview": "សូមទស្សនារូបភាព\n",
      "continue": "ផ្ទុកឡើងបន្ត\n"
  },
    table: {
      "emptyText": "ទិន្នន័យបណ្ដោះអាសន្ន\n",
      "confirmFilter": "Sieving\n",
      "resetFilter": "កំណត់ឡើងវិញ\n",
      "clearFilter": "ទាំងអស់\n",
      "sumText": "សរុប\n"
  },
    tree: {
      "emptyText": "ទិន្នន័យបណ្ដោះអាសន្ន\n"
  },
    transfer: {
      noMatch: "ទិន្នន័យដែលមិនត្រូវគ្នា\n",
      noData: "គ្មានទិន្នន័យ\n",
      titles: ['បញ្ជីរាយនាម១.\n', '"បញ្ជី២\n"'],
      filterPlaceholder: "សូមបញ្ចូលខ្លឹមសារស្វែងរក\n",
      noCheckedFormat: '共 {total} 项',
      hasCheckedFormat: 'បានជ្រើសរើស\n {checked}/{total} 项'
    },
    image: {
      "error": "បរាជ័យក្នុងការផ្ទុក\n"
  },
    pageHeader: {
      "title": "ត្រឡប់មកវិញ\n"
  },
    popconfirm: {
      "confirmButtonText": "កំណត់\n",
      "cancelButtonText": "លុបចោល\n"
  },
    empty: {
      "description": "ទិន្នន័យបណ្ដោះអាសន្ន\n"
  }
  }
};