export default {
  wecome:
    "မင်္ဂလာပါ, DeHONG နည်းပညာလွှဲပြောင်းဝန်ဆောင်မှုပလက်ဖောင်းမှလှိုက်လှဲစွာကြိုဆိုပါသည်!\n",
  login: "ဝင်ရောက်ခြင်း\n",
  nick: "အမည်ပြောင်\n",
  bind: "ကြိုးချည်ခြင်း\n",
  search: "ရှာဖွေခြင်း\n",
  qxzxyssdflsearch: "ရှာဖွေရန် လိုအပ်သော အမျိုးအစားကို ရွေးချယ်ပေးပါ\n",
  searchP: "ရွေးချယ်ပေးပါ\n",
  searchI: "အကြောင်းအရာကို ထည့်သွင်းပေးပါ\n",
  register: "မှတ်ပုံတင်ခြင်း\n",
  center: "ကိုယ်ပိုင်စင်တာ\n",
  zcxwtitle: "မူဝါဒသတင်း\n",
  tzgotitle: "အသိပေးကြေညာချက်\n",
  kjhztitle: "သိပ္ပံနှင့်နည်းပညာ ပူးပေါင်းဆောင်ရွက်မှု\n",
  kjcgtitle: "နည်းပညာရလဒ်များ\n",
  kjjrtitle: "နည်းပညာ ဘဏ္ဍာရေး\n",
  zjzktitle: "ကျွမ်းကျင်ပညာရှင်များဆိုင်ရာ အကြံပေးအဖွဲ့\n",
  zjzkftitle: "အထူးကု ပညာပေးအဖွဲ့ စိစစ်\n",
  kjcgftitle: "သိပ္ပံနှင့်နည်းပညာရလဒ်များကိုစိစစ်ခြင်း\n",
  jsxqtitle: "နည်းပညာလိုအပ်ချက်\n",
  jsxqftitle: "နည်းပညာလိုအပ်ချက် စိစစ်ခြင်း\n",
  jslinytitle: "နည်းပညာနယ်ပယ်\n",
  fbxqtitle: "လိုအပ်ချက် ထုတ်ပြန်ခြင်း\n",
  wxxztitle: "စာပေ Download\n",
  zbdwname: "ကျင်းပသည့်ဌာန\n",
  dhname: "တယ်လီဖုန်း\n",
  jszcname: "နည်းပညာပံ့ပိုးမှု\n",
  dzname: "လိပ်စာ\n",
  syname: "ပင်မစာမျက်နှာ\n",
  fbcg: "အကျိုးရလဒ်ထုတ်ပြန်ခြင်း\n",
  xmsb: "စီမံကိန်းစီမံခန့်ခွဲမှု\n",
  sbtz: "ကြေညာအသိပေးခြင်း\n",
  all: "အားလုံး\n",
  in_progress: "ဆောင်ရွက်ခြင်း\n",
  stop: "ပိတ်လိုက်ပြီ\n",
  notopen: "အစ မပြုခြင်း\n",
  expired: "သက်တမ်းကုန်ဆုံးနေခြင်း\n",
  kjtj_title: "သိပ္ပံနှင့်နည်းပညာဆိုင်ရာ စာရင်းအင်း\n",
  lilantitle: "ကြည့်ရှုခြင်း\n",
  layuantitle: "Source\n",
  lilanltitle: "ကြည့်ရှုခြင်း\n",
  timetitle: "အချိန်\n",
  zwtitle: "စာသား\n",
  fbsjtitle: "ထုတ်ပြန်သည့်အချိန်\n",
  hyfltitle: "လုပ်ငန်းကဏ္ဍခွဲခြားခြင်း\n",
  zjjjtitle: "ကျွမ်းကျင်သူ ကိုယ်ရေးအကျဉ်း\n",
  lxzjtitle: "ကျွမ်းကျင်သူများကို ဆက်သွယ်ရန်\n",
  xlxwtitle: "ပညာအရည်အချင်း/ဘွဲ့\n",
  zczwtitle: "ရာထူးအဆင့် ရာထူး\n",
  xmtitle: "အမည်\n",
  fjtitle: "ပူးတွဲ\n",
  xqtitle: "အသေးစိတ်\n",
  hylbtitle: "လုပ်ငန်းအမျိုးအစား\n",
  jssptitle: "နည်းပညာအဆင့်\n",
  lxdhtitle: "ဆက်သွယ်ရန်ဖုန်းနံပါတ်\n",
  rcktitle: "လူကြည့်\n",
  ljcktitle: "စုပေါင်းကြည့်ရှုခြင်း\n",
  zrfstitle: "လွှဲပြောင်းပုံ\n",
  zscqzttitle: "အသိဉာဏ်ပစ္စည်းမူပိုင်ခွင့်အခြေအနေ\n",
  zlmcttitle: "မူပိုင်ခွင့်အမည်\n",
  cgszdwttitle: "အသီးအပွင့်တည်ရှိရာဌာန\n",
  cgjjttitle: "ရလဒ် အကျဉ်းချုပ်\n",
  zlhttitle: "မူပိုင်အမှတ်\n",
  yxtitle: "စာတိုက်ပုံး\n",
  xqjjtitle: "လိုအပ်ချက် အကျဉ်းချုပ်\n",
  lxtitle: "ဆက်သွယ်ရန်\n",
  my: "တိုင်ပင်ဆွေးနွေးခြင်း\n",
  jg: "ဈေးနှုန်း\n",
  mslxtitle: "ချက်ချင်းဆက်သွယ်ရန်\n",
  swdlsfjx: "Log in မဝင်ရသေးပါ၊ Log in ဝင်ရန် သွားပါ၊ ဆက်လုပ်ပါသလား။\n",
  ts: "သိကောင်းစရာ\n",
  ok: "အဆုံးအဖြတ်\n",
  successs: "အောင်မြင်ခြင်း\n",
  cancel: "ဖျက်သိမ်း\n",
  qsrnxsdh: "ပြောချင်တဲ့ စကားကို ရိုက်ထည့်ပေးပါ\n",
  lxcg: "ဆက်သွယ်ရန် အောင်မြင်မှု!\n",
  lycghxgryhqdlx:
    "မက်ဆေ့ခ်ျကိုအောင်မြင်ပြီးနောက်သက်ဆိုင်ရာကသင်နှင့်အတူအဆက်အသွယ်ရရှိပါလိမ့်မယ်, ကျေးဇူးပြုပြီးဖုန်းကိုဖွင့်ထားပါ, ဆက်လက်ရှိမရှိ?\n",
  qyyjkfxmqk_title: "လုပ်ငန်းဆိုင်ရာသုတေသနဖွံ့ဖြိုးရေးစီမံကိန်းအခြေအနေ\n",
  qyyjkfhdjxgqktitle:
    "လုပ်ငန်းဆိုင်ရာ သုတေသနဖွံ့ဖြိုးရေးလုပ်ငန်းများနှင့် ဆက်စပ်အခြေအနေများ\n",
  qsrsjhmtitle: "မိုဘိုင်းဖုန်းနံပါတ်ကို ထည့်သွင်းပေးပါ\n",
  qsrdlzhtitle: "ဝင်ရောက်ရန် အကောင့်ကို ထည့်သွင်းပေးပါ\n",
  qsrdlmmtitle: "ဝင်ရောက်ရန် စကားဝှက်ကို ရိုက်ထည့်ပေးပါ\n",
  wecomestitle: "ကြိုဆိုပါတယ်!\n",
  cd3d5title: "စာလုံးရေ ၃ လုံးမှ ၁၅ လုံးအထိ ရှည်လျားသည်\n",
  qsrmmtitle: "စကားဝှက်ကို ထည့်ပေးပါ\n",
  wjmmtitle: "စကားဝှက်ကို မေ့ခြင်း\n",
  fhsytitle: "အဖွင့်စာမျက်နှာ ပြန်သွားရန်\n",
  grzctitle: "ကိုယ်ပိုင် မှတ်ပုံတင်ခြင်း\n",
  jgzctitle: "အေဂျင်စီ မှတ်ပုံတင်\n",
  jjxzgzytitle: "အဆိုပါသယံဇာတကို download လုပ်တော့မည်, ဆက်လက်ရှိမရှိ?\n",
  sjhmtitle: "မိုဘိုင်းဖုန်းနံပါတ်\n",
  szmmtitle: "စကားဝှက် သတ်မှတ်ခြင်း\n",
  qszmmtitle: "စကားဝှက်ထားပေးပါ\n",
  qrmmtitle: "စကားဝှက်ကို အတည်ပြုခြင်း\n",
  xmmtitle: "စကားဝှက်ကို အတည်ပြုခြင်း\n",
  szdqtitle: "တည်ရှိရာဒေသ\n",
  qtxyzmtitle: "CAPTCHA ကိုဖြည့်ပေးပါ\n",
  qxzzztitle: "ရွေးချယ်ပေးပါ\n",
  yzmtitle: "CAPTCHA\n",
  ljzctitle: "ချက်ချင်း မှတ်ပုံတင်ခြင်း\n",
  qxzsftitle: "ပြည်နယ်များကို ရွေးချယ်ပေးပါ\n",
  qxzcstitle: "မြို့ကို ရွေးချယ်ပေးပါ\n",
  qxzqytitle: "ဒေသကို ရွေးချယ်ပေးပါ\n",
  lcsrmmbyytitle: "စကားဝှက်ကို နှစ်ကြိမ်ရိုက်ထည့်ခြင်းသည် မတူညီပါ\n",
  hqyzmtitle: "CAPTCHA ရယူရန်\n",
  zccgtitle: "မှတ်ပုံတင်ခြင်း အောင်မြင်\n",
  jglxtitle: "အဖွဲ့အစည်းအမျိုးအစား ( ၃ ဦးအထိရွေးချယ်ပါ)\n",
  qyttitle: "လုပ်ငန်း\n",
  kyttitle: "သိပ္ပံသုတေသန\n",
  dxttitle: "တက္ကသိုလ်\n",
  fwttitle: "ဝန်ဆောင်မှု\n",
  tzttitle: "ရင်းနှီးမြှုပ်နှံ\n",
  tjttitle: "တင်သွင်းခြင်း\n",
  jgmctitle: "အေဂျင်စီအမည်\n",
  qsrjgmctitle: "အေဂျင်စီအမည်ကို ရိုက်ထည့်ပေးပါ\n",
  qzsxzyzjglxtitle: "အနည်းဆုံး အဖွဲ့အစည်း အမျိုးအစားကို ရွေးချယ်ပေးပါ\n",
  zdxzsgjglxtitle: "အဖွဲ့အစည်း အမျိုးအစား သုံးခုအထိ ရွေးချယ်ပါ\n",
  cdw11gzftitle: "အရှည် စာလုံးရေ ၁၁ လုံး\n",
  zzczmmtitle: "Reset Password လုပ်နေတယ်၊ ဆက်လုပ်နေလား။\n",
  sbtz_title: "ကြေညာအသိပေးခြင်း\n",
  wsj_title: "အချက်အလက်မဲ့\n",
  fbbm_title: "ထုတ်ပေးရေးဌာနခွဲ\n",
  lx_title: "အမျိုးအစား\n",
  zt_title: "အခြေအနေ\n",
  kssj_title: "စတင်ချိန်\n",
  jzsj_title: "ပိတ်ချိန်\n",
  more: "ပိုပို\n",
  fb: "ထုတ်ပြန်ခြင်း\n",
  zhi: "至",
  djsb: "ကြေညာရန် နှိပ်ပါ\n",
  ljdh_title: "လျင်မြန်သော သွားလာမှု\n",
  jscgname_table: "နည်းပညာရလဒ် အမည်\n",
  ssdwname_table: "ပိုင်ယူနစ်\n",
  szdwname_table: "တည်ရှိရာဌာန\n",
  hylbname_table: "လုပ်ငန်းအမျိုးအစား\n",
  cgspname_table: "ရလဒ်အဆင့်\n",
  csdname_table: "ရင့်ကျက်မှု\n",
  fbsjname_table: "ထုတ်ပြန်သည့်အချိန်\n",
  kjxq_title: "နည်းပညာ လိုအပ်ချက်များ\n",
  xglj_title: "ဆက်စပ်လင့်ခ်များ\n",
  xl_title: "ပညာအရည်အချင်း\n",
  dw_title: "ယူနစ်\n",
  zjgdxx_title: "ကျွမ်းကျင်သူများ ပိုမိုသိရှိရန်\n",
  zw_title: "ရာထူး\n",
  hy_title: "စက်မှုလုပ်ငန်း\n",
  hylb_title: "လုပ်ငန်းအမျိုးအစား\n",
  szd_title: "တည်နေရာ\n",
  dwmc_title: "ယူနစ်အမည်\n",
  xmmc_title: "ပစ္စည်းအမည်\n",
  fbsj_title: "ထုတ်ပြန်သည့်အချိန်\n",
  ly_title: "နယ်ပယ်\n",
  lll_title: "ကြည့်ရှုခြင်း\n",
  zrfs_title: "လွှဲပြောင်းပုံ\n",
  cgsp_title: "ရလဒ်အဆင့်\n",
  sfcj_title: "အရောင်းအဝယ် ရှိမရှိ\n",
  lxr_title: "အဆက်အသွယ်\n",
  tyshxydmtitle: "ယူနီဖောင်း လူမှုအကြွေးကုဒ်\n",
  yxqtitle: "သက်တမ်း\n",
  ztitle: "至",
  xmlytitle: "စီမံကိန်းရင်းမြစ်များ\n",
  qxzlytitle: "ပစ္စည်းအရင်းအမြစ်ကို ရွေးချယ်ပေးပါ\n",
  xmlkzxstitle: "စီမံကိန်းဆောင်ရွက်မှုပုံစံ\n",
  qsrxmmcname_table: "ပစ္စည်းအမည်ကို ရိုက်ထည့်ပေးပါ\n",
  qsryjkfrs_table: "သုတေသနဖွံ့ဖြိုးမှု အရေအတွက်ကို ထည့်သွင်းပေးပါ\n",
  qxzjzjd_table: "တိုးတက်မှုအဆင့်ကို ရွေးချယ်ပေးပါ\n",
  qxzxmlkzxstitle: "စီမံကိန်း ဆောင်ရွက်သည့် ပုံစံကို ရွေးချယ်ပေးပါ\n",
  xmdncgxstitle: "စီမံကိန်း၏ ထိုနှစ် အကျိုးရလဒ်ပုံစံ\n",
  qxzxmdncgxstitle:
    "ကျေးဇူးပြု၍ စီမံကိန်း၏ ထိုနှစ်အတွက် အသီးအပွင့်ပုံစံကို ရွေးချယ်ပါ\n",
  xmjsjjmbtitle: "စီမံကိန်း နည်းပညာ စီးပွားရေး ရည်မှန်းချက်\n",
  qxzxmjsjjmbtitle:
    "ကျေးဇူးပြု၍ စီမံကိန်းကို ရွေးချယ်ပါ နည်းပညာ စီးပွားရေး ရည်မှန်းချက်\n",
  xmqsrqtitle: "စီမံကိန်းစတင်မည့်ရက်\n",
  qxzxmqsrqtitle: "ပစ္စည်း စတင်မည့် ရက်ကို ရွေးချယ်ပေးပါ\n",
  xmwcrqtitle: "စီမံကိန်း ပြီးစီးမည့်ရက်\n",
  qxzxmwcrqtitle: "ပစ္စည်း ပြီးစီးမည့် ရက်ကို ရွေးချယ်ပေးပါ\n",
  knxmdlsczyjzjdtitle:
    "နှစ်ရှည်စီမံကိန်း၏ အဓိကတိုးတက်မှုအဆင့်များ အဆိုပါနှစ်တွင်\n",
  qxzknxmdlsczyjzjdtitle:
    "ကျေးဇူးပြု၍ နှစ်ရှည် စီမံကိန်းကို ထိုနှစ်တွင် အဓိက တိုးတက်မှု အဆင့်ကို ရွေးချယ်ပါ\n",
  xmyjkfrytitle: "စီမံကိန်း သုတေသန တီထွင်သူ (လူသား)\n",
  qsrxmyjkfrytitle: "ပရောဂျက် သုတေသန တီထွင်သူ (လူသား) ကို ရိုက်ထည့်ပေးပါ\n",
  xmrysjgzsjtitle: "စီမံကိန်း ဝန်ထမ်းများ လက်တွေ့ အလုပ်လုပ်ချိန် (လ)\n",
  qsrxmrysjgzsjtitle: "ဝန်ထမ်းများ၏ အလုပ်ချိန်ကို ထည့်သွင်းပေးပါ\n",
  xmjfzctitle: "စီမံကိန်းရန်ပုံငွေအသုံးစရိတ် (ကျပ်သိန်း)\n",
  qsrxmjfzctitle: "ပစ္စည်းရန်ပုံငွေ အသုံးစရိတ်ကို ထည့်သွင်းပေးပါ\n",
  dwfzrtitle: "ယူနစ်တာဝန်ခံ\n",
  tbrtitle: "ဖြည့်စွက်သူ\n",
  tbrqtitle: "ဖြည့်ရမည့်ရက်\n",
  qxztbrqtitle: "ဖြည့်စွက်ရမည့် ရက်ကို ရွေးချယ်ပေးပါ\n",
  tjbtn: "တင်သွင်းခြင်း\n",
  zbmctitle: "ညွှန်းကိန်းအမည်\n",
  jldwtitle: "အတိုင်းအတာ ယူနစ်\n",
  dmtitle: "ကုဒ်နံပါတ်\n",
  sltitle: "ပမာဏ\n",
  jiatitle: "甲",
  yititle: "乙",
  bingtitle: "丙",
  yjkfryqk1title: "၁၊ သုတေသန တီထွင်သူများ အခြေအနေ\n",
  yjkfryhj1title: "သုတေသန တီထွင်သူ စုစုပေါင်း\n",
  qzglhfwry1title: "သူတို့ထဲက: စီမံခန့်ခွဲမှုနှင့်ဝန်ဆောင်မှုဝန်ထမ်းများ\n",
  qzglx1title: "၎င်းတို့အနက် အမျိုးသမီး\n",
  qzqzry1title: "သူတို့ထဲက: အချိန်ပြည့်ဝန်ထမ်း\n",
  qzbkbyjysry1title: "၎င်းတို့တွင် ဘွဲ့ကြိုဘွဲ့ရနှင့်အထက် အရာထမ်းများ\n",
  qzwpry1title: "သူတို့ထဲက: ပြင်ပဝန်ထမ်း\n",
  r1title: "人",
  yjkffyqk2title: "၂၊ ဖွံ့ဖြိုးမှုကုန်ကျစရိတ် အခြေအနေ သုတေသန\n",
  yjkffyhj2title: "သုတေသနဖွံ့ဖြိုးမှုကုန်ကျစရိတ် စုစုပေါင်း\n",
  ryrgfy2title: "1.ဝန်ထမ်းများအတွက် ကုန်ကျစရိတ်\n",
  zjtrfy2title: "၂.တိုက်ရိုက် သွင်းအားစု ကုန်ကျစရိတ်\n",
  zjfyycqdtfy2title:
    "၃။တန်ဖိုးလျော့ ကုန်ကျစရိတ် နှင့် ရေရှည် စရိတ်မျှ ကုန်ကျစရိတ်\n",
  wxzctxfy2title: "၄.ပစ္စည်းမဲ့ ရောင်းချခြင်း ကုန်ကျစရိတ်\n",
  sjfy2title: "၅. ဒီဇိုင်းကုန်ကျစရိတ်\n",
  zbtsfyysyfy2title:
    "၆.စမ်းသပ်မှုကုန်ကျစရိတ်များနှင့်အတူပစ္စည်းကိရိယာဘာသာရပ်ဆိုင်ရာကုန်ကျစရိတ်\n",
  wttsfyysyfy2title: "7.ပြင်ပ သုတေသန ဖွံ့ဖြိုးရေး ကုန်ကျစရိတ် လွှဲအပ်ခြင်း\n",
  wtjlyjjg2title: "တာဝန်ပေးအပ်ရာ ပြည်တွင်းသုတေသနဌာနများ\n",
  wtjlgdxx2title: "တာဝန်အတွင်း အဆင့်မြင့်ကျောင်းများ\n",
  wtjlqy2title: "ပြည်တွင်းစီးပွားရေးလုပ်ငန်းလွှဲအပ်\n",
  wtjwjg2title: "ပြင်ပအေဂျင်စီတာဝန်ပေး\n",
  qtfy2title: "၈ အခြားကုန်ကျစရိတ်များ\n",
  qy2title: "ကျပ်သိန်း\n",
  yjkfzcqk3title:
    "၃၊ ဖွံ့ဖြိုးတိုးတက်မှု ပိုင်ဆိုင်မှုအခြေအနေများကို လေ့လာခြင်း\n",
  dnxcyyyjkfdgdzc3title:
    "ထိုနှစ်တွင် သုတေသနဖွံ့ဖြိုးရေးအတွက် ဖွဲ့စည်းခဲ့သော ပုံသေပိုင်ပစ္စည်းများ\n",
  qizyqhsb3title: "သူတို့ထဲက: တူရိယာများနှင့်ပစ္စည်းကိရိယာများ\n",
  yjkfzczjly4title: "၄၊ သုတေသနဖွံ့ဖြိုးရေးအသုံးစရိတ် ရန်ပုံငွေအရင်းအမြစ်များ\n",
  lzqyzc4title: "1.လုပ်ငန်း ကိုယ်ပိုင်ရှာဖွေရေးမှ\n",
  lzzfbm4title: "၂.အစိုးရဌာနမှ\n",
  lzyhdk4title: "၃။ဘဏ်ချေးငွေများမှ\n",
  lzfxtz4title: "၄.အကျိုးတူ ရင်းနှီးမြှုပ်နှံမှု မှ\n",
  lzqtqd4title: "5.အခြားလမ်းကြောင်းများမှ\n",
  xgzctsqk5title:
    "၅ ချက်၊ သက်ဆိုင်ရာ မူဝါဒများ အကောင်အထည်ဖော် ဆောင်ရွက်မှု အခြေအနေ\n",
  sbjjkc5title:
    "အခွန်ကင်းလွတ်ခွင့် လျှော့ချပေးသည့် သုတေသနဖွံ့ဖြိုးရေး အသုံးစရိတ်များကို ကြေညာခြင်း\n",
  jjkcjmsje5title: "ကင်းလွတ်ခွင့် သက်သာသည့် ငွေပမာဏကို ပေါင်းနုတ်ခြင်း\n",
  gxjsqyjmsje5title:
    "နည်းပညာမြင့်လုပ်ငန်းများ အခွန်ကင်းလွတ်ခွင့် ပမာဏ လျှော့ချ\n",
  qybyjkfjg6title:
    "၆ လုပ်ငန်းဆောင်ရွက်မှု သုတေသနဖွံ့ဖြိုးရေးအေဂျင်စီ (ပြည်တွင်း) အခြေအနေ\n",
  qmjgsg6title: "နောက်ဆုံး အဖွဲ့အရေအတွက်\n",
  jgyjkfry6title: "အေဂျင်စီ သုတေသန တီထွင်သူ\n",
  qzbsby6title: "၎င်းတို့အနက် ဆရာဝန်ဘွဲ့ရ\n",
  ssby6title: "မဟာဘွဲ့ရ\n",
  jgyjkffy6title: "အဖွဲ့အစည်း သုတေသနဖွံ့ဖြိုးရေး ကုန်ကျစရိတ်\n",
  qmyqhsbyj6title: "ကာလပေါက်ဈေး တူရိယာနှင့် ပစ္စည်းကိရိယာများ မူလဈေးနှုန်း\n",
  yjkfccjxgqk7title:
    "ခုနှစ်၊ သုတေသနဖွံ့ဖြိုးတိုးတက်မှု ထွက်ရှိမှုနှင့် ဆက်စပ်အခြေအနေများကို\n",
  zlqk7title: "(ဈ) မူပိုင်ခွင့်ဆိုင်ရာအခြေအနေ။\n",
  dlzlsqs7title: "ထိုနှစ်အတွက် မူပိုင်ခွင့်လျှောက်လွှာအရေအတွက်\n",
  qzfmzl7title: "၎င်းတို့တွင် - တီထွင်မှု မူပိုင်ခွင့်\n",
  qmyxfmzls7title: "နောက်ဆုံးအဆင့် ထိရောက်သော တီထွင်မှု မူပိုင်ခွင့်နံပါတ်\n",
  qzybss7title: "၎င်းတို့အနက် - အကောင်အထည်ဖော်ခဲ့ပြီး\n",
  zlsyqzrjxks7title:
    "မူပိုင်ခွင့်ပိုင်ဆိုင်မှု လွှဲပြောင်းခြင်းနှင့် လိုင်စင်အရေအတွက်\n",
  zlsyqxksr7title:
    "မူပိုင်ခွင့်ပိုင်ဆိုင်မှု လွှဲပြောင်းခြင်းနှင့် လိုင်စင်ရငွေများ\n",
  xcpqk7title: "စျ) ထုတ်ကုန်သစ်များအခြေအနေ။\n",
  xcpxssr7title: "ကုန်ပစ္စည်းသစ် ရောင်းရငွေ\n",
  qzck7title: "၎င်းတို့တွင် - ပို့ကုန်\n",
  qtqk7title: "( ၃ ) အခြားကိစ္စများ။\n",
  qmzcsb7title: "မှတ်ပုံတင်ထားသော ကုန်အမှတ်တံဆိပ်ပိုင်ဆိုင်ခြင်း ကာလအဆုံး\n",
  fbkjlw7title: "သိပ္ပံနှင့်နည်းပညာစာတမ်း ထုတ်ဝေ\n",
  xcgjhhybz7title: "အမျိုးသားရေးသို့မဟုတ်စက်မှုလုပ်ငန်းစံဖွဲ့စည်း\n",
  jian7title: "件",
  pian7title: "篇",
  xiang7title: "项",
  qtxgqk8title: "၈၊ အခြားဆက်စပ်အခြေအနေများ\n",
  jsgchjshqqk8title:
    "စ) နည်းပညာပြုပြင်ပြောင်းလဲမှုနှင့် နည်းပညာရရှိမှုအခြေအနေ။\n",
  jsgzjfzc8title: "နည်းပညာ ပြုပြင်ပြောင်းလဲရေး ရန်ပုံငွေ အသုံးစရိတ်\n",
  gmjljsjfzc8title: "နယ်တွင်း နည်းပညာရန်ပုံငွေ အသုံးစရိတ်များ ဝယ်ယူခြင်း\n",
  yjjwjsjfzc8title: "ပြင်ပ နည်းပညာ ရန်ပုံငွေ အသုံးစရိတ်များ မိတ်ဆက်\n",
  yjjwjsdxhxs8title:
    "နယ်ပြင်ပ နည်းပညာများ အစာခြေစုပ်ယူမှု ရန်ပုံငွေ အသုံးစရိတ်များ မိတ်ဆက်\n",
  qybyjkfjg8title:
    "(ဆ) လုပ်ငန်းဌာနခွဲ သုတေသနနှင့် ဖွံ့ဖြိုးရေးအေဂျင်စီ (ပြင်ပ) အခြေအနေ။\n",
  qmqyzjwsldyjkfjgs8title:
    "နယ်နိမိတ်ပြင်ပရှိ နောက်ဆုံးအဆင့် စီးပွားရေးလုပ်ငန်းများ တည်ထောင်ထားရှိသော သုတေသန ဖွံ့ဖြိုးရေး အေဂျင်စီ အရေအတွက်\n",
  ge8title: "个",
  dhtabletitledh:
    "DeHONG နည်းပညာလွှဲပြောင်းပလက်ဖောင်း သိပ္ပံနှင့်နည်းပညာရလဒ်များ လိုအပ်ချက်တောင်းခံလွှာ\n",
  bhdh: "နံပါတ်\n",
  txsjdh: "အချိန်ဖြည့်ပါ\n",
  dwxxdh: "တ၊ ယူနစ် သတင်းအချက်အလက်\n",
  dwmcdh: "ယူနစ်အမည်\n",
  fddbrdh: "တရားဝင်ကိုယ်စားလှယ်\n",
  lxyxdh: "ဆက်သွယ်ရန် စာတိုက်ပုံး\n",
  lxrdh: "အဆက်အသွယ်\n",
  lxdhdh: "ဆက်သွယ်ရန်ဖုန်းနံပါတ်\n",
  dwrygkdh: "ဌာနဆိုင်ရာ ဝန်ထမ်းများ၏ ကိုယ်ရေးအချက်အလက်များ\n",
  zzrydh: "တာဝန်ထမ်းဆောင်နေသော ဝန်ထမ်း\n",
  dzysjsrcdh: "မဟာဌာန အထက်တန်း နည်းပညာ ပါရမီ\n",
  qzbsdh: "ယင်းတို့အနက် ဒေါက်တာ\n",
  zscqyydh: "အသိဉာဏ်ပစ္စည်းပိုင်ဆိုင်မှု / ရင်းမြစ်များ\n",
  wgsjzldh: "အသွင်အပြင်ဒီဇိုင်းမူပိုင်ခွင့်\n",
  syxzldh: "လက်တွေ့ကျသော မူပိုင်ခွင့်\n",
  fmzldh: "တီထွင်မှု မူပိုင်ခွင့်\n",
  rzqdh: "အပျော့စားစာရေးဆရာ\n",
  gmdh: "ဝယ်ယူခြင်း\n",
  zzyfdh: "ကိုယ်ပိုင် သုတေသနနှင့် ဖွံ့ဖြိုးရေး\n",
  hzkfdh: "ပူးပေါင်း ဖွံ့ဖြိုးရေး\n",
  qtdh: "အခြား\n",
  sfwgxjsqydh: "ရှိမရှိ အဆင့်မြင့်နည်းပညာလုပ်ငန်း\n",
  shidh: "是",
  szsbh: "လက်မှတ်အမှတ် ဖြစ်သည်\n",
  foubh: "否",
  sfwkjxzxqybh: "ရှိမရှိ နည်းပညာသုံး အသေးစားနှင့် အလတ်စား စီးပွားရေးလုပ်ငန်း\n",
  qtlxscztbh: "စျေးကွက် အခြားအမျိုးအစားများ\n",
  sfjscxcyptbh:
    "ဆန်းသစ်တီထွင်မှု စွန့်ဦးတီထွင်မှု ပလက်ဖောင်းများ တည်ဆောက်ခြင်း ရှိ၊ မရှိ\n",
  zckjbh: "ဖန်ဆင်းရှင် အာကာသ\n",
  xctdbh: "ဖန်ဆင်းရှင်အသစ်\n",
  qyjszxbh: "လုပ်ငန်းနည်းပညာစင်တာ\n",
  gcyjzxbh: "အင်ဂျင်နီယာ သုတေသနဌာန\n",
  jscxzxbh: "နည်းပညာတီထွင်ဆန်းသစ်မှုဗဟိုဌာန\n",
  kjqyfhqbh: "နည်းပညာလုပ်ငန်း Incubator\n",
  snjjyxxbh:
    "ဒုတိယအချက်၊ ယခင်နှစ် လုပ်ငန်းဆောင်ရွက်မှုဆိုင်ရာ သတင်းအချက်အလက် (ဝမ်\n",
  zzcbh: "စုစုပေါင်းပိုင်ဆိုင်မှု\n",
  jzcbh: "အသားတင်ပိုင်ဆိုင်မှု\n",
  yysrbh: "营业收入",
  zyftrbh: "သုတေသနနှင့် ဖွံ့ဖြိုးရေး သွင်းအားစုများ\n",
  zyysrbh: "အဓိကစီးပွားရေးဝင်ငွေ\n",
  jlrbh: "အသားတင်အမြတ်\n",
  yftrbh: "သုတေသနနှင့် ဖွံ့ဖြိုးရေး သွင်းအားစုများ\n",
  ydwswybh: "၁-၅ သိန်းယွမ်\n",
  wsdwbwybh: "ယွမ် ၅၀-၅ သန်း\n",
  wbdwbwybh: "၅၀၀-၁၀ သိန်း ယွမ်\n",
  yqwwbwybh: "ယွမ် ၁၀ သန်းအထက်\n",
  yftrzyysrbblbh:
    "သုတေသနနှင့် ဖွံ့ဖြိုးရေး သွင်းအားစုများ လုပ်ငန်းဝင်ငွေ အချိုးအစား\n",
  bfz1bh: "၁ ရာခိုင်နှုန်း\n",
  bfz1d3bh: "၁% - ၃%\n",
  bfz3d5bh: "၃%-၅%\n",
  bfz5d10bh: "၅% - ၁၀%\n",
  bfz10bh: "၁၀ ရာခိုင်နှုန်းကျော်\n",
  zyywjgxjscpbh: "အဓိကစီးပွားရေးလုပ်ငန်းနှင့် နည်းပညာမြင့်ထုတ်ကုန် (Service)\n",
  sfhkjzxjjbh: "နည်းပညာ အထူးရန်ပုံငွေ ရရှိခြင်း ရှိမရှိ\n",
  gjsbh: "တိုင်း/ပြည်နယ်\n",
  sfyhjgtzbh: "အဖွဲ့အစည်းများ၏ ရင်းနှီးမြှုပ်နှံမှု ရရှိထားခြင်း ရှိမရှိ\n",
  ljbh: "စုမီတာ\n",
  jgtzjewybh: "အဖွဲ့အစည်းများ ရင်းနှီးမြှုပ်နှံမှုပမာဏ ဝမ်\n",
  cpjbqkbh: "၃၊ ထုတ်ကုန်အခြေခံအခြေအနေ\n",
  cpmcbh: "ကုန်ပစ္စည်းအမည်\n",
  ssjslybh: "အမျိုးအစား နည်းပညာနယ်ပယ်\n",
  dzxxbh: "အီလက်ထရောနစ် သတင်းအချက်အလက်\n",
  rgzlbh: "ဉာဏ်ရည်တု\n",
  zinzzgdzbbh: "စမတ်ကုန်ထုတ်လုပ်မှု high-end ဂီယာ\n",
  qtbh: "အခြား\n",
  xdlybh: "ခေတ်မီစိုက်ပျိုးရေး\n",
  gjsfubh: "နည်းပညာမြင့် ဝန်ဆောင်မှု\n",
  xnyjjnjsbh: "စွမ်းအင်သစ်နှင့် စွမ်းအင်ချွေတာရေး နည်းပညာ\n",
  hkhtjsbh: "လေကြောင်းနှင့် အာကာသ နည်းပညာ\n",
  zyyhjjsbh: "သယံဇာတနှင့် သဘာဝပတ်ဝန်းကျင် နည်းပညာ\n",
  xclbh: "ပစ္စည်းအသစ်\n",
  yyhybh: "အသုံးချလုပ်ငန်း\n",
  xtrhbh: "သတင်းအချက်အလက်၊ ဆက်သွယ်ရေး၊ ဆော့ဖ်ဝဲ၊ အင်တာနက်\n",
  llmybh: "ငါးဖမ်းသမား\n",
  wshshgzbh: "ကျန်းမာရေးနှင့် လူမှုရေးလုပ်ငန်းများ\n",
  jtyscchyzbh: "သယ်ယူပို့ဆောင်ရေး၊ ကုန်လှောင်ရေးနှင့် စာတိုက်လုပ်ငန်း\n",
  ckybh: "သတ္တုတွင်းလုပ်ငန်း\n",
  szjzbh: "စည်ပင်သာယာ အဆောက်အအုံ\n",
  slhjhgyssglbh:
    "ရေ၊ သဘာဝပတ်ဝန်းကျင်နှင့် အသုံးအဆောင်ပစ္စည်းများ စီမံခန့်ခွဲမှု\n",
  kxyjhjsfwybh: "သိပ္ပံသုတေသနနှင့် နည်းပညာဝန်ဆောင်မှုလုပ်ငန်း\n",
  zcybh: "ကုန်ထုတ်လုပ်မှု\n",
  dlrlrqjscshgybh:
    "လျှပ်စစ်စွမ်းအား၊ အပူစွမ်းအား၊ သဘာဝဓာတ်ငွေ့နှင့် ရေထုတ်လုပ်ဖြန့်ဖြူးရေး\n",
  gjzzbh: "အပြည်ပြည်ဆိုင်ရာ အဖွဲ့အစည်းများ\n",
  hgbh: "ဓာတုဗေဒ\n",
  ggglshbzhshzzbh:
    "ပြည်သူ့အုပ်ချုပ်ရေး၊ လူမှုဖူလုံရေးနှင့် လူမှုအဖွဲ့အစည်းများ\n",
  zphswfwbh: "ငှားရမ်းခြင်းနှင့် စီးပွားရေး ဝန်ဆောင်မှု\n",
  jybh: "ပညာရေး\n",
  yysxbh: "အသုံးချ ဂုဏ်သတ္တိများ\n",
  yysxcxbh: "မူလတီထွင်ဆန်းသစ်မှု\n",
  jseckfbh: "ဒုတိယအကြိမ် နည်းပညာဖွံ့ဖြိုးတိုးတက်မှု\n",
  jsyjzcxbh: "နည်းပညာမိတ်ဆက် ပြန်လည်ဆန်းသစ်တီထွင်မှု\n",
  zhcxbh: "ပေါင်းစပ်တီထွင်မှု\n",
  yfjdbh: "သုတေသနနှင့် ဖွံ့ဖြိုးရေးအဆင့်\n",
  yfzbh: "သုတေသနနှင့် ဖွံ့ဖြိုးရေး\n",
  xsjdbh: "ပထမအဆင့်\n",
  zsbh: "အလယ်အလတ်စာမေးပွဲ\n",
  ypbh: "နမူနာ\n",
  xplbh: "ပမာဏငယ်\n",
  kcyhbh: "စက်မှုလုပ်ငန်း\n",
  kjcgxmxqbbh:
    "စတုတ္ထအကြိမ် သိပ္ပံနှင့်နည်းပညာ အကျိုးရလဒ် စီမံကိန်း လိုအပ်ချက်အခြေအနေ\n",
  fwxqbh: "ဝန်ဆောင်မှုလိုအပ်ချက်\n",
  jsrcyjbh: "နည်းပညာစွမ်းရည် မိတ်ဆက်\n",
  cgzhyxmhzbh: "ရလဒ်ပြောင်းလဲခြင်းနှင့် စီမံကိန်းပူးပေါင်းဆောင်ရွက်ခြင်း\n",
  zljskfgmbh: "မူပိုင်ခွင့်နည်းပညာဖွံ့ဖြိုးရေး / ဝယ်ယူခြင်း\n",
  xcpyfbh: "နယူးထုတ်ကုန်များ သုတေသနနှင့် ဖွံ့ဖြိုးရေး\n",
  qypxbh: "လုပ်ငန်းသင်တန်း\n",
  scglbh: "ထုတ်လုပ်မှု စီမံခန့်ခွဲရေး\n",
  yxchbh: "Marketing Planning\n",
  zyjnbh: "အလုပ်အကိုင် ကျွမ်းကျင်မှု\n",
  xljybh: "ပညာအရည်အချင်း ပညာရေး\n",
  qyzxbh: "လုပ်ငန်းပြောင်းလဲခြင်း\n",
  xyjsjcqkbh: "ရှိပြီးသား နည်းပညာအခြေခံအခြေအနေ\n",
  xyjjdxqwtbh: "ဖြေရှင်းပေးရမည့် လိုအပ်ချက် ပြဿနာများ/ နည်းပညာလိုအပ်ချက်\n",
  zljsbh: "မူပိုင်ခွင့် နည်းပညာ\n",
  xmhzykfbh: "စီမံကိန်း ပူးပေါင်းဆောင်ရွက်ရေးနှင့် ဖွံ့ဖြိုးရေး\n",
  xmsbbh: "စီမံကိန်းကြေညာလွှာ\n",
  rcyjbh: "ပါရမီ မိတ်ဆက်\n",
  xqjsbh: "လိုအပ်ချက် အကျဉ်းချုပ်\n",
  qwhzfsbh: "ပူးပေါင်းဆောင်ရွက်မှုအချိန်မျှော်လင့်\n",
  gtkfbh: "ပူးတွဲဖွံ့ဖြိုးရေး\n",
  gmfwbh: "ဝန်ဆောင်မှုဝယ်ယူရန်\n",
  slzdbh: "ဆိုက်ကို တည်ထောင်ခြင်း\n",
  qwhzsjbh: "ပူးပေါင်းဆောင်ရွက်မှုအချိန်မျှော်လင့်\n",
  dq1dsbh: "ကာလတို (၁ နှစ် - ၃ နှစ်)\n",
  cq5nysbh: "ကာလရှည် (၅ နှစ်အထက်)\n",
  jjxqwzbh: "လိုအပ်ချက်များကို အထိ ဖြေရှင်း\n",
  yqtrbh: "ရည်ရွယ်သွင်းအားစု\n",
  swyyxbh: "သိန်းအောက်\n",
  sdsswybh: "၁၀-၃၀၀၀၀၀ ယွမ်\n",
  ssdwsbh: "၃၀-၅ သိန်းယွမ်\n",
  wsdybwbh: "၅၀-၁ သိန်း\n",
  ybdybwbh: "၁၀၀-၁ သိန်းခွဲ\n",
  ybwdebbh: "ယွမ် ၁၅၀-၂ သန်း\n",
  ebdsbbh: "ယွမ် ၂၀၀-၃ သန်း\n",
  wbysbh: "ယွမ် ၅ သန်းကျော်\n",
  qtysfwbh: "အခြား (ဘတ်ဂျက်အတိုင်းအတာ)\n",
  glxxbh: "၅၊ စီမံခန့်ခွဲမှုဆိုင်ရာ သတင်းအချက်အလက်\n",
  tygkxqxxbh: "အများပြည်သူလိုအပ်ချက် သတင်းအချက်အလက်ကို သဘောတူခြင်း\n",
  bfgkbh: "တစ်စိတ်တစ်ပိုင်း လူသိရှင်ကြား\n",
  tyxyzjfwbh: "ကျွမ်းကျင်သော ဝန်ဆောင်မှု လိုအပ်ကြောင်း သဘောတူ\n",
  tycydjjfadsxpjbh:
    "ဖြေရှင်းချက်များကို စိစစ်အကဲဖြတ်ရာတွင် ပါဝင်ဆောင်ရွက်ရန် သဘောတူ\n",
  dcxyjhzjlhddjyhxqbh:
    "စက်မှုသုတေသန ပူးပေါင်းဆောင်ရွက်မှု ဖလှယ်ရေး လုပ်ငန်းများအတွက် အကြံပြုချက်များနှင့် လိုအပ်ချက်များ\n",
  tjbh: "တင်သွင်းခြင်း\n",
  dybh: "ပုံနှိပ်ခြင်း\n",
  qtxbhbh: "နံပါတ်ကို ဖြည့်ပေးပါ\n",
  qtxdwmcbh: "ဌာနအမည်ကို ဖြည့်ပေးပါ\n",
  qtxlxrbh: "ဆက်သွယ် ဖြည့်စွက်ပေးပါ\n",
  qtxlxdhbh: "ဆက်သွယ်ရန် ဖုန်းနံပါတ် ဖြည့်စွက်ပေးပါ\n",
  zztjztsjbh: "ဒီအချက်အလက်ကို တင်သွင်းနေတာလား၊ ဆက်လုပ်နေတာလား။\n",
  tjcgbh: "တင်သွင်းအောင်မြင်ခြင်း\n",
  nccenter: "အမည်ပြောင်\n",
  bindcenter: "ကြိုးချည်ခြင်း\n",
  sqsmrzcenter: "အမည်ရင်း အောင်လက်မှတ်လျှောက်ထားခြင်း\n",
  smrzcenter: "အမည်ရင်း အောင်လက်မှတ်\n",
  sqzjrzcenter: "ကျွမ်းကျင်သူ အသိအမှတ်ပြု\n",
  sqjgrzcenter: "အဖွဲ့အစည်းအသိအမှတ်ပြုလက်မှတ်လျှောက်ထားခြင်း\n",
  jgrzcenter: "အေဂျင်စီ အသိအမှတ်ပြု\n",
  scdlcenter: "နောက်ဆုံး ဝင်ရောက်ခြင်း\n",
  xqlcenter: "လိုအပ်ချက်\n",
  cgzscenter: "ရလဒ်ပြသခြင်း\n",
  zhaqcenter: "အကောင့်လုံခြုံရေး\n",
  dlmmcenter: "စကားဝှက် ဝင်ရန်\n",
  yybhzhxxcenter: "အကောင့်သတင်းအချက်အလက်နှင့် Login လုံခြုံရေးကို ကာကွယ်ရန်\n",
  ggcenter: "ပြောင်းလဲခြင်း\n",
  aqsjcenter: "လုံခြုံရေးဖုန်း\n",
  yyzktfxcenter:
    "မှန်ကန်ကြောင်း အတည်ပြုထားပြီး ဖုန်းဖြင့် ဝင်ရောက်ခြင်း သို့မဟုတ် စကားဝှက် ပြန်လည်ရယူနိုင်ခြင်း\n",
  xgdlmmcenter: "ဝင်ရောက်ရန် စကားဝှက်ကို ပြုပြင်ခြင်း\n",
  dqmmcenter: "လက်ရှိ စကားဝှက်\n",
  xmmcenter: "စကားဝှက်အသစ်\n",
  qrmmcenter: "စကားဝှက်ကို အတည်ပြုခြင်း\n",
  mmxgcgcenter: "စကားဝှက်ပြုပြင်ခြင်း အောင်မြင်ပါသည်!\n",
  tjcenter: "တင်သွင်းခြင်း\n",
  qxcenter: "ဖျက်သိမ်း\n",
  qdcenter: "အဆုံးအဖြတ်\n",
  bdsjhcenter: "ဖုန်းအသစ်ကို ချည်နှောင်ခြင်း\n",
  xsjhmcenter: "မိုဘိုင်းဖုန်းနံပါတ်အသစ်\n",
  yzmcenter: "CAPTCHA\n",
  hqcenter: "CAPTCHA ရယူရန်\n",
  qsrsjhmvocenter: "မိုဘိုင်းဖုန်းနံပါတ်ကို ထည့်သွင်းပေးပါ\n",
  cdwsygzfvocenter: "အရှည် စာလုံးရေ ၁၁ လုံး\n",
  qsryzmvocenter: "CAPTCHA ကို ရိုက်ထည့်ပေးပါ\n",
  qsrmmvocenter: "စကားဝှက်ကို ထည့်ပေးပါ\n",
  qzcsrmmmvocenter: "စကားဝှက်ကို ထပ်ရိုက်ထည့်ပေးပါ\n",
  lcsrmmbyzvocenter: "နှစ်ကြိမ် input ကိုစကားဝှက်ကိုကိုက်ညီမှုမရှိဘူး!\n",
  zzxgmmvocenter: "စကားဝှက်ကို ပြန်လည်ပြင်ဆင်နေသည်၊ ဆက်လုပ်နေပါသလား။\n",
  cgvocenter: "အောင်မြင်ခြင်း\n",
  zzxgsjhmvocenter:
    "ဖုန်းနံပါတ်များ လဲလှယ်တပ်ဆင်ခြင်းများ ဆောင်ရွက်လျက်ရှိပြီး ဆက်လက်ဆောင်ရွက်ခြင်း ရှိ၊ မရှိ။\n",
  tsvocenter: "သိကောင်းစရာ\n",
  dlsjhcenter: "မိုဘိုင်းဖုန်းနံပါတ်သို့ ဝင်ရောက်ခြင်း\n",
  ybdcenter: "ချည်နှောင်ထားသည်\n",
  wbdcenter: "ချည်နှောင်ထားသည်\n",
  ghbdcenter: "ကြိုးချည်ခြင်းကို အစားထိုးခြင်း\n",
  qbdcenter: "ကြိုးချည်ရန်\n",
  xgmmcenter: "စကားဝှက်ကို ပြုပြင်ခြင်း\n",
  jbxxcenter: "အခြေခံအချက်အလက်\n",
  jbxxdesccenter:
    "မှန်ကန်သောအထောက်အထားသတင်းအချက်အလက်များပေးခြင်း၊ စစ်မှန်ကြောင်းသက်သေပြခြင်းကိုအထောက်အကူပြုခြင်း၊ အကြွေးပုံရိပ်ကိုမြှင့်တင်ခြင်း\n",
  zsxmccenter: "အမည်ရင်း\n",
  zsxmcvomcenter: "အမည်အမှန်ကို ထည့်သွင်းပေးပါ\n",
  xbvomcenter: "ကျားမ ရွေးချယ်ပေးပါ\n",
  cslyvomcenter: "မွေးနေ့ကို ရွေးချယ်ပေးပါ\n",
  qsrzssfzhvomcenter: "အစစ်အမှန် သက်သေခံလက်မှတ် နံပါတ်ကို ထည့်သွင်းပေးပါ\n",
  qscsfzzmvomcenter: "ကျေးဇူးပြု၍ မှတ်ပုံတင်ကဒ်၏မျက်နှာစာကို upload လုပ်ပါ\n",
  qscsfzbmvomcenter: "ကျေးဇူးပြု၍ မှတ်ပုံတင်ကဒ် နောက်ကျောကို upload လုပ်ပါ\n",
  zzthsmxxcenter: "အမည်ရင်းအချက်အလက်များကိုတင်ပြနေသည်, ဆက်လက်ရှိမရှိ?\n",
  zzbcgrxxcenter:
    "ကိုယ်ရေးကိုယ်တာအချက်အလက်များကို ထိန်းသိမ်းလျက်ရှိပြီး ဆက်လက်လုပ်ဆောင်ပါသလား။\n",
  xbcenter: "ကျားမ\n",
  nancenter: "男",
  nvcenter: "女",
  wzcenter: "Unknown\n",
  cslycenter: "မွေးသက္ကရာဇ်\n",
  sfzhcenter: "အထောက်အထား နံပါတ်\n",
  sfzzmcenter: "အထောက်အထား စာရွက်စာတမ်း မျက်နှာစာ\n",
  sfzzmdesccenter:
    "Clear ကိုပုဂ္ဂိုလ်ရေး ID ကို အပြုသဘော ပြောင်းပြန် မူရင်း ဓာတ်ပုံလိုအပ်ချက်များ 200KB အောက်တွင်ဖော်ပြထားသော JPG, GIF ကို, PNG format နဲ့ရုပ်ပုံများ\n",
  sfzfmcenter: "အထောက်အထား စာရွက်စာတမ်း နောက်ပြန်\n",
  tjsmxxcenter: "အမည်ရင်း သတင်းအချက်အလက် တင်သွင်းခြင်း\n",
  grzlcenter: "ကိုယ်ရေးအချက်အလက်\n",
  sctxcenter: "အပေါ်ယံ ဦးခေါင်းပုံ\n",
  sctxdesccenter: "တစ်ဦးအနေအထား သက်သေဓာတ်ပုံကို upload တင်ရန်အကြံပြုသည်\n",
  yhlccenter: "အသုံးပြုသူ အမည်ပြောင်\n",
  szdqcenter: "တည်ရှိရာဒေသ\n",
  qxzcenter: "ရွေးချယ်ပေးပါ\n",
  yxcenter: "စာတိုက်ပုံး\n",
  yxdesccenter: "သင်၏ အသုံးများသော စာတိုက်ပုံးကို ရိုက်ထည့်ပါ\n",
  jzdzcenter: "နေထိုင်ရာလိပ်စာ\n",
  zycenter: "အလုပ်အကိုင်\n",
  zydesccenter: "လုပ်ကိုင်သည့် လုပ်ငန်း သို့မဟုတ် အလုပ်အကိုင်\n",
  qqcenter: "QQ\n",
  qqdesccenter:
    "သင်နှင့်အတူဆက်သွယ်ပြောဆိုရန်အဆင်ပြေ ဧည့်ဝတ်ကျေပွန် အစစ်အမှန် QQ ရိုက်ထည့်ပေးပါ\n",
  bccenter: "သိမ်းဆည်း\n",
  qsrnccenter: "အမည်ပြောင်ကို ရိုက်ထည့်ပေးပါ\n",
  qsryxcenter: "စာတိုက်ပုံးကို ရိုက်ထည့်ပါ\n",
  qsrxjzdcenter: "နေထိုင်ရာလိပ်စာကို ထည့်သွင်းပေးပါ\n",
  qsrzycenter: "အလုပ်အကိုင်ကို ထည့်ပေးပါ\n",
  qsrqqcenter: "QQ ကို ရိုက်ထည့်ပေးပါ\n",
  qsctxcenter: "ဦးခေါင်းပုံတင်ပေးပါ\n",
  qxzsfcenter: "ပြည်နယ်များကို ရွေးချယ်ပေးပါ\n",
  qxzcscenter: "မြို့ကို ရွေးချယ်ပေးပါ\n",
  qxzqycenter: "ဒေသကို ရွေးချယ်ပေးပါ\n",
  jgrzdesccenter:
    "မှန်ကန်သော သတင်းအချက်အလက်များကို ပေးအပ်ခြင်းဖြင့် နောက်ထပ် စစ်ဆေးအတည်ပြုရာတွင် အထောက်အကူဖြစ်စေပါသည်။\n",
  qymcccenter: "လုပ်ငန်းအမည်\n",
  qydzcenter: "လုပ်ငန်းလိပ်စာ\n",
  fddbrcenter: "တရားဝင်ကိုယ်စားလှယ်\n",
  tyshxydmcenter: "ယူနီဖောင်း လူမှုအကြွေးကုဒ်\n",
  yyzzcenter: "လုပ်ငန်းလိုင်စင်\n",
  yyzzdesccenter:
    "ရှင်းလင်းပြတ်သားသော စီးပွားရေးလုပ်ငန်းလိုင်စင် ဓာတ်ပုံလိုအပ်ချက်များ 200KB အောက်ဖော်ပြပါ JPG, GIF, PNG ပုံစံများအတွက် ရုပ်ပုံများ\n",
  tgrzxxcenter: "အသိအမှတ်ပြုအချက်အလက်များပေးခြင်း\n",
  qyyjkfxmqkcenter: "လုပ်ငန်းဆိုင်ရာသုတေသနဖွံ့ဖြိုးရေးစီမံကိန်းအခြေအနေ\n",
  qbcenter: "အားလုံး\n",
  wshtgcenter: "မစစ်ဆေးဘဲ အောင်မြင်ခြင်း\n",
  yshcenter: "စိစစ်ပြီး\n",
  dshcenter: "စာစစ်ရန်\n",
  sscenter: "ရှာဖွေခြင်း\n",
  qsrgjzcenter: "သော့ချက်စာလုံးကို ထည့်သွင်းပေးပါ\n",
  xmmccenter: "ပစ္စည်းအမည်\n",
  xmlycenter: "စီမံကိန်းရင်းမြစ်များ\n",
  kzxscenter: "ပုံစံများ ဆောင်ရွက်ခြင်း\n",
  kssjcenter: "စတင်ချိန်\n",
  jssjcenter: "အဆုံးသတ်ချိန်\n",
  fzrcenter: "တာဝန်ခံ\n",
  czcenter: "စစ်ဆင်ရေး\n",
  qyyjkfhdcenter: "လုပ်ငန်းသုတေသနဖွံ့ဖြိုးရေးလုပ်ငန်းများ\n",
  dwmccenter: "ယူနစ်အမည်\n",
  dwfzrcenter: "ယူနစ်တာဝန်ခံ\n",
  tbrcenter: "ဖြည့်စွက်သူ\n",
  yxqkscenter: "သက်တမ်း စတင်ချိန်\n",
  yxqjscenter: "သက်တမ်းကုန်ဆုံးချိန်\n",
  kjcgxqzjcenter: "သိပ္ပံနှင့်နည်းပညာ ရလဒ်လိုအပ်ချက် တောင်းခံခြင်း\n",
  cpmccenter: "ကုန်ပစ္စည်းအမည်\n",
  txsjcenter: "အချိန်ဖြည့်ပါ\n",
  fbkjcgcgxxcenter:
    "သိပ္ပံနှင့်နည်းပညာ အသီးအပွင့်-ရလဒ် သတင်းအချက်အလက်များ ထုတ်ပြန်ခြင်း\n",
  fbkjcgcgdesccenter:
    "သတင်းအချက်အလက် ပြည့်စုံမှု ရှိလေလေ မိမိ ပစ်မှတ်ထားသော ဖောက်သည်၏ အာရုံစိုက်မှုကို ပိုမို ရရှိလေလေ ဖြစ်သည်\n",
  fbkjcgjsmccenter: "နည်းပညာအမည်\n",
  hylbcenter: "လုပ်ငန်းအမျိုးအစား\n",
  zscqztcenter: "အသိဉာဏ်ပစ္စည်းမူပိုင်ခွင့်အခြေအနေ\n",
  zlmccenter: "မူပိုင်ခွင့်အမည်\n",
  zlhcenter: "မူပိုင်အမှတ်\n",
  jjcenter: "ကိုယ်ရေးအကျဉ်း\n",
  sfcjcenter: "အရောင်းအဝယ် ရှိမရှိ\n",
  zrfscenter: "လွှဲပြောင်းပုံ\n",
  cgszdwcenter: "အသီးအပွင့်တည်ရှိရာဌာန\n",
  dwlxcenter: "ယူနစ်အမျိုးအစား\n",
  szdwmccenter: "တည်ရှိရာဌာန၏အမည်\n",
  cgszdcenter: "အသီးအပွင့်တည်နေရာ\n",
  fbcgzszmclcenter: "ထုတ်ပေးခြင်း ရလဒ်ပြသခြင်း - သက်သေပြပစ္စည်း\n",
  fbcgdesczmcenter:
    "နောက်ထပ်ရလဒ်များကို display ကိုသိရန်ဖောက်သည်အဆင်ပြေ သက်သေပြပစ္စည်း Upload လုပ်ပါ\n",
  xxcycenter: "ထွန်းသစ်စ စက်မှုလုပ်ငန်း\n",
  kjcgpjspcenter: "သိပ္ပံနှင့်နည်းပညာ ရလဒ်များ အကဲဖြတ်ခြင်း အဆင့်\n",
  csdcenter: "ရင့်ကျက်မှု\n",
  csdzmcenter: "ရင့်ကျက်မှု သက်သေ\n",
  csdzmdesccenter:
    "ကြည်လင်ပြတ်သားသော ဓာတ်ပုံတစ်ပုံအတွက် 100KB အောက်ရှိ jpg, gif, png format ဖြင့် ရုပ်ပုံတစ်ပုံ လိုအပ်ပါသည်။\n",
  jstpcenter: "နည်းပညာဆိုင်ရာ ရုပ်ပုံများ\n",
  jspgjzcenter: "နည်းပညာဆိုင်ရာအကဲဖြတ်တန်ဖိုး\n",
  lxrcenter: "အဆက်အသွယ်\n",
  sjhmcenter: "မိုဘိုင်းဖုန်းနံပါတ်\n",
  zqsrsjhcenter: "မိုဘိုင်းဖုန်းနံပါတ်ကို မှန်ကန်စွာ ထည့်သွင်းပါ\n",
  zqsrlxrcenter: "အဆက်အသွယ်ကို မှန်ကန်စွာ ဖြည့်ပါ\n",
  tjkjcgshcenter: "သိပ္ပံနှင့်နည်းပညာ ရလဒ်များ စိစစ်တင်ပြရန်\n",
  glcgcenter: "ရလဒ်များကို စီမံခန့်ခွဲခြင်း\n",
  kjcgmccenter: "နည်းပညာ အသီးအပွင့် အမည်\n",
  zjzkzjxxcenter:
    "ကျွမ်းကျင်သူပညာရှင်များဆိုင်ရာအကြံပေးအဖွဲ့ - ကျွမ်းကျင်သူသတင်းအချက်အလက်\n",
  zjzkzjxxdesccenter:
    "မှန်ကန်သောအထောက်အထားသတင်းအချက်အလက်များပေးခြင်း၊ စစ်မှန်ကြောင်းသက်သေပြခြင်းကိုအထောက်အကူပြုခြင်း၊ အကြွေးပုံရိပ်ကိုမြှင့်တင်ခြင်း\n",
  zgxlcenter: "ပညာအရည်အချင်း အမြင့်ဆုံး\n",
  gzdwcenter: "အလုပ်ဌာနများ\n",
  zwcenter: "ရာထူး\n",
  lxdhcenter: "ဆက်သွယ်ရန်ဖုန်းနံပါတ်\n",
  fwalcenter: "ဝန်ဆောင်မှုကိစ္စ\n",
  tjshcenter: "စိစစ်တင်ပြရန်\n",
  glzjzkxicenter: "စီမံခန့်ခွဲမှုအထူးကု ဉာဏ်ကြီးရှင် သတင်းအချက်အလက်\n",
  zjmzcenter: "ကျွမ်းကျင်သူ အမည်\n",
  zjhycenter: "ကျွမ်းကျင်သူ စက်မှုလုပ်ငန်း\n",
  tjrqcenter: "ရက် ထည့်ရန်\n",
  zcxlcenter: "ရာထူးအမည်/ပညာအရည်အချင်း\n",
  fbjsxqxqxxcenter:
    "နည်းပညာလိုအပ်ချက် - ဝယ်လိုအား သတင်းအချက်အလက်များ ထုတ်ပြန်ခြင်း\n",
  fbjsxqdesccenter:
    "သတင်းအချက်အလက်များကို မှန်ကန်စွာ ဖြည့်စွက်၍ ထပ်မံစစ်ဆေးမှုများ ပြုလုပ်\n",
  xqfmccenter: "ဝယ်လိုအား အဖုံး\n",
  xqfmdesccenter: "စာရင်းဌာနခွဲက ဖော်ပြသော နည်းပညာလိုအပ်ချက် အဖုံး။\n",
  xqmccenter: "ဝယ်လိုအား အမည်\n",
  fbxqlxxxcenter:
    "နည်းပညာလိုအပ်ချက်များ ထုတ်ပြန်ခြင်း - ဆက်သွယ်ရန် အချက်အလက်များ\n",
  fbxqlxxxdesccenter:
    "ဆက်သွယ်ရန်အချက်အလက်ကိုမှန်ကန်စွာဖြည့်ပါ ဖောက်သည်များသင့်အားဆက်သွယ်ရန်လွယ်ကူစေသည်\n",
  tjjsxqshcenter: "နည်းပညာလိုအပ်ချက် စိစစ်တင်ပြရန်\n",
  glxqcenter: "လိုအပ်ချက်များကို စီမံခန့်ခွဲခြင်း\n",
  xiugaicenter: "ပြုပြင်ခြင်း\n",
  sanchucenter: "ဖျက်ခြင်း\n",
  kjcglycenter: "သိပ္ပံနှင့်နည်းပညာရလဒ် မက်ဆေ့\n",
  lybkcenter: "မက်ဆေ့ဘုတ်\n",
  lydxcenter: "မက်ဆေ့ဘာသာရပ်\n",
  dhcscenter: "တွေ့ဆုံဆွေးနွေးမှု အကြိမ်အရေအတွက်\n",
  jsxqlycenter: "နည်းပညာလိုအပ်ချက် မက်ဆေ့\n",
  kjcgcenter: "သိပ္ပံနှင့်နည်းပညာရလဒ် မက်ဆေ့\n",
  zjzklycenter: "ကျွမ်းကျင်ပညာရှင်များ အကြံပေးအဖွဲ့ သတင်းစကား\n",
  jzmmL: "စကားဝှက်ကို မှတ်ထားပါ\n",
  "welogin": "ဝင်ရောက်ဖို့ ကြိုဆိုပါတယ်\n",
  "weuser": "အသုံးပြုသူအမည်\n",
  "wepass": "လျှို့ဝှက်ကုတ်\n",
  "weother": "အခြား လော့အင်များ\n"
};
