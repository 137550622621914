import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/redirect/:path(.*)',
    name: 'Redirect',
    meta: { },
    component: () => import('@/views/redirect.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: '首页',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/articlelist',
    name: 'ArticleList',
    component: () => import(/* webpackChunkName: "articlelist" */ '../views/ArticleList.vue'),
    meta: {
      title: '文章列表',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/bigdata',
    name: 'BigData',
    component: () => import(/* webpackChunkName: "BigData" */ '../views/BigData.vue'),
    meta: {
      title: '数据大屏',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/addproject',
    name: 'addproject',
    component: () => import(/* webpackChunkName: "staup" */ '../views/kjtjtable/staup.vue'),
    meta: {
      title: '数据大屏',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/tzgglist',
    name: 'TzggList',
    component: () => import(/* webpackChunkName: "TzggList" */ '../views/TzggList.vue'),
    meta: {
      title: '通知公告列表',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/tzggdetails',
    name: 'TzggDetails',
    component: () => import(/* webpackChunkName: "TzggDetails" */ '../views/TzggDetails.vue'),
    meta: {
      title: '文章详情',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/articledetails',
    name: 'ArticleDetails',
    component: () => import(/* webpackChunkName: "articledetails" */ '../views/ArticleDetails.vue'),
    meta: {
      title: '文章详情',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/noticeList',
    name: 'NoticeList',
    component: () => import(/* webpackChunkName: "NoticeList" */ '../views/NoticeList.vue'),
    meta: {
      title: '通知公告',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/jsxqlist',
    name: 'Jsxqlist',
    component: () => import(/* webpackChunkName: "jsxqlist" */ '../views/Jsxqlist.vue'),
    meta: {
      title: '技术需求列表',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/jsxqdetails',
    name: 'Jsxqdetails',
    component: () => import(/* webpackChunkName: "jsxqdetails" */ '../views/Jsxqdetails.vue'),
    meta: {
      title: '技术需求详情',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/zjzklist',
    name: 'Zjzklist',
    component: () => import(/* webpackChunkName: "zjzklist" */ '../views/Zjzklist.vue'),
    meta: {
      title: '专家智库列表',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/zjzkdetails',
    name: 'Zjzkdetails',
    component: () => import(/* webpackChunkName: "Zjzkdetails" */ '../views/Zjzkdetails.vue'),
    meta: {
      title: '专家智库详情',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/kjcglist',
    name: 'Kjcglist',
    component: () => import(/* webpackChunkName: "kjcglist" */ '../views/Kjcglist.vue'),
    meta: {
      title: '科技成果列表',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/kjcgdetial',
    name: 'Kjcgdetial',
    component: () => import(/* webpackChunkName: "kjcgdetial" */ '../views/Kjcgdetial.vue'),
    meta: {
      title: '科技成果详情',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/xmsblist',
    name: 'Xmsblist',
    component: () => import(/* webpackChunkName: "xmsblist" */ '../views/Xmsblist.vue'),
    meta: {
      title: '项目申报',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/xmsbdetails',
    name: 'Xmsbdetails',
    component: () => import(/* webpackChunkName: "xmsbdetails" */ '../views/Xmsbdetails.vue'),
    meta: {
      title: '项目申报详情',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/wxxzlist',
    name: 'Wxxzlist',
    component: () => import(/* webpackChunkName: "wxxzlist" */ '../views/Wxxzlist.vue'),
    meta: {
      title: '文件下载',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/pageregister',
    name: 'PageRegister',
    component: () => import(/* webpackChunkName: "PageRegister" */ '../views/PageRegister.vue'),
    meta: {
      title: '文件下载',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/kjhzdetails',
    name: 'KjhzDetails',
    component: () => import(/* webpackChunkName: "KjhzDetails" */ '../views/KjhzDetails.vue'),
    meta: {
      title: '科技合作',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/kjhzlist',
    name: 'Kjhzlist',
    component: () => import(/* webpackChunkName: "kjhzlist" */ '../views/Kjhzlist.vue'),
    meta: {
      title: '科技合作',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/kjjrlist',
    name: 'Kjjrlist',
    component: () => import(/* webpackChunkName: "kjjrlist" */ '../views/Kjjrlist.vue'),
    meta: {
      title: '科技金融',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/kjcgxq',
    name: 'kjcgxq',
    component: () => import(/* webpackChunkName: "kjjrlist" */ '../views/Kjcgxq.vue'),
    meta: {
      title: '科技需求征集',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/kjcgxqadmin',
    name: 'kjcgxqadmin',
    component: () => import(/* webpackChunkName: "kjjrlist" */ '../views/Kjcgxqadmin.vue'),
    meta: {
      title: '科技需求征集',
      hederType: 'hide',
    }
  },
  {
    path: '/noAuthAdminStaup',
    name: 'noAuthAdminStaup',
    component: () => import(/* webpackChunkName: "kjjrlist" */ '../views/kjtjtable/noAuthAdminStaup.vue'),
    meta: {
      title: '科技统计',
      hederType: 'hide',
    }
  },
  {
    path: '/kjjrdetails',
    name: 'KjjrDetails',
    component: () => import(/* webpackChunkName: "KjjrDetails" */ '../views/KjjrDetails.vue'),
    meta: {
      title: '科技金融详情',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/kjtjlist',
    name: 'Kjtjlist',
    component: () => import(/* webpackChunkName: "kjtjlist" */ '../views/Kjtjlist.vue'),
    meta: {
      title: '科技统计',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/loginold',
    name: 'loginold',
    component: () => import(/* webpackChunkName: "loginold" */ '../views/Login.vue'),
    meta: {
      title: '登录',
      hederType: 'hide',
    }
  },
  {
    path: '/login',
    name: 'PageLogin',
    component: () => import(/* webpackChunkName: "PageLogin" */ '../views/PageLogin.vue'),
    meta: {
      title: '登录',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/wzmm',
    name: 'PageWjma',
    component: () => import(/* webpackChunkName: "PageWjma" */ '../views/PageWjma.vue'),
    meta: {
      title: '登录',
      hederType: 'dehongweb',
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: {
      title: '注册',
      hederType: 'hide',
    }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "kjtjlist" */ '../views/usercenter/CenterLayout.vue'),
    meta: {
      title: '个人中心',
      hederType: 'dehongweb',
    },
    redirect: '/center',
    children: [
      {
        path: '/message',
        name: 'Message',
        component: () => import(/* webpackChunkName: "Message" */ '../views/usercenter/Message.vue'),
        meta: {
          title: '留言消息对话框',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/center',
        name: 'UserCenter',
        component: () => import(/* webpackChunkName: "UserCenter" */ '../views/usercenter/UserCenter.vue'),
        meta: {
          title: '个人主页',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/info',
        name: 'UserInfo',
        component: () => import(/* webpackChunkName: "UserInfo" */ '../views/usercenter/UserInfo.vue'),
        meta: {
          title: '个人资料',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/qyrz',
        name: 'Qyrz',
        component: () => import(/* webpackChunkName: "Qyrz" */ '../views/usercenter/Qyrz.vue'),
        meta: {
          title: '企业认证',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/jbxx',
        name: 'Jbxx',
        component: () => import(/* webpackChunkName: "Jbxx" */ '../views/usercenter/Jbxx.vue'),
        meta: {
          title: '是名认证',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/zjzl',
        name: 'Zjzl',
        component: () => import(/* webpackChunkName: "Zjzl" */ '../views/usercenter/Zjzl.vue'),
        meta: {
          title: '专家资料',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/glkjcglist',
        name: 'GlKjcgList',
        component: () => import(/* webpackChunkName: "GlKjcgList" */ '../views/usercenter/GlKjcgList.vue'),
        meta: {
          title: '管理科技成果',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/fbkjcg',
        name: 'Fbkjcg',
        component: () => import(/* webpackChunkName: "Fbkjcg" */ '../views/usercenter/Fbkjcg.vue'),
        meta: {
          title: '发布科技成果',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/glzjzklist',
        name: 'GLZjzkList',
        component: () => import(/* webpackChunkName: "GLZjzkList" */ '../views/usercenter/GLZjzkList.vue'),
        meta: {
          title: '管理专家智库',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/glkjtjlist',
        name: 'GlKjtjList',
        component: () => import(/* webpackChunkName: "GlKjtjList" */ '../views/usercenter/GlKjtjList.vue'),
        meta: {
          title: '管理专家智库',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/fbzjzk',
        name: 'FbZjzk',
        component: () => import(/* webpackChunkName: "FbZjzk" */ '../views/usercenter/FbZjzk.vue'),
        meta: {
          title: '发布专家智库',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/gljsxqlist',
        name: 'GlJsxqList',
        component: () => import(/* webpackChunkName: "GlJsxqList" */ '../views/usercenter/GlJsxqList.vue'),
        meta: {
          title: '管理技术需求',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/fbjsxq',
        name: 'FbJsxq',
        component: () => import(/* webpackChunkName: "FbJsxq" */ '../views/usercenter/FbJsxq.vue'),
        meta: {
          title: '发布技术需求',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/glzjfwlist',
        name: 'GlZjfwList',
        component: () => import(/* webpackChunkName: "GlZjfwList" */ '../views/usercenter/GlZjfwList.vue'),
        meta: {
          title: '管理中介服务',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/fbzjfw',
        name: 'FbZjfw',
        component: () => import(/* webpackChunkName: "FbZjfw" */ '../views/usercenter/FbZjfw.vue'),
        meta: {
          title: '发布中介服务',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/myleavemessage',
        name: 'MyLeaveMessage',
        component: () => import(/* webpackChunkName: "MyLeaveMessage" */ '../views/usercenter/MyLeaveMessage.vue'),
        meta: {
          title: '我的留言',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/kjcgleavemessage',
        name: 'KjcgLeaveMessage',
        component: () => import(/* webpackChunkName: "KjcgLeaveMessage" */ '../views/usercenter/KjcgLeaveMessage.vue'),
        meta: {
          title: '科技成果留言',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/messagedetial',
        name: 'MessageDetial',
        component: () => import(/* webpackChunkName: "MessageDetial" */ '../views/usercenter/MessageDetial.vue'),
        meta: {
          title: '留言对话列表',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/jsxqleavemessage',
        name: 'JsxqLeaveMessage',
        component: () => import(/* webpackChunkName: "JsxqLeaveMessage" */ '../views/usercenter/JsxqLeaveMessage.vue'),
        meta: {
          title: '技术需求留言',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/glqyhdxg',
        name: 'glQyhdxg',
        component: () => import(/* webpackChunkName: "glqyhdxg" */ '../views/usercenter/GlQyhdxg.vue'),
        meta: {
          title: '技术需求留言',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/zjzkmessage',
        name: 'ZjzkLeaveMessage',
        component: () => import(/* webpackChunkName: "ZjzkLeaveMessage" */ '../views/usercenter/ZjzkLeaveMessage.vue'),
        meta: {
          title: '技术需求留言',
          hederType: 'dehongweb',
        }
      },
      {
        path: '/dhzcgzjb',
        name: 'GlDhzcgzjb',
        component: () => import(/* webpackChunkName: "GlDhzcgzjb" */ '../views/usercenter/GlDhzcgzjb.vue'),
        meta: {
          title: '技术需求留言',
          hederType: 'dehongweb',
        }
      },
    ]
  },
  {
    path: '/pjapp',
    name: 'PJapp',
    component: () => import(/* webpackChunkName: "PJapp" */ '../views/project/PJapp.vue'),
    meta: {
      title: '项目申报导航',
      hederType: 'dehongweb',
    },
    children: []
  },
  {
    path: '/project',
    name: 'ProjectLayout',
    component: () => import(/* webpackChunkName: "ProjectLayout" */ '../views/project/ProjectLayout.vue'),
    meta: {
      title: '项目申报',
      hederType: 'shenbao',
    },
    redirect: '/phome',
    children: [
      {
        path: '/phome',
        name: 'PHome',
        component: () => import(/* webpackChunkName: "PHome" */ '../views/project/PHome.vue'),
        meta: {
          title: '项目申报主页',
          hederType: 'shenbao',
        },
        children: []
      },
      {
        path: '/declarepro',
        name: 'DeclareproLayout',
        component: () => import(/* webpackChunkName: "DeclareproLayout" */ '../views/project/DeclareproLayout/index.vue'),
        meta: {
          title: '项目申报',
          hederType: 'shenbao',
        },
        redirect: '/declarepro/declareprolist',
        children: [
          {
            path: '/declarepro/declareprolist',
            name: 'DeclareproList',
            component: () => import(/* webpackChunkName: "DeclarerList" */ '../views/project/DeclareproLayout/pages/DeclareproList.vue'),
            meta: {
              title: '申报列表',
              hederType: 'shenbao',
            },
            children: []
          },
          {
            path: '/declarepro/projectlist',
            name: 'ProjectList',
            component: () => import(/* webpackChunkName: "ProjectList" */ '../views/project/DeclareproLayout/pages/ProjectList.vue'),
            meta: {
              title: '项目列表',
              hederType: 'shenbao',
            },
            children: []
          },
          {
            path: '/declarepro/toexaminelist',
            name: 'ToExamineList',
            component: () => import(/* webpackChunkName: "ToExamineList" */ '../views/project/DeclareproLayout/pages/ToExamineList.vue'),
            meta: {
              title: '审核申请书',
              hederType: 'shenbao',
            },
            children: []
          },
          {
            path: '/declarepro/alltoexaminelist',
            name: 'AllToExamineList',
            component: () => import(/* webpackChunkName: "AllToExamineList" */ '../views/project/DeclareproLayout/pages/AllToExamineList.vue'),
            meta: {
              title: '所有审核申请书',
              hederType: 'shenbao',
            },
            children: []
          },
          {
            path: '/declarepro/reportfrom',
            name: 'ReportFrom',
            component: () => import(/* webpackChunkName: "ReportFrom" */ '../views/project/DeclareproLayout/pages/ReportFrom.vue'),
            meta: {
              title: '申请表单',
              hederType: 'shenbao',
            },
            children: []
          },
        ]
      },
      {
        path: '/projectapprovallist',
        name: 'ProjectapprovalLayout',
        component: () => import(/* webpackChunkName: "ProjectapprovalLayout" */ '../views/project/ProjectapprovalLayout/index.vue'),
        meta: {
          title: '立项管理',
          hederType: 'shenbao',
        },
        redirect: '/projectapprovallist/selectlxproject',
        children: [
          {
            path: '/projectapprovallist/selectlxproject',
            name: 'SelectLXProject',
            component: () => import(/* webpackChunkName: "SelectLXProject" */ '../views/project/ProjectapprovalLayout/pages/SelectLXProject.vue'),
            meta: {
              title: '立项项目查询',
              hederType: 'shenbao',
            },
            children: []
          },
          {
            path: '/projectapprovallist/reviewcontractlist',
            name: 'ReviewContractList',
            component: () => import(/* webpackChunkName: "ReviewContractList" */ '../views/project/ProjectapprovalLayout/pages/ReviewContractList.vue'),
            meta: {
              title: '审核合同书',
              hederType: 'shenbao',
            },
            children: []
          },
          {
            path: '/projectapprovallist/allreviewcontractlist',
            name: 'AllReviewContractList',
            component: () => import(/* webpackChunkName: "AllReviewContractList" */ '../views/project/ProjectapprovalLayout/pages/AllReviewContractList.vue'),
            meta: {
              title: '所有审核合同书',
              hederType: 'shenbao',
            },
            children: []
          },
        ]
      },
      {
        path: '/projectacceptancelist',
        name: 'ProjectAcceptanceLayout',
        component: () => import(/* webpackChunkName: "ProjectAcceptanceLayout" */ '../views/project/ProjectAcceptanceLayout/index.vue'),
        meta: {
          title: '项目验收管理',
          hederType: 'shenbao',
        },
        redirect: '/projectacceptancelist/checkandacceptlist',
        children: [
          {
            path: '/projectacceptancelist/checkandacceptlist',
            name: 'CheckAndAcceptList',
            component: () => import(/* webpackChunkName: "CheckAndAcceptList" */ '../views/project/ProjectAcceptanceLayout/pages/CheckAndAcceptList.vue'),
            meta: {
              title: '审核验收申请',
              hederType: 'shenbao',
            },
            children: []
          },
          {
            path: '/projectacceptancelist/allCheckandacceptlist',
            name: 'AllCheckAndAcceptList',
            component: () => import(/* webpackChunkName: "AllCheckAndAcceptList" */ '../views/project/ProjectAcceptanceLayout/pages/AllCheckAndAcceptList.vue'),
            meta: {
              title: '所有验收申请书查询',
              hederType: 'shenbao',
            },
            children: []
          },
          {
            path: '/projectacceptancelist/inputexpertopinionslist',
            name: 'InputExpertOpinionsList',
            component: () => import(/* webpackChunkName: "InputExpertOpinionsList" */ '../views/project/ProjectAcceptanceLayout/pages/InputExpertOpinionsList.vue'),
            meta: {
              title: '录入专家意见',
              hederType: 'shenbao',
            },
            children: []
          },
        ]
      },
      {
        path: '/psystem',
        name: 'PSystem',
        component: () => import(/* webpackChunkName: "PSystem" */ '../views/project/PSystemLayout/index.vue'),
        meta: {
          title: '系统管理',
          hederType: 'shenbao',
        },
        redirect: '/psystem/declarerlist',
        children: [
          {
            path: '/psystem/declarerlist',
            name: 'DeclarerList',
            component: () => import(/* webpackChunkName: "DeclarerList" */ '../views/project/PSystemLayout/pages/DeclarerList.vue'),
            meta: {
              title: '申报人管理',
              hederType: 'shenbao',
            },
            children: []
          },
          {
            path: '/psystem/personalinformation',
            name: 'PersonalInformation',
            component: () => import(/* webpackChunkName: "PersonalInformation" */ '../views/project/PSystemLayout/pages/PersonalInformation.vue'),
            meta: {
              title: '个人信息维护',
              hederType: 'shenbao',
            },
            children: []
          },
          {
            path: '/psystem/personalaccount',
            name: 'PersonalAccount',
            component: () => import(/* webpackChunkName: "PersonalAccount" */ '../views/project/PSystemLayout/pages/PersonalAccount.vue'),
            meta: {
              title: '个人帐号管理',
              hederType: 'shenbao',
            },
            children: []
          },
          {
            path: '/psystem/eclarerfrom',
            name: 'AdDeclarer',
            component: () => import(/* webpackChunkName: "AdDeclarer" */ '../views/project/PSystemLayout/pages/AdDeclarer.vue'),
            meta: {
              title: '添加申报人',
              hederType: 'shenbao',
            },
            children: []
          },
        ]
      },
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    meta: {
      title: '404页面',
      hederType: 'dehongweb',
    },
    component:  () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeResolve((to, from, next) => {
  // window.document.title = to.meta.title
  next()
})

export default router
