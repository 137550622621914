export default {
  wecome: "Hello, welcome to Dehong Technology Transfer Service Platform!",
  login: "Login",
  nick: "Nickname",
  bind: "Binding",
  search: "Search",
  qxzxyssdflsearch: "Please select the category to search for",
  searchP: "Please select",
  searchI: "Please enter content",
  register: "Registration",
  center: "Individual center",
  zcxwtitle: "Policy news",
  tzgotitle: "Notice announcement",
  kjhztitle: "Scientific and technological cooperation",
  kjcgtitle: "Scientific and technological achievements",
  kjjrtitle: "Technology finance",
  zjzktitle: "Expert think tank",
  zjzkftitle: "Screening of expert think tanks",
  kjcgftitle: "Screening of scientific and technological achievements",
  jsxqtitle: "Technical requirements",
  jsxqftitle: "Technical requirements screening",
  jslinytitle: "Technical field",
  fbxqtitle: "Release requirements",
  wxxztitle: "Document download",
  zbdwname: "Organizer",
  dhname: "Telephone",
  jszcname: "Technical support",
  dzname: "Address",
  syname: "Home page",
  fbcg: "Release results",
  xmsb: "Project management",
  sbtz: "Declaration notice",
  all: "All",
  in_progress: "In progress",
  stop: "Expired",
  notopen: "Not started",
  expired: "Expired",
  kjtj_title: "Science and technology statistics",
  lilantitle: "Browse",
  layuantitle: "Source",
  lilanltitle: "Page views",
  timetitle: "Time",
  zwtitle: "Text",
  fbsjtitle: "Release time",
  hyfltitle: "Industry classification",
  zjjjtitle: "Brief introduction of experts",
  lxzjtitle: "Contact experts",
  xlxwtitle: "Education/degree",
  zczwtitle: "Professional title and position",
  xmtitle: "Name",
  fjtitle: "Annex",
  xqtitle: "Details",
  hylbtitle: "Industry category",
  jssptitle: "Technical level",
  lxdhtitle: "Contact number",
  rcktitle: "Person view",
  ljcktitle: "Cumulative view",
  zrfstitle: "Mode of transfer",
  zscqzttitle: "Intellectual property status",
  zlmcttitle: "Patent name",
  cgszdwttitle: "Unit where the results are located",
  cgjjttitle: "Brief introduction of results",
  zlhttitle: "Patent number",
  yxtitle: "Mailbox",
  xqjjtitle: "Requirements Profile",
  lxtitle: "Contact",
  my: "Negotiable",
  jg: "Price",
  mslxtitle: "Contact immediately",
  swdlsfjx: "Not yet logged in, go to log in, do you want to continue?",
  ts: "Prompt",
  ok: "Determine",
  successs: "Success",
  cancel: "Cancel",
  qsrnxsdh: "Please enter what you want to say",
  lxcg: "Successful contact!",
  lycghxgryhqdlx:
    "After the message is successful, relevant personnel will get in touch with you. Please keep the phone open. Do you want to continue?",
  qyyjkfxmqk_title: "Research and development projects of enterprises",
  qyyjkfhdjxgqktitle:
    "Research and development activities of enterprises and related information",
  qsrsjhmtitle: "Please enter your mobile phone number",
  qsrdlzhtitle: "Please enter your login account number",
  qsrdlmmtitle: "Please enter your login password",
  wecomestitle: "Welcome!",
  cd3d5title: "Between 3 and 15 characters in length",
  qsrmmtitle: "Please enter your password",
  wjmmtitle: "Forgot password",
  fhsytitle: "Return to Home Page",
  grzctitle: "Individual registration",
  jgzctitle: "Institutional registration",
  jjxzgzytitle:
    "This resource is about to be downloaded. Do you want to continue?",
  sjhmtitle: "Mobile phone number",
  szmmtitle: "Set password",
  qszmmtitle: "Please set the password",
  qrmmtitle: "Confirm password",
  xmmtitle: "Confirm password",
  szdqtitle: "Location",
  qtxyzmtitle: "Please fill in the verification code",
  qxzzztitle: "Please select",
  yzmtitle: "Verification code",
  ljzctitle: "Register now",
  qxzsftitle: "Please select a province",
  qxzcstitle: "Please select a city",
  qxzqytitle: "Please select an area",
  lcsrmmbyytitle: "Enter the password differently twice",
  hqyzmtitle: "Get verification code",
  zccgtitle: "Successful registration",
  jglxtitle: "Type of organization (choose up to 3)",
  qyttitle: "Enterprise",
  kyttitle: "Scientific research",
  dxttitle: "University",
  fwttitle: "Services",
  tzttitle: "Investment",
  tjttitle: "Submit",
  jgmctitle: "Name of organization",
  qsrjgmctitle: "Please enter an organization name",
  qzsxzyzjglxtitle: "Please select at least one institution type",
  zdxzsgjglxtitle: "Choose up to three mechanism types",
  cdw11gzftitle: "The length is 11 characters",
  zzczmmtitle: "Password reset is in progress. Do you want to continue?",
  sbtz_title: "Declaration notice",
  wsj_title: "No data",
  fbbm_title: "Release department",
  lx_title: "Type",
  zt_title: "Status",
  kssj_title: "Start time",
  jzsj_title: "Deadline",
  more: "More",
  fb: "Publish",
  zhi: "至",
  djsb: "Click to declare",
  ljdh_title: "Fast navigation",
  jscgname_table: "Name of technical achievements",
  ssdwname_table: "Subordinate unit",
  szdwname_table: "Unit",
  hylbname_table: "Industry category",
  cgspname_table: "Outcome level",
  csdname_table: "Maturity",
  fbsjname_table: "Release time",
  kjxq_title: "Demand of science and technology",
  xglj_title: "Related links",
  xl_title: "Educational background",
  dw_title: "Unit",
  zjgdxx_title: "More information from experts",
  zw_title: "Position",
  hy_title: "Industry",
  hylb_title: "Industry category",
  szd_title: "Location",
  dwmc_title: "Company name",
  xmmc_title: "Project name",
  fbsj_title: "Release time",
  ly_title: "Domain",
  lll_title: "Page views",
  zrfs_title: "Mode of transfer",
  cgsp_title: "Outcome level",
  sfcj_title: "Deal or not",
  lxr_title: "Contact person",
  tyshxydmtitle: "Unified social credit code",
  yxqtitle: "Validity period",
  ztitle: "至",
  xmlytitle: "Project source",
  qxzlytitle: "Please select the project source",
  xmlkzxstitle: "Project development form",
  qsrxmmcname_table: "Please enter a project name",
  qsryjkfrs_table:
    "Please enter the number of research and development personnel",
  qxzjzjd_table: "Please select the stage of progress",
  qxzxmlkzxstitle: "Please select the project development form",
  xmdncgxstitle: "Form of project results in the current year",
  qxzxmdncgxstitle:
    "Please select the result form of the current year of the project",
  xmjsjjmbtitle: "Technical and economic objectives of the project",
  qxzxmjsjjmbtitle:
    "Please select the technical and economic objectives of the project",
  xmqsrqtitle: "Project start date",
  qxzxmqsrqtitle: "Please select the project start date",
  xmwcrqtitle: "Project completion date",
  qxzxmwcrqtitle: "Please select the project completion date",
  knxmdlsczyjzjdtitle:
    "The main progress stage of the New Year's project in the current year",
  qxzknxmdlsczyjzjdtitle:
    "Please select the main progress stage of the New Year's project in the current year",
  xmyjkfrytitle: "Project research and development personnel (person)",
  qsrxmyjkfrytitle:
    "Please enter the project research and development personnel (person)",
  xmrysjgzsjtitle: "Actual working hours of project personnel (person-months)",
  qsrxmrysjgzsjtitle: "Please enter staff working hours",
  xmjfzctitle: "Expenditure of project funds (thousands of yuan)",
  qsrxmjfzctitle: "Please enter the project expenditure",
  dwfzrtitle: "Head of Unit",
  tbrtitle: "Filler",
  tbrqtitle: "Filling date",
  qxztbrqtitle: "Please select the filling date",
  tjbtn: "Submit",
  zbmctitle: "Indicator name",
  jldwtitle: "Unit of measurement",
  dmtitle: "Code",
  sltitle: "Quantity",
  jiatitle: "甲",
  yititle: "乙",
  bingtitle: "丙",
  yjkfryqk1title: "First, the situation of research and development personnel",
  yjkfryhj1title: "Total R&D personnel",
  qzglhfwry1title: "Among them: management and service personnel",
  qzglx1title: "Among them: Female",
  qzqzry1title: "Among them: full-time staff",
  qzbkbyjysry1title: "Among them: Bachelor degree or above",
  qzwpry1title: "Among them: external personnel",
  r1title: "人",
  yjkffyqk2title: "Second, the research and development expenses",
  yjkffyhj2title: "Total research and development expenses",
  ryrgfy2title: "1. Personnel labor costs",
  zjtrfy2title: "2. Direct input costs",
  zjfyycqdtfy2title:
    "3. Depreciation expense versus long-term deferred expense",
  wxzctxfy2title: "4. Amortization expense of intangible assets",
  sjfy2title: "5. Design costs",
  zbtsfyysyfy2title: "6. Equipment debugging cost and test cost",
  wttsfyysyfy2title:
    "7. Expenses for commissioning external research and development",
  wtjlyjjg2title: "Commissioned domestic research institutions",
  wtjlgdxx2title: "Entrust domestic institutions of higher learning",
  wtjlqy2title: "Entrusted domestic enterprises",
  wtjwjg2title: "Entrust overseas institutions",
  qtfy2title: "8. Other expenses",
  qy2title: "Thousand dollars",
  yjkfzcqk3title: "III. Research and Development Assets",
  dnxcyyyjkfdgdzc3title:
    "Fixed assets for research and development formed in the current year",
  qizyqhsb3title: "Among them: instruments and equipment",
  yjkfzczjly4title: "IV. Sources of Research and Development Expenditure Funds",
  lzqyzc4title: "1. Self-raised by enterprises",
  lzzfbm4title: "2. From government departments",
  lzyhdk4title: "3. Loans from banks",
  lzfxtz4title: "4. From venture capital",
  lzqtqd4title: "5. From other sources",
  xgzctsqk5title: "V. Implementation of relevant policies",
  sbjjkc5title:
    "Declare and deduct the research and development expenditure of tax reduction and exemption",
  jjkcjmsje5title: "Add and deduct the amount of tax reduction and exemption",
  gxjsqyjmsje5title:
    "Amount of tax reduction and exemption for high-tech enterprises",
  qybyjkfjg6title:
    "Six, enterprises run research and development institutions (domestic)",
  qmjgsg6title: "Number of institutions at the end of the period",
  jgyjkfry6title: "Institutional research and development personnel",
  qzbsby6title: "Among them: Ph.D. graduation",
  ssby6title: "Master's degree",
  jgyjkffy6title: "Institutional research and development expenses",
  qmyqhsbyj6title:
    "Original price of instruments and equipment at the end of the period",
  yjkfccjxgqk7title:
    "VII. Research and Development Output and Related Information",
  zlqk7title: "(a) the patent situation",
  dlzlsqs7title: "Number of patent applications in the current year",
  qzfmzl7title: "Among them: invention patent",
  qmyxfmzls7title:
    "Number of effective invention patents at the end of the period",
  qzybss7title: "Among them: has been implemented",
  zlsyqzrjxks7title: "Patent ownership transfer and license number",
  zlsyqxksr7title: "Patent ownership transfer and license income",
  xcpqk7title: "(2) New products",
  xcpxssr7title: "Sales revenue of new products",
  qzck7title: "Of which: Exports",
  qtqk7title: "(3) Other circumstances",
  qmzcsb7title: "Have registered trademarks at the end of the period",
  fbkjlw7title: "Publish scientific papers",
  xcgjhhybz7title: "Form national or industry standards",
  jian7title: "件",
  pian7title: "篇",
  xiang7title: "项",
  qtxgqk8title: "VIII. Other relevant information",
  jsgchjshqqk8title: "(a) Technical transformation and technology acquisition",
  jsgzjfzc8title: "Expenditure on technical transformation",
  gmjljsjfzc8title: "Expenditure on purchasing domestic technology",
  yjjwjsjfzc8title: "Expenditure on introducing overseas technology",
  yjjwjsdxhxs8title:
    "Expenditure on digestion and absorption of imported overseas technology",
  qybyjkfjg8title:
    "(two) the enterprise to run research and development institutions (overseas)",
  qmqyzjwsldyjkfjgs8title:
    "Number of overseas research and development institutions established by enterprises at the end of the period",
  ge8title: "个",
  dhtabletitledh:
    "Demand Collection Form for Scientific and Technological Achievements of Dehong Technology Transfer Platform",
  bhdh: "Numbering",
  txsjdh: "Filling time",
  dwxxdh: "First, the unit information",
  dwmcdh: "Company name",
  fddbrdh: "Legal representative",
  lxyxdh: "Contact mailbox",
  lxrdh: "Contact person",
  lxdhdh: "Contact number",
  dwrygkdh: "General situation of unit personnel",
  zzrydh: "On-the-job personnel",
  dzysjsrcdh: "Technical talents with college degree or above",
  qzbsdh: "Doctor among them",
  zscqyydh: "Intellectual property ownership/source",
  wgsjzldh: "Design patent",
  syxzldh: "Practical patent",
  fmzldh: "Invention patent",
  rzqdh: "Soft copyright",
  gmdh: "Purchase",
  zzyfdh: "Independent research and development",
  hzkfdh: "Cooperative development",
  qtdh: "Others",
  sfwgxjsqydh: "Whether it is a high-tech enterprise",
  shidh: "是",
  szsbh: "Is the certificate number",
  foubh: "否",
  sfwkjxzxqybh:
    "Whether it is a small and medium-sized science and technology enterprise",
  qtlxscztbh: "Other types of market entities",
  sfjscxcyptbh: "Whether to build an innovation and entrepreneurship platform",
  zckjbh: "Creative space",
  xctdbh: "New World",
  qyjszxbh: "Enterprise Technology Center",
  gcyjzxbh: "Engineering Research Center",
  jscxzxbh: "Technology innovation center",
  kjqyfhqbh: "Technology business incubator",
  snjjyxxbh: "Two, last year's business information (ten thousand yuan)",
  zzcbh: "Total assets",
  jzcbh: "Net assets",
  yysrbh: "营业收入",
  zyftrbh: "R&D investment",
  zyysrbh: "Main business income",
  jlrbh: "Net profit",
  yftrbh: "R&D investment",
  ydwswybh: "1-500,000 yuan",
  wsdwbwybh: "500-5 million yuan",
  wbdwbwybh: "5-10 million yuan",
  yqwwbwybh: "More than 10 million yuan",
  yftrzyysrbblbh: "Proportion of R&D investment to operating income",
  bfz1bh: "1%",
  bfz1d3bh: "1%-3%",
  bfz3d5bh: "3%-5%",
  bfz5d10bh: "5%-10%",
  bfz10bh: "More than 10%",
  zyywjgxjscpbh: "Main business and high-tech products (services)",
  sfhkjzxjjbh: "Have you received special funds for science and technology",
  gjsbh: "National/Provincial",
  sfyhjgtzbh: "Have you received institutional investment",
  ljbh: "Accumulate",
  jgtzjewybh: "[Institutional Investment Amount] Ten Thousand Yuan",
  cpjbqkbh: "III. Basic Information of Products",
  cpmcbh: "Product name",
  ssjslybh: "Technical field",
  dzxxbh: "Electronic information",
  rgzlbh: "Artificial intelligence",
  zinzzgdzbbh: "Intelligent manufacturing of high-end equipment",
  qtbh: "Others",
  xdlybh: "Modern agriculture",
  gjsfubh: "High-tech service",
  xnyjjnjsbh: "New energy and energy saving technology",
  hkhtjsbh: "Aerospace technology",
  zyyhjjsbh: "Resources and environmental technology",
  xclbh: "New material",
  yyhybh: "Application industry",
  xtrhbh: "Information, communication, software, internet",
  llmybh: "Agriculture, forestry, animal husbandry and fishery",
  wshshgzbh: "Health and social work",
  jtyscchyzbh: "Transportation, warehousing and postal services",
  ckybh: "Mining industry",
  szjzbh: "Municipal architecture",
  slhjhgyssglbh: "Water, Environment and Utilities Management",
  kxyjhjsfwybh: "Scientific research and technical services",
  zcybh: "Manufacturing",
  dlrlrqjscshgybh: "Electricity, heat, gas and water production and supply",
  gjzzbh: "International organizations",
  hgbh: "Chemical industry",
  ggglshbzhshzzbh:
    "Public Administration, Social Security and Social Organization",
  zphswfwbh: "Leasing and business services",
  jybh: "Education",
  yysxbh: "Apply attributes",
  yysxcxbh: "Original innovation",
  jseckfbh: "Secondary development of technology",
  jsyjzcxbh: "Technology introduction and re-innovation",
  zhcxbh: "Combinatorial innovation",
  yfjdbh: "Research and development stage",
  yfzbh: "In development",
  xsjdbh: "Small trial stage",
  zsbh: "Pilot test",
  ypbh: "Sample",
  xplbh: "Small batch",
  kcyhbh: "Industrializable",
  kjcgxmxqbbh:
    "IV. Demand for Scientific and Technological Achievements Projects",
  fwxqbh: "Service demand",
  jsrcyjbh: "Introduction of technical talents",
  cgzhyxmhzbh: "Achievement transformation and project cooperation",
  zljskfgmbh: "Patented technology development/purchase",
  xcpyfbh: "New product development",
  qypxbh: "Enterprise training",
  scglbh: "Production management",
  yxchbh: "Marketing planning",
  zyjnbh: "Professional skills",
  xljybh: "Academic education",
  qyzxbh: "Enterprise transformation",
  xyjsjcqkbh: "Basic information of existing technology",
  xyjjdxqwtbh: "Requirements issues/technical requirements to be solved",
  zljsbh: "Patented technology",
  xmhzykfbh: "Project cooperation and development",
  xmsbbh: "Project declaration",
  rcyjbh: "Talent introduction",
  xqjsbh: "Requirements Profile",
  qwhzfsbh: "Expected cooperation time",
  gtkfbh: "Joint development",
  gmfwbh: "Purchase of services",
  slzdbh: "Set up a site",
  qwhzsjbh: "Expected cooperation time",
  dq1dsbh: "Short-term (1-3 years)",
  cq5nysbh: "Long-term (over 5 years)",
  jjxqwzbh: "Until the demand is solved",
  yqtrbh: "Expected input",
  swyyxbh: "Below 100,000 yuan",
  sdsswybh: "100,000-300,000 yuan",
  ssdwsbh: "300,000-500,000 yuan",
  wsdybwbh: "500-1 million yuan",
  ybdybwbh: "1-1.5 million yuan",
  ybwdebbh: "1.5-2 million yuan",
  ebdsbbh: "2-3 million yuan",
  wbysbh: "More than 5 million yuan",
  qtysfwbh: "Others (budget scope)",
  glxxbh: "V. Management information",
  tygkxqxxbh: "Agree to disclose demand information",
  bfgkbh: "Partial disclosure",
  tyxyzjfwbh: "Agree on the need for expert services",
  tycydjjfadsxpjbh:
    "Agree to participate in the screening and evaluation of solutions",
  dcxyjhzjlhddjyhxqbh:
    "Suggestions and demands for cooperation and exchange activities of production, education and research",
  tjbh: "Submit",
  dybh: "Print",
  qtxbhbh: "Please fill in the number",
  qtxdwmcbh: "Please fill in the name of the company",
  qtxlxrbh: "Please fill in the contact person",
  qtxlxdhbh: "Please fill in the contact number",
  zztjztsjbh: "This data is being submitted. Do you want to continue?",
  tjcgbh: "Submission Successful",
  nccenter: "Nickname",
  bindcenter: "Binding",
  sqsmrzcenter: "Apply for real-name authentication",
  smrzcenter: "Real-name authentication",
  sqzjrzcenter: "Expert certification",
  sqjgrzcenter: "Apply for agency certification",
  jgrzcenter: "Institutional accreditation",
  scdlcenter: "Last login",
  xqlcenter: "Demand",
  cgzscenter: "Achievement display",
  zhaqcenter: "Account security",
  dlmmcenter: "Login password",
  yybhzhxxcenter: "Used to protect account information and login security",
  ggcenter: "Change",
  aqsjcenter: "Secure mobile phone",
  yyzktfxcenter:
    "Verified, you can log in or retrieve your password through your mobile phone",
  xgdlmmcenter: "Modify login password",
  dqmmcenter: "Current password",
  xmmcenter: "New password",
  qrmmcenter: "Confirm password",
  mmxgcgcenter: "Password changed successfully!",
  tjcenter: "Submit",
  qxcenter: "Cancel",
  qdcenter: "Determine",
  bdsjhcenter: "Bind a new mobile phone",
  xsjhmcenter: "New mobile phone number",
  yzmcenter: "Verification code",
  hqcenter: "Get verification code",
  qsrsjhmvocenter: "Please enter your mobile phone number",
  cdwsygzfvocenter: "The length is 11 characters",
  qsryzmvocenter: "Please enter the verification code",
  qsrmmvocenter: "Please enter your password",
  qzcsrmmmvocenter: "Please enter your password again",
  lcsrmmbyzvocenter: "The password entered twice is inconsistent!",
  zzxgmmvocenter: "Changing password, do you want to continue?",
  cgvocenter: "Success",
  zzxgsjhmvocenter:
    "Changing mobile phone number is in progress. Do you want to continue?",
  tsvocenter: "Prompt",
  dlsjhcenter: "Login mobile phone number",
  ybdcenter: "Bound",
  wbdcenter: "Bound",
  ghbdcenter: "Change bindings",
  qbdcenter: "Unbind",
  xgmmcenter: "Change password",
  jbxxcenter: "Basic information",
  jbxxdesccenter:
    "Providing correct identity information is helpful for identity verification and improving credit image",
  zsxmccenter: "Real name",
  zsxmcvomcenter: "Please enter your real name",
  xbvomcenter: "Please select a gender",
  cslyvomcenter: "Please select your birthday",
  qsrzssfzhvomcenter: "Please enter the real ID number",
  qscsfzzmvomcenter: "Please upload the front of your ID card",
  qscsfzbmvomcenter: "Please upload the back of your ID card",
  zzthsmxxcenter: "Submitting real name information. Do you want to continue?",
  zzbcgrxxcenter: "Saving personal information. Do you want to continue?",
  xbcenter: "Gender",
  nancenter: "男",
  nvcenter: "女",
  wzcenter: "Unknown",
  cslycenter: "Date of birth",
  sfzhcenter: "ID number",
  sfzzmcenter: "Front of identity document",
  sfzzmdesccenter:
    "Clear original photos of front and back of personal ID card require JPG, GIF and PNG format pictures below 200KB",
  sfzfmcenter: "Inverse side of identity document",
  tjsmxxcenter: "Submit real name information",
  grzlcenter: "Personal data",
  sctxcenter: "Upload avatar",
  sctxdesccenter: "It is recommended to upload an one-inch ID photo",
  yhlccenter: "User nickname",
  szdqcenter: "Location",
  qxzcenter: "Please select",
  yxcenter: "Mailbox",
  yxdesccenter: "Enter your common mailbox",
  jzdzcenter: "Residential address",
  zycenter: "Occupation",
  zydesccenter: "A profession or occupation",
  qqcenter: "QQ",
  qqdesccenter:
    "Please enter the real QQ to facilitate customer service to communicate with you",
  bccenter: "Save",
  qsrnccenter: "Please enter a nickname",
  qsryxcenter: "Please enter the mailbox",
  qsrxjzdcenter: "Please enter your residence address",
  qsrzycenter: "Please enter occupation",
  qsrqqcenter: "Please enter QQ",
  qsctxcenter: "Please upload your avatar",
  qxzsfcenter: "Please select a province",
  qxzcscenter: "Please select a city",
  qxzqycenter: "Please select an area",
  jgrzdesccenter:
    "Providing correct information is helpful for further verification.",
  qymcccenter: "Enterprise name",
  qydzcenter: "Business address",
  fddbrcenter: "Legal representative",
  tyshxydmcenter: "Unified social credit code",
  yyzzcenter: "Business license",
  yyzzdesccenter:
    "Clear business license photos require JPG, GIF and PNG format pictures below 200KB",
  tgrzxxcenter: "Provide authentication information",
  qyyjkfxmqkcenter: "Research and development projects of enterprises",
  qbcenter: "All",
  wshtgcenter: "Not approved",
  yshcenter: "Approved",
  dshcenter: "Pending review",
  sscenter: "Search",
  qsrgjzcenter: "Please enter a keyword",
  xmmccenter: "Project name",
  xmlycenter: "Project source",
  kzxscenter: "Development form",
  kssjcenter: "Start time",
  jssjcenter: "End time",
  fzrcenter: "Responsible person",
  czcenter: "Operation",
  qyyjkfhdcenter: "Enterprise research and development activities",
  dwmccenter: "Company name",
  dwfzrcenter: "Head of Unit",
  tbrcenter: "Filler",
  yxqkscenter: "Start time of validity period",
  yxqjscenter: "End of validity period",
  kjcgxqzjcenter:
    "Demand collection of scientific and technological achievements",
  cpmccenter: "Product name",
  txsjcenter: "Filling time",
  fbkjcgcgxxcenter:
    "Publish scientific and technological achievements-achievements information",
  fbkjcgcgdesccenter:
    "The more complete the information is, the easier it is to get the attention of the target customers",
  fbkjcgjsmccenter: "Technical name",
  hylbcenter: "Industry category",
  zscqztcenter: "Intellectual property status",
  zlmccenter: "Patent name",
  zlhcenter: "Patent number",
  jjcenter: "Brief introduction",
  sfcjcenter: "Deal or not",
  zrfscenter: "Mode of transfer",
  cgszdwcenter: "Unit where the results are located",
  dwlxcenter: "Unit type",
  szdwmccenter: "Name of unit",
  cgszdcenter: "Location of results",
  fbcgzszmclcenter: "Release results display-certification materials",
  fbcgdesczmcenter:
    "Upload certification materials to facilitate customers to further understand the results display",
  xxcycenter: "Emerging industry",
  kjcgpjspcenter:
    "Evaluation level of scientific and technological achievements",
  csdcenter: "Maturity",
  csdzmcenter: "Maturity proof",
  csdzmdesccenter:
    "Clear photos require images in jpg, gif and png formats below 100KB.",
  jstpcenter: "Technical picture",
  jspgjzcenter: "Technical evaluation value",
  lxrcenter: "Contact person",
  sjhmcenter: "Mobile phone number",
  zqsrsjhcenter: "Enter the mobile phone number correctly",
  zqsrlxrcenter: "Fill in the contact person correctly",
  tjkjcgshcenter: "Submit scientific and technological achievements for review",
  glcgcenter: "Management results",
  kjcgmccenter: "Name of scientific and technological achievements",
  zjzkzjxxcenter: "Expert Think Tank-Expert Information",
  zjzkzjxxdesccenter:
    "Providing correct identity information is helpful for identity verification and improving credit image",
  zgxlcenter: "Highest educational background",
  gzdwcenter: "Work unit",
  zwcenter: "Position",
  lxdhcenter: "Contact number",
  fwalcenter: "Service case",
  tjshcenter: "Submit for review",
  glzjzkxicenter: "Management expert think tank information",
  zjmzcenter: "Name of expert",
  zjhycenter: "Expert industry",
  tjrqcenter: "Add date",
  zcxlcenter: "Professional title/educational background",
  fbjsxqxqxxcenter: "Publish technical requirements-requirements information",
  fbjsxqdesccenter:
    "Fill in the information correctly and pass the audit further",
  xqfmccenter: "Demand cover",
  xqfmdesccenter:
    "The technical requirements cover page displayed in the list.",
  xqmccenter: "Requirement name",
  fbxqlxxxcenter: "Publish technical requirements-contact information",
  fbxqlxxxdesccenter:
    "Fill in the contact information correctly, which is convenient for customers to contact you",
  tjjsxqshcenter: "Submit technical requirements review",
  glxqcenter: "Management requirements",
  xiugaicenter: "Modify",
  sanchucenter: "Delete",
  kjcglycenter: "Message of scientific and technological achievements",
  lybkcenter: "Message section",
  lydxcenter: "Message object",
  dhcscenter: "Number of conversations",
  jsxqlycenter: "Technical requirements message",
  kjcgcenter: "Message of scientific and technological achievements",
  zjzklycenter: "Expert think tank message",
  jzmmL: "Remember the password",
  "welogin": "Welcome to login",
  "weuser": "User name",
  "wepass": "Password",
  "weother": "Other logins"
};
