export default {
  wecome: "您好，欢迎来到德宏州技术转移服务平台！",
  login: "登录",
  nick: "昵称",
  bind: "绑定",
  search: "搜索",
  qxzxyssdflsearch: "请选择需要搜索的分类",
  searchP: "请选择",
  searchI: "请输入内容",
  register: "注册",
  center: "个人中心",
  zcxwtitle: "政策新闻",
  tzgotitle: "通知公告",
  kjhztitle: "科技合作",
  kjcgtitle: "科技成果",
  kjjrtitle: "科技金融",
  zjzktitle: "专家智库",
  zjzkftitle: "专家智库筛选",
  kjcgftitle: "科技成果筛选",
  jsxqtitle: "技术需求",
  jsxqftitle: "技术需求筛选",
  jslinytitle: "技术领域",
  fbxqtitle: "发布需求",
  wxxztitle: "资料下载",
  zbdwname: "主办单位",
  dhname: "电话",
  jszcname: "技术支持",
  dzname: "地址",
  syname: "首页",
  fbcg: "发布成果",
  xmsb: "项目管理",
  sbtz: "申报通知",
  all: "全部",
  in_progress: "进行中",
  stop: "已截止",
  notopen: "未开始",
  expired: "已过期",
  kjtj_title: "科技统计",
  lilantitle: "浏览",
  layuantitle: "来源",
  lilanltitle: "浏览量",
  timetitle: "时间",
  zwtitle: "正文",
  fbsjtitle: "发布时间",
  hyfltitle: "行业分类",
  zjjjtitle: "专家简介",
  lxzjtitle: "联系专家",
  xlxwtitle: "学历/学位",
  zczwtitle: "职称职务",
  xmtitle: "姓名",
  fjtitle: "附件",
  xqtitle: "详情",
  hylbtitle: "行业类别",
  jssptitle: "技术水平",
  lxdhtitle: "联系电话",
  rcktitle: "人查看",
  ljcktitle: "累计查看",
  zrfstitle: "转让方式",
  zscqzttitle: "知识产权状态",
  zlmcttitle: "专利名称",
  cgszdwttitle: "成果所在单位",
  cgjjttitle: "成果简介",
  zlhttitle: "专利号",
  yxtitle: "邮箱",
  xqjjtitle: "需求简介",
  lxtitle: "联系",
  my: "面议",
  jg: "价格",
  mslxtitle: "马上联系",
  swdlsfjx: "尚未登录,前往登录, 是否继续?",
  ts: "提示",
  ok: "确定",
  successs: "成功",
  cancel: "取消",
  qsrnxsdh: "请输入你想说的话",
  lxcg: "联系成功！",
  lycghxgryhqdlx:
    "留言成功后, 相关人员会与你取得联系, 请保持电话畅通, 是否继续?",
  qyyjkfxmqk_title: "企业研究开发项目情况",
  qyyjkfhdjxgqktitle: "企业研究开发活动及相关情况",
  qsrsjhmtitle: "请输入手机号码",
  qsrdlzhtitle: "请输入登录账号",
  qsrdlmmtitle: "请输入登录密码",
  wecomestitle: "欢迎你!",
  cd3d5title: "长度在 3 到 15 个字符",
  qsrmmtitle: "请输入密码",
  wjmmtitle: "忘记密码",
  fhsytitle: "返回首页",
  grzctitle: "个人注册",
  jgzctitle: "机构注册",
  jjxzgzytitle: "即将下载该资源, 是否继续?",
  sjhmtitle: "手机号码",
  szmmtitle: "设置密码",
  qszmmtitle: "请设置密码",
  qrmmtitle: "确认密码",
  xmmtitle: "确认密码",
  szdqtitle: "所在地区",
  qtxyzmtitle: "请填写验证码",
  qxzzztitle: "请选择",
  yzmtitle: "验证码",
  ljzctitle: "立即注册",
  qxzsftitle: "请选择省份",
  qxzcstitle: "请选择城市",
  qxzqytitle: "请选择区域",
  lcsrmmbyytitle: "两次输入密码不一样",
  hqyzmtitle: "获取验证码",
  zccgtitle: "注册成功",
  jglxtitle: "机构类型（最多选3个）",
  qyttitle: "企业",
  kyttitle: "科研",
  dxttitle: "大学",
  fwttitle: "服务",
  tzttitle: "投资",
  tjttitle: "提交",
  jgmctitle: "机构名称",
  qsrjgmctitle: "请输入机构名称",
  qzsxzyzjglxtitle: "请选择至少选择一个机构类型",
  zdxzsgjglxtitle: "最多选择三个机构类型",
  cdw11gzftitle: "长度为 11 个字符",
  zzczmmtitle: "正在进行重置密码, 是否继续?",
  sbtz_title: "申报通知",
  wsj_title: "无数据",
  fbbm_title: "发布部门",
  lx_title: "类型",
  zt_title: "状态",
  kssj_title: "开始时间",
  jzsj_title: "截止时间",
  more: "更多",
  fb: "发布",
  zhi: "至",
  djsb: "点击申报",
  ljdh_title: "快速导航",
  jscgname_table: "技术成果名称",
  ssdwname_table: "所属单位",
  szdwname_table: "所在单位",
  hylbname_table: "行业类别",
  cgspname_table: "成果水平",
  csdname_table: "成熟度",
  fbsjname_table: "发布时间",
  kjxq_title: "科技需求",
  xglj_title: "相关链接",
  xl_title: "学历",
  dw_title: "单位",
  zjgdxx_title: "专家更多信息",
  zw_title: "职务",
  hy_title: "行业",
  hylb_title: "行业类别",
  szd_title: "所在地",
  dwmc_title: "单位名称",
  xmmc_title: "项目名称",
  fbsj_title: "发布时间",
  ly_title: "领域",
  lll_title: "浏览量",
  zrfs_title: "转让方式",
  cgsp_title: "成果水平",
  sfcj_title: "是否成交",
  lxr_title: "联系人",
  tyshxydmtitle: "统一社会信用代码",
  yxqtitle: "有效期",
  ztitle: "至",
  xmlytitle: "项目来源",
  qxzlytitle: "请选择项目来源",
  xmlkzxstitle: "项目开展形式",
  qsrxmmcname_table: "请输入项目名称",
  qsryjkfrs_table: "请输入研究开发人数",
  qxzjzjd_table: "请选择进展阶段",
  qxzxmlkzxstitle: "请选择项目开展形式",
  xmdncgxstitle: "项目当年成果形式",
  qxzxmdncgxstitle: "请选择项目当年成果形式",
  xmjsjjmbtitle: "项目技术经济目标",
  qxzxmjsjjmbtitle: "请选择项目技术经济目标",
  xmqsrqtitle: "项目起始日期",
  qxzxmqsrqtitle: "请选择项目起始日期",
  xmwcrqtitle: "项目完成日期",
  qxzxmwcrqtitle: "请选择项目完成日期",
  knxmdlsczyjzjdtitle: "跨年项目当年所处主要进展阶段",
  qxzknxmdlsczyjzjdtitle: "请选择跨年项目当年所处主要进展阶段",
  xmyjkfrytitle: "项目研究开发人员(人)",
  qsrxmyjkfrytitle: "请输入项目研究开发人员(人)",
  xmrysjgzsjtitle: "项目人员实际工作时间(人月)",
  qsrxmrysjgzsjtitle: "请输入人员工作时间",
  xmjfzctitle: "项目经费支出(千元)",
  qsrxmjfzctitle: "请输入项目经费支出",
  dwfzrtitle: "单位负责人",
  tbrtitle: "填表人",
  tbrqtitle: "填报日期",
  qxztbrqtitle: "请选择填报日期",
  tjbtn: "提交",
  zbmctitle: "指标名称",
  jldwtitle: "计量单位",
  dmtitle: "代码",
  sltitle: "数量",
  jiatitle: "甲",
  yititle: "乙",
  bingtitle: "丙",
  yjkfryqk1title: "一、研究开发人员情况",
  yjkfryhj1title: "研究开发人员合计",
  qzglhfwry1title: "其中：管理和服务人员",
  qzglx1title: "其中：女性",
  qzqzry1title: "其中：全职人员",
  qzbkbyjysry1title: "其中：本科毕业及以上人员",
  qzwpry1title: "其中：外聘人员",
  r1title: "人",
  yjkffyqk2title: "二、研究开发费用情况",
  yjkffyhj2title: "研究开发费用合计",
  ryrgfy2title: "1.人员人工费用",
  zjtrfy2title: "2.直接投入费用",
  zjfyycqdtfy2title: "3.折旧费用与长期待摊费用",
  wxzctxfy2title: "4.无形资产摊销费用",
  sjfy2title: "5.设计费用",
  zbtsfyysyfy2title: "6.装备调试费用与试验费用",
  wttsfyysyfy2title: "7.委托外部研究开发费用",
  wtjlyjjg2title: "委托境内研究机构",
  wtjlgdxx2title: "委托境内高等学校",
  wtjlqy2title: "委托境内企业",
  wtjwjg2title: "委托境外机构",
  qtfy2title: "8.其他费用",
  qy2title: "千元",
  yjkfzcqk3title: "三、研究开发资产情况",
  dnxcyyyjkfdgdzc3title: "当年形成用于研究开发的固定资产",
  qizyqhsb3title: "其中：仪器和设备",
  yjkfzczjly4title: "四、研究开发支出资金来源",
  lzqyzc4title: "1.来自企业自筹",
  lzzfbm4title: "2.来自政府部门",
  lzyhdk4title: "3.来自银行贷款",
  lzfxtz4title: "4.来自风险投资",
  lzqtqd4title: "5.来自其他渠道",
  xgzctsqk5title: "五、相关政策落实情况",
  sbjjkc5title: "申报加计扣除减免税的研究开发支出",
  jjkcjmsje5title: "加计扣除减免税金额",
  gxjsqyjmsje5title: "高新技术企业减免税金额",
  qybyjkfjg6title: "六、企业办研究开发机构（境内）情况",
  qmjgsg6title: "期末机构数",
  jgyjkfry6title: "机构研究开发人员",
  qzbsby6title: "其中：博士毕业",
  ssby6title: "硕士毕业",
  jgyjkffy6title: "机构研究开发费用",
  qmyqhsbyj6title: "期末仪器和设备原价",
  yjkfccjxgqk7title: "七、研究开发产出及相关情况",
  zlqk7title: "(一)专利情况",
  dlzlsqs7title: "当年专利申请数",
  qzfmzl7title: "其中：发明专利",
  qmyxfmzls7title: "期末有效发明专利数",
  qzybss7title: "其中：已被实施",
  zlsyqzrjxks7title: "专利所有权转让及许可数",
  zlsyqxksr7title: "专利所有权转让及许可收入",
  xcpqk7title: "(二)新产品情况",
  xcpxssr7title: "新产品销售收入",
  qzck7title: "其中：出口",
  qtqk7title: "(三)其他情况",
  qmzcsb7title: "期末拥有注册商标",
  fbkjlw7title: "发表科技论文",
  xcgjhhybz7title: "形成国家或行业标准",
  jian7title: "件",
  pian7title: "篇",
  xiang7title: "项",
  qtxgqk8title: "八、其他相关情况",
  jsgchjshqqk8title: "(一)技术改造和技术获取情况",
  jsgzjfzc8title: "技术改造经费支出",
  gmjljsjfzc8title: "购买境内技术经费支出",
  yjjwjsjfzc8title: "引进境外技术经费支出",
  yjjwjsdxhxs8title: "引进境外技术的消化吸收经费支出",
  qybyjkfjg8title: "(二)企业办研究开发机构（境外）情况",
  qmqyzjwsldyjkfjgs8title: "期末企业在境外设立的研究开发机构数",
  ge8title: "个",
  dhtabletitledh: "德宏州技术转移平台科技成果需求征集表",
  bhdh: "编号",
  txsjdh: "填写时间",
  dwxxdh: "一、单位信息",
  dwmcdh: "单位名称",
  fddbrdh: "法定代表人",
  lxyxdh: "联系邮箱",
  lxrdh: "联系人",
  lxdhdh: "联系电话",
  dwrygkdh: "单位人员概况",
  zzrydh: "在职人员",
  dzysjsrcdh: "大专以上技术人才",
  qzbsdh: "其中博士",
  zscqyydh: "知识产权拥有量/来源",
  wgsjzldh: "外观设计专利",
  syxzldh: "实用型专利",
  fmzldh: "发明专利",
  rzqdh: "软著权",
  gmdh: "购买",
  zzyfdh: "自主研发",
  hzkfdh: "合作开发",
  qtdh: "其他",
  sfwgxjsqydh: "是否为高新技术企业",
  shidh: "是",
  szsbh: " 是 证书编号 ",
  foubh: "否",
  sfwkjxzxqybh: "是否为科技型中小企业",
  qtlxscztbh: "其他类型市场主体",
  sfjscxcyptbh: "是否建设创新创业平台",
  zckjbh: "众创空间",
  xctdbh: "新创天地",
  qyjszxbh: "企业技术中心",
  gcyjzxbh: "工程研究中心",
  jscxzxbh: "技术创新中心",
  kjqyfhqbh: "科技企业孵化器",
  snjjyxxbh: "二、上年度经营信息（万元）",
  zzcbh: "总资产",
  jzcbh: "净资产",
  yysrbh: "营业收入",
  zyftrbh: "研发投入",
  zyysrbh: "主营业务收入",
  jlrbh: "净利润",
  yftrbh: "研发投入",
  ydwswybh: "1-50万元",
  wsdwbwybh: "50-500万元",
  wbdwbwybh: "500-1000万元",
  yqwwbwybh: "1000万元以上",
  yftrzyysrbblbh: "研发投入占营业收入比例",
  bfz1bh: "1%",
  bfz1d3bh: "1%-3%",
  bfz3d5bh: "3%-5%",
  bfz5d10bh: "5%-10%",
  bfz10bh: "10%以上",
  zyywjgxjscpbh: "主营业务及高新技术产品（服务）",
  sfhkjzxjjbh: "是否获科技专项资金",
  gjsbh: "国家/省",
  sfyhjgtzbh: "是否已获机构投资",
  ljbh: "累计",
  jgtzjewybh: "[机构投资金额] 万元",
  cpjbqkbh: "三、产品基本情况",
  cpmcbh: "产品名称",
  ssjslybh: "所属技术领域",
  dzxxbh: "电子信息",
  rgzlbh: "人工智能",
  zinzzgdzbbh: "智能制造高端装备",
  qtbh: "其他",
  xdlybh: "现代农业",
  gjsfubh: "高技术服务",
  xnyjjnjsbh: "新能源及节能技术",
  hkhtjsbh: "航空航天技术",
  zyyhjjsbh: "资源与环境技术",
  xclbh: "新材料",
  yyhybh: "应用行业",
  xtrhbh: "信息、通讯、软件、互联网",
  llmybh: "农林牧渔",
  wshshgzbh: "卫生和社会工作",
  jtyscchyzbh: "交通运输、仓储和邮政",
  ckybh: "采矿业",
  szjzbh: "市政建筑",
  slhjhgyssglbh: "水利、环境和公用设施管理",
  kxyjhjsfwybh: "科学研究和技术服务业",
  zcybh: "制造业",
  dlrlrqjscshgybh: "电力、热力、燃气及水生产和供应",
  gjzzbh: "国际组织",
  hgbh: "化工",
  ggglshbzhshzzbh: "公共管理、社会保障和社会组织",
  zphswfwbh: "租赁和商务服务",
  jybh: "教育",
  yysxbh: "应用属性",
  yysxcxbh: "原始性创新",
  jseckfbh: "技术二次开发",
  jsyjzcxbh: "技术引进再创新",
  zhcxbh: "组合创新",
  yfjdbh: "研发阶段",
  yfzbh: "研发中",
  xsjdbh: "小试阶段",
  zsbh: "中试",
  ypbh: "样品",
  xplbh: "小批量",
  kcyhbh: "可产业化",
  kjcgxmxqbbh: "四、科技成果项目需求情况",
  fwxqbh: "服务需求",
  jsrcyjbh: "技术人才引进",
  cgzhyxmhzbh: "成果转化与项目合作",
  zljskfgmbh: "专利技术开发/购买",
  xcpyfbh: "新产品研发",
  qypxbh: "企业培训",
  scglbh: "生产管理",
  yxchbh: "营销策划",
  zyjnbh: "专业技能",
  xljybh: "学历教育",
  qyzxbh: "企业转型",
  xyjsjcqkbh: "现有技术基础情况",
  xyjjdxqwtbh: "需要解决的问题/技术问题",
  zljsbh: "专利技术",
  xmhzykfbh: "项目合作与开发",
  xmsbbh: "项目申报",
  rcyjbh: "人才引进",
  xqjsbh: "需求简介",
  qwhzfsbh: "期望合作时间",
  gtkfbh: "共同开发",
  gmfwbh: "购买服务",
  slzdbh: "设立站点",
  qwhzsjbh: "期望合作时间",
  dq1dsbh: "短期（1-3年）",
  cq5nysbh: "长期（5年以上）",
  jjxqwzbh: "解决需求为止",
  yqtrbh: "预期投入",
  swyyxbh: "10万元以下",
  sdsswybh: "10-30万元",
  ssdwsbh: "30-50万元",
  wsdybwbh: "50-100万元",
  ybdybwbh: "100-150万元",
  ybwdebbh: "150-200万元",
  ebdsbbh: "200-300万元",
  wbysbh: "500万元以上",
  qtysfwbh: "其他（预算范围）",
  glxxbh: "五、管理信息",
  tygkxqxxbh: "同意公开需求信息",
  bfgkbh: "部分公开",
  tyxyzjfwbh: "同意需要专家服务",
  tycydjjfadsxpjbh: "同意参与对解决方案的筛选评价",
  dcxyjhzjlhddjyhxqbh: "对产学研合作交流活动的建议和需求",
  tjbh: "提交",
  dybh: "打印",
  qtxbhbh: "请填写编号",
  qtxdwmcbh: "请填写单位名称",
  qtxlxrbh: "请填写联系人",
  qtxlxdhbh: "请填写联系电话",
  zztjztsjbh: "正在提交这条数据, 是否继续?",
  tjcgbh: "提交成功",
  nccenter: "昵称",
  bindcenter: "绑定",
  sqsmrzcenter: "申请实名认证",
  smrzcenter: "实名认证",
  sqzjrzcenter: "专家认证",
  sqjgrzcenter: "申请机构认证",
  jgrzcenter: "机构认证",
  scdlcenter: "上次登录",
  xqlcenter: "需求",
  cgzscenter: "成果展示",
  zhaqcenter: "账户安全",
  dlmmcenter: "登录密码",
  yybhzhxxcenter: "用于保护账号信息和登录安全",
  ggcenter: "更改",
  aqsjcenter: "安全手机",
  yyzktfxcenter: "已验证，可通过手机登录或找回密码",
  xgdlmmcenter: "修改登录密码",
  dqmmcenter: "当前密码",
  xmmcenter: "新密码",
  qrmmcenter: "确认密码",
  mmxgcgcenter: "密码修改成功!",
  tjcenter: "提交",
  qxcenter: "取消",
  qdcenter: "确定",
  bdsjhcenter: "绑定新手机",
  xsjhmcenter: "新手机号码",
  yzmcenter: "验证码",
  hqcenter: "获取验证码",
  qsrsjhmvocenter: "请输入手机号码",
  cdwsygzfvocenter: "长度为 11 个字符",
  qsryzmvocenter: "请输入验证码",
  qsrmmvocenter: "请输入密码",
  qzcsrmmmvocenter: "请再次输入密码",
  lcsrmmbyzvocenter: "两次输入密码不一致!",
  zzxgmmvocenter: "正在修改密码, 是否继续?",
  cgvocenter: "成功",
  zzxgsjhmvocenter: "正在进行换绑手机号, 是否继续?",
  tsvocenter: "提示",
  dlsjhcenter: "登录手机号",
  ybdcenter: "已绑定",
  wbdcenter: "已绑定",
  ghbdcenter: "更换绑定",
  qbdcenter: "去绑定",
  xgmmcenter: "修改密码",
  jbxxcenter: "基本信息",
  jbxxdesccenter: "提供正确的身份信息，有助于进行身份验证，提升信用形象",
  zsxmccenter: "真实姓名",
  zsxmcvomcenter: "请输入真实姓名",
  xbvomcenter: "请选择性别",
  cslyvomcenter: "请选择生日",
  qsrzssfzhvomcenter: "请输入真实身份证号",
  qscsfzzmvomcenter: "请上传身份证正面",
  qscsfzbmvomcenter: "请上传身份证背面",
  zzthsmxxcenter: "正在提交实名信息, 是否继续?",
  zzbcgrxxcenter: "正在保存个人信息, 是否继续?",
  xbcenter: "性别",
  nancenter: "男",
  nvcenter: "女",
  wzcenter: "未知",
  cslycenter: "出生年月",
  sfzhcenter: "身份证号",
  sfzzmcenter: "身份证件正面",
  sfzzmdesccenter:
    "清晰的个人身份证正反面原件照片要求200KB以下的JPG、GIF、PNG格式的图片",
  sfzfmcenter: "身份证件反面",
  tjsmxxcenter: "提交实名信息",
  grzlcenter: "个人资料",
  sctxcenter: "上传头像",
  sctxdesccenter: "建议上传一寸证件照",
  yhlccenter: "用户昵称",
  szdqcenter: "所在地区",
  qxzcenter: "请选择",
  yxcenter: "邮箱",
  yxdesccenter: "输入您常用邮箱",
  jzdzcenter: "居住地址",
  zycenter: "职业",
  zydesccenter: "从事的行业或职业",
  qqcenter: "QQ",
  qqdesccenter: "请输入真实QQ,方便客服与您交流",
  bccenter: "保存",
  qsrnccenter: "请输入昵称",
  qsryxcenter: "请输入邮箱",
  qsrxjzdcenter: "请输入居住地址",
  qsrzycenter: "请输入职业",
  qsrqqcenter: "请输入QQ",
  qsctxcenter: "请上传头像",
  qxzsfcenter: "请选择省份",
  qxzcscenter: "请选择城市",
  qxzqycenter: "请选择区域",
  jgrzdesccenter: "提供正确的信息，有助于进一步验证。",
  qymcccenter: "企业名称",
  qydzcenter: "企业地址",
  fddbrcenter: "法定代表人",
  tyshxydmcenter: "统一社会信用代码",
  yyzzcenter: "营业执照",
  yyzzdesccenter:
    "清晰的工商营业执照照片要求200KB以下的JPG、GIF、PNG格式的图片",
  tgrzxxcenter: "提供认证信息",
  qyyjkfxmqkcenter: "企业研究开发项目情况",
  qbcenter: "全部",
  wshtgcenter: "未审核通过",
  yshcenter: "已审核",
  dshcenter: "待审核",
  sscenter: "搜索",
  qsrgjzcenter: "请输入关键字",
  xmmccenter: "项目名称",
  xmlycenter: "项目来源",
  kzxscenter: "开展形式",
  kssjcenter: "开始时间",
  jssjcenter: "结束时间",
  fzrcenter: "负责人",
  czcenter: "操作",
  qyyjkfhdcenter: "企业研究开发活动",
  dwmccenter: "单位名称",
  dwfzrcenter: "单位负责人",
  tbrcenter: "填表人",
  yxqkscenter: "有效期开始时间",
  yxqjscenter: "有效期结束时间",
  kjcgxqzjcenter: "科技成果需求征集",
  cpmccenter: "产品名称",
  txsjcenter: "填写时间",
  fbkjcgcgxxcenter: "发布科技成果- 成果信息",
  fbkjcgcgdesccenter: "信息完整度越高，越容易获得目标客户的关注",
  fbkjcgjsmccenter: "技术名称",
  hylbcenter: "行业类别",
  zscqztcenter: "知识产权状态",
  zlmccenter: "专利名称",
  zlhcenter: "专利号",
  jjcenter: "简介",
  sfcjcenter: "是否成交",
  zrfscenter: "转让方式",
  cgszdwcenter: "成果所在单位",
  dwlxcenter: "单位类型",
  szdwmccenter: "所在单位名称",
  cgszdcenter: "成果所在地",
  fbcgzszmclcenter: "发布成果展示 - 证明材料",
  fbcgdesczmcenter: "上传证明材料，方便客户进一步了解成果展示",
  xxcycenter: "新兴产业",
  kjcgpjspcenter: "科技成果评价水平",
  csdcenter: "成熟度",
  csdzmcenter: "成熟度证明",
  csdzmdesccenter: "清晰的照片要求100KB以下的jpg、gif、png格式的图片。",
  jstpcenter: "技术图片",
  jspgjzcenter: "技术评估价值",
  lxrcenter: "联系人",
  sjhmcenter: "手机号码",
  zqsrsjhcenter: "正确输入手机号",
  zqsrlxrcenter: "正确填写联系人",
  tjkjcgshcenter: "提交科技成果审核",
  glcgcenter: "管理成果",
  kjcgmccenter: "科技成果名称",
  zjzkzjxxcenter: "专家智库-专家信息",
  zjzkzjxxdesccenter: "提供正确的身份信息，有助于进行身份验证，提升信用形象",
  zgxlcenter: "最高学历",
  gzdwcenter: "工作单位",
  zwcenter: "职务",
  lxdhcenter: "联系电话",
  fwalcenter: "服务案例",
  tjshcenter: "提交审核",
  glzjzkxicenter: "管理专家智库信息",
  zjmzcenter: "专家名字",
  zjhycenter: "专家行业",
  tjrqcenter: "添加日期",
  zcxlcenter: "职称/学历",
  fbjsxqxqxxcenter: "发布技术需求 - 需求信息",
  fbjsxqdesccenter: "正确填写信息，进一步通过审核",
  xqfmccenter: "需求封面",
  xqfmdesccenter: "列表处显示的技术需求封面。",
  xqmccenter: "需求名称",
  fbxqlxxxcenter: "发布技术需求 - 联系信息",
  fbxqlxxxdesccenter: "正确填写联系信息，方便客户与您联系",
  tjjsxqshcenter: "提交技术需求审核",
  glxqcenter: "管理需求",
  xiugaicenter: "修改",
  sanchucenter: "删除",
  kjcglycenter: "科技成果留言",
  lybkcenter: "留言板块",
  lydxcenter: "留言对象",
  dhcscenter: "对话次数",
  jsxqlycenter: "技术需求留言",
  kjcgcenter: "科技成果留言",
  zjzklycenter: "专家智库留言",
  jzmmL: "记住密码",
  welogin: '欢迎登录',
  weuser: '用户名',
  wepass: '密  码',
  weother: '其他登录',
};
