export default {
  wecome: "สวัสดียินดีต้อนรับสู่แพลตฟอร์มบริการถ่ายโอนเทคโนโลยี Dehong！",
  login: "ล็อกอิน",
  nick: "ชื่อเล่น",
  bind: "ผูก",
  search: "ค้นหา",
  qxzxyssdflsearch: "โปรดเลือกหมวดหมู่ที่คุณต้องการค้นหา",
  searchP: "กรุณาเลือก",
  searchI: "กรุณากรอกเนื้อหา",
  register: "ลงทะเบียน",
  center: "ศูนย์บริการส่วนบุคคล",
  zcxwtitle: "ข่าวนโยบาย",
  tzgotitle: "ประกาศแจ้ง",
  kjhztitle: "ความร่วมมือทางวิทยาศาสตร์และเทคโนโลยี",
  kjcgtitle: "ความสำเร็จทางวิทยาศาสตร์และเทคโนโลยี",
  kjjrtitle: "การเงินเทคโนโลยี",
  zjzktitle: "ผู้เชี่ยวชาญคิดว่ารถถัง",
  zjzkftitle: "ผู้เชี่ยวชาญคิดว่าการคัดกรองรถถัง",
  kjcgftitle: "การคัดกรองความสำเร็จทางวิทยาศาสตร์และเทคโนโลยี",
  jsxqtitle: "ข้อกำหนดทางเทคนิค",
  jsxqftitle: "การคัดกรองข้อกำหนดทางเทคนิค",
  jslinytitle: "ด้านเทคนิค",
  fbxqtitle: "ข้อกำหนดในการเผยแพร่",
  wxxztitle: "ดาวน์โหลดเอกสาร",
  zbdwname: "ผู้จัดงาน",
  dhname: "โทรศัพท์",
  jszcname: "การสนับสนุนทางเทคนิค",
  dzname: "ที่อยู่",
  syname: "หน้าแรก",
  fbcg: "เผยแพร่ผล",
  xmsb: "การบริหารจัดการโครงการ",
  sbtz: "ประกาศประกาศ",
  all: "ทั้งหมด",
  in_progress: "อยู่ระหว่างดำเนินการ",
  stop: "หมดเขตแล้ว",
  notopen: "ยังไม่เริ่ม",
  expired: "หมดอายุแล้ว",
  kjtj_title: "สถิติวิทยาศาสตร์และเทคโนโลยี",
  lilantitle: "เรียกดู",
  layuantitle: "ที่มา",
  lilanltitle: "ยอดวิว",
  timetitle: "เวลา",
  zwtitle: "ข้อความ",
  fbsjtitle: "เวลาที่วางจำหน่าย",
  hyfltitle: "การจำแนกประเภทอุตสาหกรรม",
  zjjjtitle: "ประวัติผู้เชี่ยวชาญ",
  lxzjtitle: "ติดต่อผู้เชี่ยวชาญ",
  xlxwtitle: "วุฒิการศึกษา / ปริญญา",
  zczwtitle: "ชื่อตำแหน่ง ตำแหน่ง",
  xmtitle: "ชื่อ",
  fjtitle: "เอกสารแนบ",
  xqtitle: "รายละเอียด",
  hylbtitle: "ประเภทอุตสาหกรรม",
  jssptitle: "ระดับเทคนิค",
  lxdhtitle: "เบอร์ติดต่อ",
  rcktitle: "คนดู",
  ljcktitle: "ดูสะสม",
  zrfstitle: "วิธีการโอน",
  zscqzttitle: "สถานะทรัพย์สินทางปัญญา",
  zlmcttitle: "ชื่อสิทธิบัตร",
  cgszdwttitle: "หน่วยผลลัพธ์",
  cgjjttitle: "ความรู้เบื้องต้นเกี่ยวกับผลลัพธ์",
  zlhttitle: "เลขที่สิทธิบัตร",
  yxtitle: "กล่องจดหมาย",
  xqjjtitle: "แนะนำสั้น ๆ เกี่ยวกับความต้องการ",
  lxtitle: "ติดต่อ",
  my: "ต่อรองได้",
  jg: "ราคา",
  mslxtitle: "ติดต่อตอนนี้",
  swdlsfjx: "ยังไม่ได้เข้าสู่ระบบไปที่เข้าสู่ระบบดำเนินการต่อหรือไม่",
  ts: "คำใบ้",
  ok: "แน่นอน",
  successs: "สำเร็จ",
  cancel: "ยกเลิก",
  qsrnxsdh: "กรุณาใส่สิ่งที่คุณต้องการจะพูด",
  lxcg: "ติดต่อสำเร็จ！",
  lycghxgryhqdlx:
    "หลังจากข้อความสำเร็จแล้วบุคลากรที่เกี่ยวข้องจะติดต่อคุณโปรดเปิดโทรศัพท์ไว้คุณต้องการดำเนินการต่อหรือไม่？",
  qyyjkfxmqk_title: "โครงการวิจัยและพัฒนาองค์กร",
  qyyjkfhdjxgqktitle:
    "กิจกรรมการวิจัยและพัฒนาขององค์กรและสถานการณ์ที่เกี่ยวข้อง",
  qsrsjhmtitle: "กรุณาใส่หมายเลขโทรศัพท์มือถือของคุณ",
  qsrdlzhtitle: "โปรดป้อนบัญชีเข้าสู่ระบบของคุณ",
  qsrdlmmtitle: "โปรดป้อนรหัสผ่านเข้าสู่ระบบของคุณ",
  wecomestitle: "ยินดีต้อนรับคุณ！",
  cd3d5title: "ความยาว 3 ถึง 15 ตัวอักษร",
  qsrmmtitle: "กรุณาใส่รหัสผ่าน",
  wjmmtitle: "ลืมรหัสผ่าน",
  fhsytitle: "กลับไปที่หน้าแรก",
  grzctitle: "การลงทะเบียนส่วนบุคคล",
  jgzctitle: "การลงทะเบียนสถาบัน",
  jjxzgzytitle: "ทรัพยากรจะถูกดาวน์โหลดเร็ว ๆ นี้คุณต้องการดำเนินการต่อหรือไม่",
  sjhmtitle: "เบอร์มือถือ",
  szmmtitle: "ตั้งรหัสผ่าน",
  qszmmtitle: "กรุณาตั้งรหัสผ่าน",
  qrmmtitle: "ยืนยันรหัสผ่าน",
  xmmtitle: "ยืนยันรหัสผ่าน",
  szdqtitle: "พื้นที่ที่ตั้งอยู่",
  qtxyzmtitle: "กรุณากรอกรหัสยืนยัน",
  qxzzztitle: "กรุณาเลือก",
  yzmtitle: "รหัสยืนยัน",
  ljzctitle: "ลงทะเบียนตอนนี้",
  qxzsftitle: "กรุณาเลือกจังหวัด",
  qxzcstitle: "กรุณาเลือกเมือง",
  qxzqytitle: "กรุณาเลือกพื้นที่",
  lcsrmmbyytitle: "การป้อนรหัสผ่านสองครั้งไม่เหมือนกัน",
  hqyzmtitle: "รับรหัสยืนยัน",
  zccgtitle: "ลงทะเบียนเรียบร้อยแล้ว",
  jglxtitle: "ประเภทสถาบัน (เลือกได้สูงสุด 3 แห่ง)",
  qyttitle: "กิจการ",
  kyttitle: "การวิจัยทางวิทยาศาสตร์",
  dxttitle: "มหาวิทยาลัย",
  fwttitle: "บริการ",
  tzttitle: "ลงทุน",
  tjttitle: "ส่ง",
  jgmctitle: "ชื่อสถาบัน",
  qsrjgmctitle: "กรุณาใส่ชื่อสถาบัน",
  qzsxzyzjglxtitle: "โปรดเลือก เลือกประเภทสถาบันอย่างน้อยหนึ่งประเภท",
  zdxzsgjglxtitle: "เลือกสถาบันได้สูงสุด 3 ประเภท",
  cdw11gzftitle: "ความยาว 11 ตัวอักษร",
  zzczmmtitle: "คุณกำลังรีเซ็ตรหัสผ่านของคุณคุณต้องการดำเนินการต่อหรือไม่？",
  sbtz_title: "ประกาศประกาศ",
  wsj_title: "ไม่มีข้อมูล",
  fbbm_title: "แผนกเผยแพร่",
  lx_title: "ประเภท",
  zt_title: "สภาพ",
  kssj_title: "เวลาเริ่มต้น",
  jzsj_title: "กำหนดเวลา",
  more: "มากกว่า",
  fb: "ปล่อย",
  zhi: "至",
  djsb: "คลิ๊กประกาศ",
  ljdh_title: "การนำทางอย่างรวดเร็ว",
  jscgname_table: "ชื่อความสำเร็จทางเทคนิค",
  ssdwname_table: "หน่วยต้นสังกัด",
  szdwname_table: "หน่วย",
  hylbname_table: "ประเภทอุตสาหกรรม",
  cgspname_table: "ระดับผลลัพธ์",
  csdname_table: "วุฒิภาวะ",
  fbsjname_table: "เวลาที่วางจำหน่าย",
  kjxq_title: "ความต้องการเทคโนโลยี",
  xglj_title: "ลิงค์ที่เกี่ยวข้อง",
  xl_title: "วุฒิการศึกษา",
  dw_title: "หน่วย",
  zjgdxx_title: "ข้อมูลเพิ่มเติมจากผู้เชี่ยวชาญ",
  zw_title: "ตำแหน่ง",
  hy_title: "อุตสาหกรรม",
  hylb_title: "ประเภทอุตสาหกรรม",
  szd_title: "ที่ตั้ง",
  dwmc_title: "ชื่อหน่วย",
  xmmc_title: "ชื่อโครงการ",
  fbsj_title: "เวลาที่วางจำหน่าย",
  ly_title: "สนาม",
  lll_title: "ยอดวิว",
  zrfs_title: "วิธีการโอน",
  cgsp_title: "ระดับผลลัพธ์",
  sfcj_title: "ตกลงหรือไม่",
  lxr_title: "ผู้ติดต่อ",
  tyshxydmtitle: "รหัสเครดิตทางสังคมแบบครบวงจร",
  yxqtitle: "วันหมดอายุ",
  ztitle: "至",
  xmlytitle: "ที่มาของโครงการ",
  qxzlytitle: "กรุณาเลือกแหล่งที่มาของโครงการ",
  xmlkzxstitle: "แบบฟอร์มการพัฒนาโครงการ",
  qsrxmmcname_table: "กรุณาใส่ชื่อโครงการ",
  qsryjkfrs_table: "โปรดป้อนจำนวนการวิจัยและพัฒนา",
  qxzjzjd_table: "โปรดเลือกขั้นตอนความคืบหน้า",
  qxzxmlkzxstitle: "โปรดเลือกรูปแบบการพัฒนาโครงการ",
  xmdncgxstitle: "แบบฟอร์มผลลัพธ์ของโครงการในปีปัจจุบัน",
  qxzxmdncgxstitle: "โปรดเลือกรูปแบบผลลัพธ์ของปีปัจจุบันของโครงการ",
  xmjsjjmbtitle: "เป้าหมายทางเทคนิคและเศรษฐกิจของโครงการ",
  qxzxmjsjjmbtitle: "โปรดเลือกโครงการ เป้าหมายทางเทคนิคและเศรษฐกิจ",
  xmqsrqtitle: "วันที่เริ่มต้นโครงการ",
  qxzxmqsrqtitle: "โปรดเลือกวันที่เริ่มต้นโครงการ",
  xmwcrqtitle: "วันที่เสร็จสิ้นโครงการ",
  qxzxmwcrqtitle: "โปรดเลือกวันที่โครงการเสร็จสมบูรณ์",
  knxmdlsczyjzjdtitle: "โครงการข้ามปีเมื่ออยู่ในขั้นตอนหลักของการพัฒนา",
  qxzknxmdlsczyjzjdtitle:
    "กรุณาเลือกโครงการข้ามปีเมื่ออยู่ในขั้นตอนหลักของการพัฒนา",
  xmyjkfrytitle: "นักวิจัยและพัฒนาโครงการ (คน)",
  qsrxmyjkfrytitle: "โปรดป้อนผู้พัฒนาโครงการ (คน)",
  xmrysjgzsjtitle: "ชั่วโมงการทำงานจริงของบุคลากรโครงการ (คนต่อเดือน)",
  qsrxmrysjgzsjtitle: "โปรดป้อนเวลาทำงานของพนักงาน",
  xmjfzctitle: "ค่าใช้จ่ายโครงการ (พันหยวน)",
  qsrxmjfzctitle: "โปรดป้อนค่าใช้จ่ายโครงการ",
  dwfzrtitle: "หัวหน้าหน่วย",
  tbrtitle: "คนกรอกแบบฟอร์ม",
  tbrqtitle: "วันที่กรอกข้อมูล",
  qxztbrqtitle: "โปรดเลือกวันที่กรอกข้อมูล",
  tjbtn: "ส่ง",
  zbmctitle: "ชื่อตัวบ่งชี้",
  jldwtitle: "หน่วยวัด",
  dmtitle: "รหัส",
  sltitle: "จำนวน",
  jiatitle: "甲",
  yititle: "乙",
  bingtitle: "丙",
  yjkfryqk1title: "ประการแรกสถานการณ์ของนักพัฒนางานวิจัย",
  yjkfryhj1title: "นักวิจัยและนักพัฒนาทั้งหมด",
  qzglhfwry1title: "ในหมู่พวกเขา： การจัดการและพนักงานบริการ",
  qzglx1title: "โดย ： หญิง",
  qzqzry1title: "ในหมู่พวกเขา： พนักงานเต็มเวลา",
  qzbkbyjysry1title: "โดย ： ผู้ที่จบการศึกษาระดับปริญญาตรีขึ้นไป",
  qzwpry1title: "ในหมู่พวกเขา： พนักงานภายนอก",
  r1title: "人",
  yjkffyqk2title: "ประการที่สองค่าใช้จ่ายในการวิจัยและพัฒนา",
  yjkffyhj2title: "รวมค่าใช้จ่ายในการวิจัยและพัฒนา",
  ryrgfy2title: "1. ค่าแรงงานสำหรับบุคลากร",
  zjtrfy2title: "2. ค่าใช้จ่ายในการป้อนข้อมูลโดยตรง",
  zjfyycqdtfy2title: "3.ค่าเสื่อมราคาเทียบกับค่าใช้จ่ายรอการตัดบัญชีระยะยาว",
  wxzctxfy2title: "4.ค่าตัดจำหน่ายสินทรัพย์ไม่มีตัวตน",
  sjfy2title: "5. ค่าใช้จ่ายในการออกแบบ",
  zbtsfyysyfy2title: "6. ค่าใช้จ่ายในการว่าจ้างอุปกรณ์และค่าใช้จ่ายในการทดสอบ",
  wttsfyysyfy2title: "7. มอบหมายค่าใช้จ่ายในการวิจัยและพัฒนาจากภายนอก",
  wtjlyjjg2title: "สถาบันวิจัยในประเทศที่ได้รับมอบหมาย",
  wtjlgdxx2title: "มอบหมายให้สถาบันอุดมศึกษาในประเทศ",
  wtjlqy2title: "องค์กรในประเทศที่ได้รับมอบหมาย",
  wtjwjg2title: "สถาบันต่างประเทศที่ได้รับมอบหมาย",
  qtfy2title: "8.ค่าใช้จ่ายอื่นๆ",
  qy2title: "พันดอลลาร์",
  yjkfzcqk3title: "ประการที่สามการวิจัยและพัฒนาสินทรัพย์",
  dnxcyyyjkfdgdzc3title:
    "สินทรัพย์ถาวรที่เกิดขึ้นเพื่อการวิจัยและพัฒนาในปีเดียวกัน",
  qizyqhsb3title: "ในหมู่พวกเขา： เครื่องมือและอุปกรณ์",
  yjkfzczjly4title: "ประการที่สี่แหล่งที่มาของค่าใช้จ่ายในการวิจัยและพัฒนา",
  lzqyzc4title: "1. จากการระดมทุนด้วยตนเองขององค์กร",
  lzzfbm4title: "2.จากส่วนราชการ",
  lzyhdk4title: "3. สินเชื่อจากธนาคาร",
  lzfxtz4title: "4. จาก Venture Capital",
  lzqtqd4title: "5. จากช่องทางอื่น",
  xgzctsqk5title: "ห้าและนโยบายที่เกี่ยวข้องกับการดำเนินงาน",
  sbjjkc5title:
    "ประกาศค่าใช้จ่ายในการวิจัยและพัฒนาสำหรับการหักลดหย่อนภาษีและการยกเว้นภาษี",
  jjkcjmsje5title: "การหักลดหย่อนภาษีเพิ่มเติม",
  gxjsqyjmsje5title: "การลดและยกเว้นภาษีขององค์กรที่มีเทคโนโลยีสูง",
  qybyjkfjg6title:
    "6. สถานการณ์ของสถาบันวิจัยและพัฒนา (ในประเทศ) ที่ดำเนินการโดยองค์กร",
  qmjgsg6title: "จำนวนสถาบันสุดท้าย",
  jgyjkfry6title: "นักวิจัยและนักพัฒนาสถาบัน",
  qzbsby6title: "โดย ： จบปริญญาเอก",
  ssby6title: "สำเร็จการศึกษาระดับปริญญาโท",
  jgyjkffy6title: "ค่าใช้จ่ายในการวิจัยและพัฒนาสถาบัน",
  qmyqhsbyj6title: "ราคาเดิมของเครื่องมือและอุปกรณ์สิ้นสุด",
  yjkfccjxgqk7title: "7. ผลลัพธ์การวิจัยและพัฒนาและสถานการณ์ที่เกี่ยวข้อง",
  zlqk7title: "( 1 ) สถานการณ์สิทธิบัตร",
  dlzlsqs7title: "จำนวนการยื่นขอสิทธิบัตรในปีปัจจุบัน",
  qzfmzl7title: "โดย ： สิทธิบัตรการประดิษฐ์",
  qmyxfmzls7title: "จำนวนสิทธิบัตรการประดิษฐ์ที่ถูกต้อง ณ สิ้นงวด",
  qzybss7title: "โดย ： ได้รับการใช้",
  zlsyqzrjxks7title: "การโอนกรรมสิทธิ์สิทธิบัตรและจำนวนใบอนุญาต",
  zlsyqxksr7title: "การโอนกรรมสิทธิ์สิทธิบัตรและรายได้ใบอนุญาต",
  xcpqk7title: "( สอง ) สถานการณ์ของผลิตภัณฑ์ใหม่",
  xcpxssr7title: "รายได้จากการขายผลิตภัณฑ์ใหม่",
  qzck7title: "โดย ： ส่งออก",
  qtqk7title: "สถานการณ์อื่นๆ ( สาม )",
  qmzcsb7title: "เครื่องหมายการค้าจดทะเบียนเมื่อสิ้นสุดระยะเวลา",
  fbkjlw7title: "เผยแพร่เอกสารทางวิทยาศาสตร์",
  xcgjhhybz7title: "สร้างมาตรฐานระดับชาติหรืออุตสาหกรรม",
  jian7title: "件",
  pian7title: "篇",
  xiang7title: "项",
  qtxgqk8title: "แปดสถานการณ์อื่น ๆ ที่เกี่ยวข้อง",
  jsgchjshqqk8title: "( 1 ) การเปลี่ยนแปลงเทคโนโลยีและการเข้าถึงเทคโนโลยี",
  jsgzjfzc8title: "ค่าใช้จ่ายการเปลี่ยนแปลงทางเทคนิค",
  gmjljsjfzc8title: "การซื้อค่าใช้จ่ายทางเทคนิคในประเทศ",
  yjjwjsjfzc8title: "แนะนำค่าใช้จ่ายด้านเทคนิคในต่างประเทศ",
  yjjwjsdxhxs8title: "แนะนำการย่อยและการดูดซับของเทคโนโลยีต่างประเทศ",
  qybyjkfjg8title:
    "( สอง ) องค์กรสำนักงานวิจัยและพัฒนาสถาบัน ( ต่างประเทศ ) สถานการณ์",
  qmqyzjwsldyjkfjgs8title:
    "จำนวนสถาบันวิจัยและพัฒนาที่จัดตั้งขึ้นโดย บริษัท ต่างประเทศเมื่อสิ้นสุดระยะเวลา",
  ge8title: "个",
  dhtabletitledh:
    "ตารางการรวบรวมความต้องการความสำเร็จทางวิทยาศาสตร์และเทคโนโลยีของแพลตฟอร์มการถ่ายโอนเทคโนโลยี Dehong",
  bhdh: "เลข",
  txsjdh: "เวลากรอกข้อมูล",
  dwxxdh: "ข้อมูลหน่วยหนึ่ง",
  dwmcdh: "ชื่อหน่วย",
  fddbrdh: "ตัวแทนทางกฎหมาย",
  lxyxdh: "กล่องจดหมายติดต่อ",
  lxrdh: "ผู้ติดต่อ",
  lxdhdh: "เบอร์ติดต่อ",
  dwrygkdh: "ข้อมูลบุคลากรของหน่วย",
  zzrydh: "ผู้ครอบครอง",
  dzysjsrcdh: "ความสามารถทางเทคนิคระดับวิทยาลัยหรือสูงกว่า",
  qzbsdh: "หนึ่งในนั้น ดร.",
  zscqyydh: "ความเป็นเจ้าของทรัพย์สินทางปัญญา / แหล่งที่มา",
  wgsjzldh: "สิทธิบัตรการออกแบบ",
  syxzldh: "สิทธิบัตรที่ใช้งานได้จริง",
  fmzldh: "สิทธิบัตรการประดิษฐ์",
  rzqdh: "การให้สิทธิแบบอ่อน",
  gmdh: "ซื้อ",
  zzyfdh: "การวิจัยและพัฒนาอิสระ",
  hzkfdh: "การพัฒนาสหกรณ์",
  qtdh: "อื่นๆ",
  sfwgxjsqydh: "ไม่ว่าจะเป็นองค์กรที่มีเทคโนโลยีสูง",
  shidh: "是",
  szsbh: "ใช่ หมายเลขใบรับรอง",
  foubh: "否",
  sfwkjxzxqybh: "ไม่ว่าจะเป็นวิสาหกิจขนาดกลางและขนาดย่อม",
  qtlxscztbh: "ผู้เล่นในตลาดประเภทอื่น ๆ",
  sfjscxcyptbh: "ว่าจะสร้างแพลตฟอร์มนวัตกรรมและการเป็นผู้ประกอบการ",
  zckjbh: "พื้นที่สร้างสาธารณะ",
  xctdbh: "โลกแห่งการสร้างใหม่",
  qyjszxbh: "ศูนย์เทคโนโลยีองค์กร",
  gcyjzxbh: "ศูนย์วิจัยวิศวกรรม",
  jscxzxbh: "ศูนย์นวัตกรรมเทคโนโลยี",
  kjqyfhqbh: "ศูนย์บ่มเพาะธุรกิจเทคโนโลยี",
  snjjyxxbh: "ประการที่สองข้อมูลธุรกิจของปีที่แล้ว (10,000 หยวน)",
  zzcbh: "สินทรัพย์รวม",
  jzcbh: "สินทรัพย์สุทธิ",
  yysrbh: "营业收入",
  zyftrbh: "การลงทุนด้าน R&D",
  zyysrbh: "รายได้จากธุรกิจหลัก",
  jlrbh: "กำไรสุทธิ",
  yftrbh: "การลงทุนด้าน R&D",
  ydwswybh: "1-50 ล้านบาท",
  wsdwbwybh: "50-500 ล้านบาท",
  wbdwbwybh: "500 - 1,000 ล้านบาท",
  yqwwbwybh: "มากกว่า 10 ล้านหยวน",
  yftrzyysrbblbh: "การลงทุนด้าน R&D คิดเป็นสัดส่วนของรายได้จากการดำเนินงาน",
  bfz1bh: "1%",
  bfz1d3bh: "1% - 3%",
  bfz3d5bh: "3% -5%",
  bfz5d10bh: "5% -10%",
  bfz10bh: "มากกว่า 10%",
  zyywjgxjscpbh: "ธุรกิจหลักและผลิตภัณฑ์ไฮเทค (บริการ)",
  sfhkjzxjjbh: "จะได้รับเงินพิเศษวิทยาศาสตร์และเทคโนโลยี",
  gjsbh: "ประเทศ/จังหวัด",
  sfyhjgtzbh: "คุณได้รับการลงทุนจากสถาบันหรือไม่",
  ljbh: "สะสม",
  jgtzjewybh: "[จำนวนเงินลงทุนของสถาบัน] 10,000 หยวน",
  cpjbqkbh: "ประการที่สามข้อมูลพื้นฐานของผลิตภัณฑ์",
  cpmcbh: "ชื่อผลิตภัณฑ์",
  ssjslybh: "สาขาเทคนิค",
  dzxxbh: "ข้อมูลอิเล็กทรอนิกส์",
  rgzlbh: "ปัญญาประดิษฐ์",
  zinzzgdzbbh: "การผลิตอัจฉริยะของอุปกรณ์ระดับไฮเอนด์",
  qtbh: "อื่นๆ",
  xdlybh: "เกษตรสมัยใหม่",
  gjsfubh: "บริการที่มีเทคโนโลยีสูง",
  xnyjjnjsbh: "พลังงานใหม่และเทคโนโลยีประหยัดพลังงาน",
  hkhtjsbh: "เทคโนโลยีการบินและอวกาศ",
  zyyhjjsbh: "ทรัพยากรและเทคโนโลยีสิ่งแวดล้อม",
  xclbh: "วัสดุใหม่",
  yyhybh: "อุตสาหกรรมแอพพลิเคชั่น",
  xtrhbh: "ข้อมูลการสื่อสารซอฟต์แวร์อินเทอร์เน็ต",
  llmybh: "การเกษตรป่าไม้การเลี้ยงสัตว์และการประมง",
  wshshgzbh: "งานด้านสุขภาพและสังคมสงเคราะห์",
  jtyscchyzbh: "การขนส่งคลังสินค้าและบริการไปรษณีย์",
  ckybh: "อุตสาหกรรมเหมืองแร่",
  szjzbh: "อาคารเทศบาล",
  slhjhgyssglbh: "การจัดการน้ำสิ่งแวดล้อมและสาธารณูปโภค",
  kxyjhjsfwybh: "การวิจัยทางวิทยาศาสตร์และบริการเทคโนโลยี",
  zcybh: "อุตสาหกรรมการผลิต",
  dlrlrqjscshgybh: "การผลิตและจำหน่ายไฟฟ้าความร้อนก๊าซและน้ำ",
  gjzzbh: "องค์การระหว่างประเทศ",
  hgbh: "เคมีภัณฑ์",
  ggglshbzhshzzbh: "การจัดการสาธารณะประกันสังคมและองค์กรทางสังคม",
  zphswfwbh: "บริการให้เช่าและธุรกิจ",
  jybh: "การศึกษา",
  yysxbh: "คุณสมบัติของแอปพลิเคชัน",
  yysxcxbh: "นวัตกรรมดั้งเดิม",
  jseckfbh: "การพัฒนาเทคโนโลยีรอง",
  jsyjzcxbh: "การแนะนำเทคโนโลยีและนวัตกรรม",
  zhcxbh: "นวัตกรรมผสมผสาน",
  yfjdbh: "ขั้นตอนการวิจัยและพัฒนา",
  yfzbh: "ในการวิจัยและพัฒนา",
  xsjdbh: "ขั้นตอนการทดสอบขนาดเล็ก",
  zsbh: "การทดสอบนำร่อง",
  ypbh: "ตัวอย่าง",
  xplbh: "ชุดเล็ก",
  kcyhbh: "สามารถทำให้เป็นอุตสาหกรรมได้",
  kjcgxmxqbbh:
    "ประการที่สี่สถานการณ์ความต้องการของโครงการความสำเร็จทางวิทยาศาสตร์และเทคโนโลยี",
  fwxqbh: "ความต้องการบริการ",
  jsrcyjbh: "การแนะนำความสามารถทางเทคนิค",
  cgzhyxmhzbh: "การเปลี่ยนแปลงผลลัพธ์และความร่วมมือโครงการ",
  zljskfgmbh: "การพัฒนา / ซื้อเทคโนโลยีที่จดสิทธิบัตร",
  xcpyfbh: "การพัฒนาผลิตภัณฑ์ใหม่",
  qypxbh: "การฝึกอบรมขององค์กร",
  scglbh: "การจัดการการผลิต",
  yxchbh: "การวางแผนการตลาด",
  zyjnbh: "ทักษะวิชาชีพ",
  xljybh: "การศึกษาเชิงวิชาการ",
  qyzxbh: "การเปลี่ยนแปลงองค์กร",
  xyjsjcqkbh: "พื้นฐานเทคโนโลยีที่มีอยู่",
  xyjjdxqwtbh: "ปัญหาความต้องการ / ข้อกำหนดทางเทคนิคที่ต้องแก้ไข",
  zljsbh: "เทคโนโลยีสิทธิบัตร",
  xmhzykfbh: "ความร่วมมือและการพัฒนาโครงการ",
  xmsbbh: "ประกาศโครงการ",
  rcyjbh: "การแนะนำคนเก่ง",
  xqjsbh: "แนะนำสั้น ๆ เกี่ยวกับความต้องการ",
  qwhzfsbh: "เวลาความร่วมมือที่คาดหวัง",
  gtkfbh: "ร่วมพัฒนา",
  gmfwbh: "ซื้อบริการ",
  slzdbh: "ตั้งค่าเว็บไซต์",
  qwhzsjbh: "เวลาความร่วมมือที่คาดหวัง",
  dq1dsbh: "ระยะสั้น (1-3 ปี)",
  cq5nysbh: "ระยะยาว (มากกว่า 5 ปี)",
  jjxqwzbh: "แก้ปัญหาความต้องการ",
  yqtrbh: "การลงทุนที่คาดหวัง",
  swyyxbh: "น้อยกว่า 100,000 หยวน",
  sdsswybh: "10-30 ล้านบาท",
  ssdwsbh: "30-50 ล้านบาท",
  wsdybwbh: "50-100 ล้านบาท",
  ybdybwbh: "100-150 ล้านบาท",
  ybwdebbh: "1.5 - 200 ล้านบาท",
  ebdsbbh: "200-300 ล้านบาท",
  wbysbh: "มากกว่า 5 ล้านหยวน",
  qtysfwbh: "อื่น ๆ (ช่วงงบประมาณ)",
  glxxbh: "ห้า,การจัดการข้อมูล",
  tygkxqxxbh: "ตกลงที่จะเปิดเผยข้อมูลความต้องการ",
  bfgkbh: "เปิดเผยบางส่วน",
  tyxyzjfwbh: "เห็นด้วยกับความต้องการบริการผู้เชี่ยวชาญ",
  tycydjjfadsxpjbh: "ตกลงที่จะมีส่วนร่วมในการประเมินผลการคัดกรองของการแก้ปัญหา",
  dcxyjhzjlhddjyhxqbh:
    "ข้อเสนอแนะและข้อกำหนดสำหรับกิจกรรมการแลกเปลี่ยนความร่วมมือระหว่างอุตสาหกรรมมหาวิทยาลัยและสถาบันการวิจัย",
  tjbh: "ส่ง",
  dybh: "พิมพ์",
  qtxbhbh: "กรุณากรอกหมายเลข",
  qtxdwmcbh: "กรุณากรอกชื่อหน่วย",
  qtxlxrbh: "กรุณากรอกข้อมูลติดต่อ",
  qtxlxdhbh: "กรุณากรอกหมายเลขติดต่อ",
  zztjztsjbh: "กำลังส่งข้อมูลนี้คุณต้องการดำเนินการต่อหรือไม่",
  tjcgbh: "ส่งสำเร็จ",
  nccenter: "ชื่อเล่น",
  bindcenter: "ผูก",
  sqsmrzcenter: "ใช้สำหรับการตรวจสอบชื่อจริง",
  smrzcenter: "การตรวจสอบชื่อจริง",
  sqzjrzcenter: "การรับรองโดยผู้เชี่ยวชาญ",
  sqjgrzcenter: "การรับรองผู้สมัคร",
  jgrzcenter: "การรับรองสถาบัน",
  scdlcenter: "เข้าสู่ระบบล่าสุด",
  xqlcenter: "ความต้องการ",
  cgzscenter: "การแสดงผลงาน",
  zhaqcenter: "ความปลอดภัยของบัญชี",
  dlmmcenter: "รหัสผ่านเข้าสู่ระบบ",
  yybhzhxxcenter: "ใช้เพื่อปกป้องข้อมูลบัญชีและความปลอดภัยในการเข้าสู่ระบบ",
  ggcenter: "เปลี่ยนแปลง",
  aqsjcenter: "โทรศัพท์มือถือที่ปลอดภัย",
  yyzktfxcenter:
    "ยืนยันแล้วสามารถเข้าสู่ระบบหรือดึงรหัสผ่านผ่านโทรศัพท์มือถือของคุณ",
  xgdlmmcenter: "แก้ไขรหัสผ่านเข้าสู่ระบบ",
  dqmmcenter: "รหัสผ่านปัจจุบัน",
  xmmcenter: "รหัสผ่านใหม่",
  qrmmcenter: "ยืนยันรหัสผ่าน",
  mmxgcgcenter: "การแก้ไขรหัสผ่านสำเร็จแล้ว！",
  tjcenter: "ส่ง",
  qxcenter: "ยกเลิก",
  qdcenter: "แน่นอน",
  bdsjhcenter: "ผูกโทรศัพท์ใหม่",
  xsjhmcenter: "เบอร์มือถือใหม่",
  yzmcenter: "รหัสยืนยัน",
  hqcenter: "รับรหัสยืนยัน",
  qsrsjhmvocenter: "กรุณาใส่หมายเลขโทรศัพท์มือถือของคุณ",
  cdwsygzfvocenter: "ความยาว 11 ตัวอักษร",
  qsryzmvocenter: "กรุณากรอกรหัสยืนยัน",
  qsrmmvocenter: "กรุณาใส่รหัสผ่าน",
  qzcsrmmmvocenter: "โปรดป้อนรหัสผ่านของคุณอีกครั้ง",
  lcsrmmbyzvocenter: "รหัสผ่านที่ป้อนสองครั้งไม่สอดคล้องกัน！",
  zzxgmmvocenter: "กำลังเปลี่ยนรหัสผ่านคุณต้องการดำเนินการต่อหรือไม่？",
  cgvocenter: "สำเร็จ",
  zzxgsjhmvocenter:
    "คุณกำลังเปลี่ยนหมายเลขโทรศัพท์ของคุณคุณต้องการดำเนินการต่อหรือไม่？",
  tsvocenter: "คำใบ้",
  dlsjhcenter: "เข้าสู่ระบบหมายเลขโทรศัพท์มือถือ",
  ybdcenter: "ถูกผูกไว้แล้ว",
  wbdcenter: "ถูกผูกไว้แล้ว",
  ghbdcenter: "เปลี่ยนการผูก",
  qbdcenter: "ไปผูก",
  xgmmcenter: "เปลี่ยนรหัสผ่าน",
  jbxxcenter: "ข้อมูลพื้นฐาน",
  jbxxdesccenter:
    "ให้ข้อมูลประจำตัวที่ถูกต้องช่วยในการรับรองความถูกต้องและปรับปรุงภาพลักษณ์เครดิต",
  zsxmccenter: "ชื่อจริง",
  zsxmcvomcenter: "กรุณาใส่ชื่อจริงของคุณ",
  xbvomcenter: "กรุณาเลือกเพศ",
  cslyvomcenter: "กรุณาเลือกวันเกิด",
  qsrzssfzhvomcenter: "โปรดป้อนหมายเลข ID จริงของคุณ",
  qscsfzzmvomcenter: "กรุณาอัพโหลดหน้าบัตรประชาชน",
  qscsfzbmvomcenter: "กรุณาอัปโหลดด้านหลังของบัตรประจำตัวประชาชนของคุณ",
  zzthsmxxcenter: "กำลังส่งข้อมูลชื่อจริงคุณต้องการดำเนินการต่อหรือไม่？",
  zzbcgrxxcenter: "กำลังบันทึกข้อมูลส่วนบุคคลคุณต้องการดำเนินการต่อหรือไม่？",
  xbcenter: "เพศ",
  nancenter: "男",
  nvcenter: "女",
  wzcenter: "ไม่ทราบ",
  cslycenter: "วันเดือนปีเกิด",
  sfzhcenter: "เลขบัตรประชาชน",
  sfzzmcenter: "ด้านหน้าของเอกสารประจำตัว",
  sfzzmdesccenter:
    "บัตรประจำตัวส่วนบุคคลที่ชัดเจนด้านหน้าและด้านหลังของภาพถ่ายต้นฉบับต้องใช้ภาพในรูปแบบ JPG, GIF, PNG ต่ำกว่า 200KB",
  sfzfmcenter: "เอกสารแสดงตน ฝั่งตรงข้าม",
  tjsmxxcenter: "ส่งข้อมูลชื่อจริง",
  grzlcenter: "ข้อมูลส่วนบุคคล",
  sctxcenter: "อัพโหลดรูปประจำตัว",
  sctxdesccenter: "ขอแนะนำให้อัปโหลดภาพถ่าย ID หนึ่งนิ้ว",
  yhlccenter: "ชื่อเล่นของผู้ใช้",
  szdqcenter: "พื้นที่ที่ตั้งอยู่",
  qxzcenter: "กรุณาเลือก",
  yxcenter: "กล่องจดหมาย",
  yxdesccenter: "ป้อนกล่องจดหมายปกติของคุณ",
  jzdzcenter: "ถิ่นที่อยู่",
  zycenter: "อาชีพ",
  zydesccenter: "อุตสาหกรรมหรืออาชีพ",
  qqcenter: "คิวคิว",
  qqdesccenter:
    "โปรดป้อน QQ จริงเพื่ออำนวยความสะดวกในการบริการลูกค้าเพื่อสื่อสารกับคุณ",
  bccenter: "บันทึก",
  qsrnccenter: "กรุณาใส่ชื่อเล่น",
  qsryxcenter: "กรุณาใส่กล่องจดหมาย",
  qsrxjzdcenter: "โปรดป้อนที่อยู่ของคุณ",
  qsrzycenter: "โปรดป้อนอาชีพของคุณ",
  qsrqqcenter: "โปรดป้อน QQ",
  qsctxcenter: "กรุณาอัปโหลดภาพ",
  qxzsfcenter: "กรุณาเลือกจังหวัด",
  qxzcscenter: "กรุณาเลือกเมือง",
  qxzqycenter: "กรุณาเลือกพื้นที่",
  jgrzdesccenter: "การให้ข้อมูลที่ถูกต้องจะช่วยในการตรวจสอบเพิ่มเติม",
  qymcccenter: "ชื่อธุรกิจ",
  qydzcenter: "ที่อยู่ธุรกิจ",
  fddbrcenter: "ตัวแทนทางกฎหมาย",
  tyshxydmcenter: "รหัสเครดิตทางสังคมแบบครบวงจร",
  yyzzcenter: "ใบอนุญาตประกอบธุรกิจ",
  yyzzdesccenter:
    "ภาพถ่ายใบอนุญาตประกอบธุรกิจที่ชัดเจนต้องใช้ภาพในรูปแบบ JPG, GIF, PNG ต่ำกว่า 200KB",
  tgrzxxcenter: "ให้ข้อมูลการรับรอง",
  qyyjkfxmqkcenter: "โครงการวิจัยและพัฒนาองค์กร",
  qbcenter: "ทั้งหมด",
  wshtgcenter: "ยังไม่ได้ตรวจสอบ ผ่าน",
  yshcenter: "ตรวจสอบแล้ว",
  dshcenter: "รอการตรวจสอบ",
  sscenter: "ค้นหา",
  qsrgjzcenter: "กรุณาใส่คีย์เวิร์ด",
  xmmccenter: "ชื่อโครงการ",
  xmlycenter: "ที่มาของโครงการ",
  kzxscenter: "รูปแบบการพัฒนา",
  kssjcenter: "เวลาเริ่มต้น",
  jssjcenter: "เวลาสิ้นสุด",
  fzrcenter: "ผู้รับผิดชอบ",
  czcenter: "ปฏิบัติการ",
  qyyjkfhdcenter: "กิจกรรมการวิจัยและพัฒนาองค์กร",
  dwmccenter: "ชื่อหน่วย",
  dwfzrcenter: "หัวหน้าหน่วย",
  tbrcenter: "คนกรอกแบบฟอร์ม",
  yxqkscenter: "เวลาเริ่มต้นวันหมดอายุ",
  yxqjscenter: "เวลาสิ้นสุดวันหมดอายุ",
  kjcgxqzjcenter: "ความต้องการความสำเร็จทางวิทยาศาสตร์และเทคโนโลยี",
  cpmccenter: "ชื่อผลิตภัณฑ์",
  txsjcenter: "เวลากรอกข้อมูล",
  fbkjcgcgxxcenter:
    "เผยแพร่ความสำเร็จทางวิทยาศาสตร์และเทคโนโลยี - ข้อมูลผลลัพธ์",
  fbkjcgcgdesccenter:
    "ยิ่งมีความสมบูรณ์ของข้อมูลมากเท่าไหร่ก็จะยิ่งได้รับความสนใจจากลูกค้าเป้าหมายได้ง่ายขึ้นเท่านั้น",
  fbkjcgjsmccenter: "ชื่อทางเทคนิค",
  hylbcenter: "ประเภทอุตสาหกรรม",
  zscqztcenter: "知识产权状态",
  zlmccenter: "ชื่อสิทธิบัตร",
  zlhcenter: "เลขที่สิทธิบัตร",
  jjcenter: "เกริ่นนำ",
  sfcjcenter: "ตกลงหรือไม่",
  zrfscenter: "วิธีการโอน",
  cgszdwcenter: "หน่วยผลลัพธ์",
  dwlxcenter: "ประเภทหน่วย",
  szdwmccenter: "ชื่อหน่วย",
  cgszdcenter: "ที่ตั้งของผลลัพธ์",
  fbcgzszmclcenter: "การนำเสนอผลการเผยแพร่ - เอกสารรับรอง",
  fbcgdesczmcenter:
    "อัปโหลดเอกสารรับรองเพื่ออำนวยความสะดวกให้ลูกค้าเข้าใจการแสดงผลลัพธ์เพิ่มเติม",
  xxcycenter: "อุตสาหกรรมเกิดใหม่",
  kjcgpjspcenter: "ระดับการประเมินความสำเร็จทางวิทยาศาสตร์และเทคโนโลยี",
  csdcenter: "วุฒิภาวะ",
  csdzmcenter: "คำรับรองวุฒิภาวะ",
  csdzmdesccenter:
    "รูปภาพที่ชัดเจนต้องใช้รูปภาพในรูปแบบ jpg, gif, png ต่ำกว่า 100KB",
  jstpcenter: "ภาพทางเทคนิค",
  jspgjzcenter: "มูลค่าการประเมินทางเทคนิค",
  lxrcenter: "ผู้ติดต่อ",
  sjhmcenter: "เบอร์มือถือ",
  zqsrsjhcenter: "ป้อนหมายเลขโทรศัพท์มือถือของคุณอย่างถูกต้อง",
  zqsrlxrcenter: "กรอกรายชื่อติดต่อให้ถูกต้อง",
  tjkjcgshcenter: "ส่งการตรวจสอบความสำเร็จทางวิทยาศาสตร์และเทคโนโลยี",
  glcgcenter: "ผลการจัดการ",
  kjcgmccenter: "ชื่อความสำเร็จทางวิทยาศาสตร์และเทคโนโลยี",
  zjzkzjxxcenter: "ผู้เชี่ยวชาญ Think Tank - ข้อมูลผู้เชี่ยวชาญ",
  zjzkzjxxdesccenter:
    "ให้ข้อมูลประจำตัวที่ถูกต้องช่วยในการรับรองความถูกต้องและปรับปรุงภาพลักษณ์เครดิต",
  zgxlcenter: "วุฒิการศึกษาสูงสุด",
  gzdwcenter: "หน่วยงาน",
  zwcenter: "ตำแหน่ง",
  lxdhcenter: "เบอร์ติดต่อ",
  fwalcenter: "กรณีการให้บริการ",
  tjshcenter: "ส่งการตรวจสอบ",
  glzjzkxicenter: "ผู้เชี่ยวชาญด้านการจัดการคิดว่าข้อมูลรถถัง",
  zjmzcenter: "ชื่อผู้เชี่ยวชาญ",
  zjhycenter: "อุตสาหกรรมผู้เชี่ยวชาญ",
  tjrqcenter: "เพิ่มวันที่",
  zcxlcenter: "ชื่อตำแหน่ง/วุฒิการศึกษา",
  fbjsxqxqxxcenter: "เผยแพร่ข้อกำหนดทางเทคนิค - ข้อมูลข้อกำหนด",
  fbjsxqdesccenter: "กรอกข้อมูลให้ถูกต้องและผ่านการตรวจสอบเพิ่มเติม",
  xqfmccenter: "ครอบคลุมความต้องการ",
  xqfmdesccenter: "ครอบคลุมข้อกำหนดทางเทคนิคที่แสดงในรายการ",
  xqmccenter: "ชื่อความต้องการ",
  fbxqlxxxcenter: "เผยแพร่ข้อกำหนดทางเทคนิค - ข้อมูลการติดต่อ",
  fbxqlxxxdesccenter:
    "กรอกข้อมูลการติดต่อให้ถูกต้องเพื่อความสะดวกของลูกค้าในการติดต่อกับคุณ",
  tjjsxqshcenter: "ส่งการตรวจสอบข้อกำหนดทางเทคนิค",
  glxqcenter: "ความต้องการในการจัดการ",
  xiugaicenter: "แก้ไข",
  sanchucenter: "ลบ",
  kjcglycenter: "ข้อความความสำเร็จทางวิทยาศาสตร์และเทคโนโลยี",
  lybkcenter: "บล็อกกระดานข้อความ",
  lydxcenter: "วัตถุข้อความ",
  dhcscenter: "จำนวนการสนทนา",
  jsxqlycenter: "ข้อความความต้องการทางเทคนิค",
  kjcgcenter: "ข้อความความสำเร็จทางวิทยาศาสตร์และเทคโนโลยี",
  zjzklycenter: "ผู้เชี่ยวชาญคิดว่าข้อความรถถัง",
  jzmmL: "จำรหัสผ่าน",
  "welogin": "ยินดีต้อนรับเข้าสู่ระบบ",
  "weuser": "ชื่อผู้ใช้",
  "wepass": "รหัสผ่าน",
  "weother": "เข้าสู่ระบบอื่น ๆ"
};
