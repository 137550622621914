export default {
  wecome:
    "ສະບາຍດີ ແລະ ຍິນດີ ຕ້ອນຮັບ ສູ່ ເວທີ ການ ບໍລິການ ໂ ອ ນ ເ ຕັກ ໂນ ໂລ ຢ ີ Dehongzhou!",
  login: "ເຂົ້າ ສູ່ ລະບົບ",
  nick: "ຊື່ ຫ ຼ ິ ້ ນ",
  bind: "ຜູກມັດ",
  search: "ຄົ້ນຫາ",
  qxzxyssdflsearch: "ກະ ລຸ ນາ ເລືອກ ໝ ວ ດ ໝ ູ ່ ເພື່ອ ຄົ້ນຫາ",
  searchP: "ກະ ລຸ ນາ ເລືອກ",
  searchI: "ກະ ລຸ ນາ ໃສ່",
  register: "ລົງທະບຽນ",
  center: "ສູນ ສ່ວນຕົວ",
  zcxwtitle: "ຂ່າວ ນະໂຍບາຍ",
  tzgotitle: "ແຈ້ງ ການ ແຈ້ງ ການ",
  kjhztitle: "ການ ຮ່ວມມື ດ້ານ ວິທະຍາສາດ ແລະ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  kjcgtitle: "ຜົນ ສຳ ເ ລັດ ດ້ານ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  kjjrtitle: "ການ ເງິນ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  zjzktitle: "ຜູ້ຊ່ຽວຊານ ດ້ານ ແນວ ຄິດ",
  zjzkftitle: "ການ ຄັດເລືອກ ຜູ້ຊ່ຽວຊານ ດ້ານ ແນວ ຄິດ",
  kjcgftitle: "ການ ຄັດເລືອກ ຜົນ ສຳ ເ ລັດ ດ້ານ ວິທະຍາສາດ ແລະ ເ ຕັກ ໂນ",
  jsxqtitle: "ຄວາມຕ້ອງການ ດ້ານ ເ ຕັກ ນ ິ ກ",
  jsxqftitle: "ການ ຄັດເລືອກ ຄວາມຕ້ອງການ ດ້ານ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  jslinytitle: "ຂະ ແໜງ ເ ຕັກ ນ ິ ກ",
  fbxqtitle: "ປ່ອຍ ຂໍ້ ກຳ ນ ົ ດ",
  wxxztitle: "ດາວ ໂ ຫ ລ ດ ວັນນະຄະດີ",
  zbdwname: "ຜູ້ ຈັດງານ",
  dhname: "ໂທລະສັບ",
  jszcname: "ສະ ຫ ນັບ ສະ ຫ ນ ູ ນ ດ້ານ ວິຊາການ",
  dzname: "ທີ່ຢູ່",
  syname: "ໜ້າ ທຳ ອ ິ ດ",
  fbcg: "ປ່ອຍ ຜົນ ໄດ້ຮັບ",
  xmsb: "ການຄຸ້ມຄອງໂຄງການ.\n",
  sbtz: "ແຈ້ງ ການ ແຈ້ງ ການ",
  all: "ທັງ ໝ ົ ດ",
  in_progress: "ກຳ ລ ັ ງ ດຳ ເນ ີ ນ ຢູ່",
  stop: "ປິດ ແລ້ວ",
  notopen: "ບໍ່ໄດ້ ເ ລີ່ ມ ຕົ້ນ",
  expired: "ໝ ົ ດ ອາຍຸ ແລ້ວ",
  kjtj_title: "ສະຖິຕິ ວິທະຍາສາດ ແລະ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  lilantitle: "ຊອກຫາ",
  layuantitle: "ແ ຫ ຼ ່ ງ ຂໍ້ ມູນ",
  lilanltitle: "ເບິ່ງ ໜ້າ ເວ ັ ບ",
  timetitle: "ເວລາ",
  zwtitle: "ຂໍ້ຄວາມ",
  fbsjtitle: "ເວລາ ປ່ອຍ",
  hyfltitle: "ການ ຈັດ ປະເພດ ອ ຸ ດ ສາ ຫ ະ ກຳ",
  zjjjtitle: "ຂໍ້ ມູນ ຜູ້ຊ່ຽວຊານ",
  lxzjtitle: "ຕິດຕໍ່ ຜູ້ຊ່ຽວຊານ",
  xlxwtitle: "ການສຶກສາ/ປະ ລິນ ຍາ",
  zczwtitle: "ຫ ົ ວ ຂໍ້",
  xmtitle: "ຊື່",
  fjtitle: "ເ ອ ກະ ສານ ຄັດ ຕິດ",
  xqtitle: "ລາຍ ລະອຽດ",
  hylbtitle: "ປະເພດ ອ ຸ ດ ສາ ຫ ະ ກຳ",
  jssptitle: "ລະດັບ ເ ຕັກ ນ ິ ກ",
  lxdhtitle: "ເບີ ໂທລະສັບ ຕິດຕໍ່",
  rcktitle: "ປະຊາຊົນ ເບິ່ງ",
  ljcktitle: "ເບິ່ງ ສະສົມ",
  zrfstitle: "ວິທີການ ໂ ອ ນ ຍ້າຍ",
  zscqzttitle: "ສະຖານະ ພາບ ຊັບສິນ ທາງ ປັນຍາ",
  zlmcttitle: "ຊື່ ສິດທິບັດ",
  cgszdwttitle: "ຫ ົ ວ ໜ່ວຍ ຜົນ ໄດ້ຮັບ",
  cgjjttitle: "ການ ນຳ ສະ ເ ໜີ ຜົນ ໄດ້ຮັບ",
  zlhttitle: "ເລກ ສິດທິບັດ",
  yxtitle: "ກ່ອງ ຈົດ ໝາ ຍ",
  xqjjtitle: "ຂໍ້ ມູນ ຄວາມຕ້ອງການ",
  lxtitle: "ຕິດຕໍ່",
  my: "ຕໍ່ລອງ ໄດ້",
  jg: "ລາຄາ",
  mslxtitle: "ຕິດຕໍ່ ດຽວນີ້",
  swdlsfjx:
    "ຍັງ ບໍ່ໄດ້ ເຂົ້າ ສູ່ ລະບົບ ເທື່ອ, ເຂົ້າ ສູ່ ລະບົບ, ທ່ານ ຕ້ອງການ ດຳ ເນ ີ ນ ການ",
  ts: "ຄຳ ແນະ ນຳ",
  ok: "ຕົກລົງ",
  successs: "ຄວາມ ສຳ ເ ລັດ",
  cancel: "ຍົກເລີກ",
  qsrnxsdh: "ກະ ລຸ ນາ ໃສ່ ສິ່ງ ທີ່ ທ່ານ ຕ້ອງການ ເວົ້າ",
  lxcg: "ການ ຕິດຕໍ່ ປະສົບ ຜົນ ສຳ ເ ລັດ!",
  lycghxgryhqdlx:
    "ຫ ຼ ັ ງ ຈາກ ຂໍ້ຄວາມ ປະສົບ ຜົນ ສຳ ເ ລັດ, ບຸກ ຄະ ລາ ກອນ ທີ່ ກ່ຽວຂ້ອງ ຈະ ຕິດຕໍ່ ຫ າ ທ່ານ, ກະ ລຸ ນາ ຮັກສາ ໂທລະສັບ ເປີດ, ທ່ານ ຕ້ອງການ ດຳ ເນ ີ ນ ການ ຕໍ່ ບໍ?",
  qyyjkfxmqk_title: "ສະຖານະ ພາບ ຂອງ ໂຄງ ການຄົ້ນຄວ້າ ແລະ ພັດທະນາ ວິ ສາ ຫ ະ ກິດ",
  qyyjkfhdjxgqktitle:
    "ກິດ ຈະ ກຳ ຄົ້ນຄວ້າ ແລະ ພັດທະນາ ວິ ສາ ຫ ະ ກິດ ແລະ ສະພາບ ການທີ່ ກ່ຽວຂ້ອງ",
  qsrsjhmtitle: "ກະ ລຸ ນາ ໃສ່ ເບີ ໂທລະສັບ ມືຖື ຂອງທ່ານ",
  qsrdlzhtitle: "ກະ ລຸ ນາ ໃສ່ ບັນຊີ ເຂົ້າ ສູ່ ລະບົບ",
  qsrdlmmtitle: "ກະ ລຸ ນາ ໃສ່ ລະຫັດ ຜ່ານ ເຂົ້າ ສູ່ ລະບົບ",
  wecomestitle: "ເຈົ້າ ຍິນດີ ຕ້ອນຮັບ!",
  cd3d5title: "3 ເ ຖ ິ ງ 15 ຕົວ ອັກສອນ ໃນ ຄວາມ ຍາວ",
  qsrmmtitle: "ກະ ລຸ ນາ ໃສ່ ລະຫັດ ຜ່ານ",
  wjmmtitle: "ລືມ ລະຫັດ ຜ່ານ",
  fhsytitle: "ກັບໄປ ທີ່ ໜ້າ ທຳ ອ ິ ດ",
  grzctitle: "ການ ລົງທະບຽນ ສ່ວນ ບຸກຄົນ",
  jgzctitle: "ການ ລົງທະບຽນ ສະຖາບັນ",
  jjxzgzytitle: "ຊັບພະຍາກອນ ກຳ ລ ັ ງ ຈະ ຖືກ ດາວ ໂ ຫ ລ ດ, ທ່ານ ຕ້ອງການ ດຳ ເນ",
  sjhmtitle: "ເບີ ໂທລະສັບ ມືຖື",
  szmmtitle: "ຕັ້ງ ລະຫັດ ຜ່ານ",
  qszmmtitle: "ກະ ລຸ ນາ ຕັ້ງ ລະຫັດ ຜ່ານ",
  qrmmtitle: "ຢືນຢັນ ລະຫັດ ຜ່ານ",
  xmmtitle: "ຢືນຢັນ ລະຫັດ ຜ່ານ",
  szdqtitle: "ພື້ນທີ່",
  qtxyzmtitle: "ກະ ລຸ ນາ ຕື່ມ ໃສ່ ລະຫັດ ຢືນຢັນ",
  qxzzztitle: "ກະ ລຸ ນາ ເລືອກ",
  yzmtitle: "ລະຫັດ ຢືນຢັນ",
  ljzctitle: "ລົງທະບຽນ ດຽວນີ້",
  qxzsftitle: "ກະ ລຸ ນາ ເລືອກ ແຂວງ",
  qxzcstitle: "ກະ ລຸ ນາ ເລືອກ ເມືອງ",
  qxzqytitle: "ກະ ລຸ ນາ ເລືອກ ພື້ນທີ່",
  lcsrmmbyytitle: "ລະຫັດ ຜ່ານ ແມ່ນ ແຕກ ຕ່າງກັນ ສອງຄັ້ງ",
  hqyzmtitle: "ເ ອ ົ າ ລະຫັດ ຢືນຢັນ",
  zccgtitle: "ການ ລົງທະບຽນ ປະສົບ ຜົນ ສຳ ເ ລັດ",
  jglxtitle: "ປະເພດ ສະຖາບັນ (ເ ຖ ິ ງ 3)",
  qyttitle: "ທຸລະກິດ",
  kyttitle: "ການຄົ້ນຄວ້າ ວິທະຍາສາດ",
  dxttitle: "ມະຫາວິທະຍາໄລ",
  fwttitle: "ການ ບໍລິການ",
  tzttitle: "ການ ລົງ ທ ື ນ",
  tjttitle: "ການຍື່ນສະເຫນີ.\n",
  jgmctitle: "ຊື່ ສະຖາບັນ",
  qsrjgmctitle: "ກະ ລຸ ນາ ໃສ່ຊື່ ຂອງ ອ ົ ງ ກອນ",
  qzsxzyzjglxtitle: "ກະ ລຸ ນາ ເລືອກ ຢ່າງ ໜ ້ ອ ຍ ໜຶ່ງ ປະເພດ ສະຖາບັນ",
  zdxzsgjglxtitle: "ເລືອກ ເ ຖ ິ ງ ສາມ ປະເພດ ສະຖາບັນ",
  cdw11gzftitle: "11 ຕົວ ອັກສອນ ຍາວ",
  zzczmmtitle: "ລະຫັດ ຜ່ານ ກຳ ລ ັ ງ ຖືກ ປັບ ໃ ໝ ່, ທ່ານ ຕ້ອງການ ດຳ ເນ ີ ນ",
  sbtz_title: "ແຈ້ງ ການ ແຈ້ງ ການ",
  wsj_title: "ບໍ່ມີ ຂໍ້ ມູນ",
  fbbm_title: "ພະແນກ ເຜີຍແຜ່",
  lx_title: "ປະເພດ",
  zt_title: "ສະຖານະ ພາບ",
  kssj_title: "ເວລາ ເ ລີ່ ມ ຕົ້ນ",
  jzsj_title: "ເສັ້ນຕາຍ ຄັ້ງ",
  more: "ຫ ຼ າ ຍ",
  fb: "ເຜີຍແຜ່",
  zhi: "至",
  djsb: "ກົດ ປະກາດ",
  ljdh_title: "ການ ນຳ ທາງ ດ່ວນ",
  jscgname_table: "ຊື່ ຜົນ ສຳ ເ ລັດ ດ້ານ ວິຊາການ",
  ssdwname_table: "ຫ ົ ວ ໜ່ວຍ",
  szdwname_table: "ໜ່ວຍ ງານ",
  hylbname_table: "ປະເພດ ອ ຸ ດ ສາ ຫ ະ ກຳ",
  cgspname_table: "ລະດັບ ຜົນ ໄດ້ຮັບ",
  csdname_table: "ການ ໃ ຫ ຍ ່ ເຕັມຕົວ",
  fbsjname_table: "ເວລາ ປ່ອຍ",
  kjxq_title: "ຄວາມຕ້ອງການ ດ້ານ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  xglj_title: "ການ ເຊື່ອມ ຕໍ່ ທີ່ ກ່ຽວຂ້ອງ",
  xl_title: "ການສຶກສາ",
  dw_title: "ໜ່ວຍ ງານ",
  zjgdxx_title: "ຂໍ້ ມູນ ເພີ່ມ ເຕີມ ຂອງ ຜູ້ຊ່ຽວຊານ",
  zw_title: "ຕຳ ແ ໜ ່ ງ",
  hy_title: "ອ ຸ ດ ສາ ຫ ະ ກຳ",
  hylb_title: "ປະເພດ ອ ຸ ດ ສາ ຫ ະ ກຳ",
  szd_title: "所在地",
  dwmc_title: "ຊື່ ໜ່ວຍ ງານ",
  xmmc_title: "ຊື່ ໂຄງການ",
  fbsj_title: "ເວລາ ປ່ອຍ",
  ly_title: "ໂດ ເມ ນ",
  lll_title: "ເບິ່ງ ໜ້າ ເວ ັ ບ",
  zrfs_title: "ວິທີການ ໂ ອ ນ ຍ້າຍ",
  cgsp_title: "ລະດັບ ຜົນ ໄດ້ຮັບ",
  sfcj_title: "ບໍ່ວ່າ ຈະ ເປັນ ການຕົກລົງ",
  lxr_title: "ຕິດຕໍ່",
  tyshxydmtitle: "ລະຫັດ ສິນ ເຊື່ອ ສັງຄົມ ທີ່ ເປັນ ເ ອ ກະ ພາບ",
  yxqtitle: "ໄລຍະ ເວລາ ທີ່ ຖືກຕ້ອງ",
  ztitle: "至",
  xmlytitle: "ແ ຫ ຼ ່ ງ ຂໍ້ ມູນ ຂອງ ໂຄງການ",
  qxzlytitle: "ກະ ລຸ ນາ ເລືອກ ແ ຫ ຼ ່ ງ ຂໍ້ ມູນ ຂອງ ໂຄງການ",
  xmlkzxstitle: "ແບບ ຟ ອ ມ ການ ພັດທະນາ ໂຄງການ",
  qsrxmmcname_table: "ກະ ລຸ ນາ ໃສ່ຊື່ ໂຄງການ",
  qsryjkfrs_table: "ກະ ລຸ ນາ ໃສ່ ຈຳ ນວນ ການຄົ້ນຄວ້າ ແລະ ການ ພັດທະນາ",
  qxzjzjd_table: "ກະ ລຸ ນາ ເລືອກ ຂັ້ນ ຕອນ ຂອງ ຄວາມ ຄືບ ໜ້າ",
  qxzxmlkzxstitle: "ກະ ລຸ ນາ ເລືອກ ແບບ ຟ ອ ມ ການ ພັດທະນາ ໂຄງການ",
  xmdncgxstitle: "ແບບ ຟ ອ ມ ຜົນ ໄດ້ຮັບ ຂອງ ໂຄງການ",
  qxzxmdncgxstitle: "ກະ ລຸ ນາ ເລືອກ ແບບ ຟ ອ ມ ຜົນ ຂອງ ໂຄງການ",
  xmjsjjmbtitle: "ເປົ້າ ໝາ ຍ ດ້ານ ເ ຕັກ ນ ິ ກ ແລະ ເສດຖະກິດ ຂອງ ໂຄງການ",
  qxzxmjsjjmbtitle:
    "ກະ ລຸ ນາ ເລືອກ ເປົ້າ ໝາ ຍ ດ້ານ ວິຊາການ ແລະ ເສດຖະກິດ ຂອງ ໂຄງການ",
  xmqsrqtitle: "ວັນທີ ເ ລີ່ ມ ຕົ້ນ ຂອງ ໂຄງການ",
  qxzxmqsrqtitle: "ກະ ລຸ ນາ ເລືອກ ວັນທີ ເ ລີ່ ມ ຕົ້ນ ຂອງ ໂຄງການ",
  xmwcrqtitle: "ວັນທີ ສຳ ເ ລັດ ໂຄງການ",
  qxzxmwcrqtitle: "ກະ ລຸ ນາ ເລືອກ ວັນທີ ສຳ ເ ລັດ ໂຄງການ",
  knxmdlsczyjzjdtitle:
    "ໂຄງການ ປີ ໃ ໝ ່ ແມ່ນ ຢູ່ ໃນໄລຍະ ຕົ້ນຕໍ ຂອງ ຄວາມ ຄືບ ໜ້າ",
  qxzknxmdlsczyjzjdtitle:
    "ກະ ລຸ ນາ ເລືອກ ຂັ້ນ ຕອນ ຕົ້ນຕໍ ຂອງ ຄວາມ ຄືບ ໜ້າ ຂອງ ໂຄງການ ປີ ໃ ໝ ່",
  xmyjkfrytitle: "ນັກ ຄົ້ນຄວ້າ ແລະ ພັດທະນາ ໂຄງການ (ບຸກຄົນ)",
  qsrxmyjkfrytitle: "ກະ ລຸ ນາ ໃສ່ ນັກ ຄົ້ນຄວ້າ ແລະ ພັດທະນາ ໂຄງການ (ບຸກຄົນ)",
  xmrysjgzsjtitle: "ຊົ່ວໂມງ ເຮັດວຽກ ຕົວຈິງ ຂອງ ພະນັກງານ ໂຄງການ (ຄົນ ຕໍ່ເດືອນ)",
  qsrxmrysjgzsjtitle: "ກະ ລຸ ນາ ໃສ່ ຊົ່ວໂມງ ເຮັດວຽກ ຂອງ ບຸກ ຄະ ລາ ກອນ",
  xmjfzctitle: "ລາຍຈ່າຍ ໂຄງການ (ຫ ລາຍ ພັນ ຢ ວ ນ)",
  qsrxmjfzctitle: "ກະ ລຸ ນາ ໃສ່ ລາຍຈ່າຍ ໂຄງການ",
  dwfzrtitle: "ຫ ົ ວ ໜ້າ ໜ່ວຍ ງານ",
  tbrtitle: "ຜູ້ ປະກອບ ແບບ ຟ ອ ມ",
  tbrqtitle: "ວັນທີ ຍື່ນ ສະ ເ ໜີ",
  qxztbrqtitle: "ກະ ລຸ ນາ ເລືອກ ວັນທີ ລາຍງານ",
  tjbtn: "ຍື່ນ ສະ ເ ໜີ",
  zbmctitle: "ຊື່ ຕົວ ຊີ້ ວັດ",
  jldwtitle: "ຫ ົ ວ ໜ່ວຍ ວັດແທກ",
  dmtitle: "ລະຫັດ",
  sltitle: "ຈຳ ນວນ",
  jiatitle: "甲",
  yititle: "乙",
  bingtitle: "丙",
  yjkfryqk1title: "1. ສະຖານະການ ຂອງ ນັກ ຄົ້ນຄວ້າ ແລະ ນັກ ພັດທະນາ",
  yjkfryhj1title: "ພະນັກງານ ຄົ້ນຄວ້າ ແລະ ພັດທະນາ ທັງ ໝ ົ ດ",
  qzglhfwry1title: "ໃນນັ້ນ: ພະນັກງານ ຄຸ້ມ ຄອງ ແລະ ບໍລິການ",
  qzglx1title: "ໃນນັ້ນ: ເພດຍິງ",
  qzqzry1title: "ໃນນັ້ນ: ພະນັກງານ ເຕັມເວລາ",
  qzbkbyjysry1title: "ໃນນັ້ນ: ລະດັບ ປະ ລິນ ຍາ ຕີ ຂຶ້ນໄປ",
  qzwpry1title: "ໃນນັ້ນ: ພະນັກງານ ພາຍນອກ",
  r1title: "人",
  yjkffyqk2title: "ສອງ, ຄ່າໃຊ້ຈ່າຍ ໃນ ການຄົ້ນຄວ້າ ແລະ ການ ພັດທະນາ",
  yjkffyhj2title: "ຄ່າໃຊ້ຈ່າຍ ໃນ ການຄົ້ນຄວ້າ ແລະ ການ ພັດທະນາ ທັງ ໝ ົ ດ",
  ryrgfy2title: "1. ຄ່າໃຊ້ຈ່າຍ ແຮງງານ",
  zjtrfy2title: "2. ຄ່າໃຊ້ຈ່າຍ ໃນ ການ ປ້ອນ ຂໍ້ ມູນ ໂດຍກົງ",
  zjfyycqdtfy2title:
    "3. ຄ່າໃຊ້ຈ່າຍ ຄ່າ ເສື່ອມລາຄາ ແລະ ຄ່າໃຊ້ຈ່າຍ ທີ່ ຍັງ ຄ້າງ ຄາ ໃນໄລຍະ ຍາວ",
  wxzctxfy2title: "4. ຄ່າໃຊ້ຈ່າຍ ໃນ ການ ຊຳ ລະ ຂອງ ຊັບສິນ ທີ່ ບໍ່ມີ ຕົວ ຕົນ",
  sjfy2title: ".. ຄ່າໃຊ້ຈ່າຍ ໃນ ການອອກ ແບບ",
  zbtsfyysyfy2title:
    ".. ຄ່າໃຊ້ຈ່າຍ ໃນ ການ ມອບ ອ ຸ ປະ ກອນ ແລະ ຄ່າໃຊ້ຈ່າຍ ໃນ ການ ທົດສອບ",
  wttsfyysyfy2title:
    ".. ຄ່າໃຊ້ຈ່າຍ ໃນ ການຄົ້ນຄວ້າ ແລະ ການ ພັດທະນາ ພາຍນອກ ທີ່ ໄດ້ຮັບ ມອບ ໝາ ຍ",
  wtjlyjjg2title: "ສະຖາບັນ ຄົ້ນ ຄ ້ ວາ ພາຍໃນ ປະເທດ ທີ່ ໄດ້ຮັບ ມອບ ໝາ ຍ",
  wtjlgdxx2title: "ມອບ ໝາ ຍ ໃ ຫ ້ ສະຖາບັນ ການສຶກສາ ຊັ້ນສູງ ພາຍໃນ ປະເທດ",
  wtjlqy2title: "ມອບ ໝາ ຍ ວິ ສາ ຫ ະ ກິດ ພາຍໃນ ປະເທດ",
  wtjwjg2title: "ມອບ ໝາ ຍ ໃ ຫ ້ ສະຖາບັນ ການຕ່າງປະເທດ",
  qtfy2title: ".. ຄ່າໃຊ້ຈ່າຍ ອ ື ່ ນ ໆ",
  qy2title: "ພັນ ຢ ວ ນ",
  yjkfzcqk3title: "ສາມ, ຄົ້ນ ຄ ້ ວາ ແລະ ພັດທະນາ ຊັບສິນ",
  dnxcyyyjkfdgdzc3title:
    "ຊັບສິນ ຄົງທີ່ ສ້າງ ຕັ້ງ ຂ ື ້ ນ ເພື່ອ ການຄົ້ນຄວ້າ ແລະ ພັດທະນາ ໃນ ປີ ນັ້ນ",
  qizyqhsb3title: "ໃນນັ້ນ: ເຄື່ອງ ມື ແລະ ອ ຸ ປະ ກອນ",
  yjkfzczjly4title:
    "ສີ່, ແ ຫ ຼ ່ ງ ທຶນ ເພື່ອ ການຄົ້ນຄວ້າ ແລະ ລາຍຈ່າຍ ການ ພັດທະນາ",
  lzqyzc4title: "1. ການ ເງິນ ດ້ວຍ ຕົນເອງ ຈາກ ວິ ສາ ຫ ະ ກິດ",
  lzzfbm4title: "2. ຈາກ ພະແນກ ລັດຖະບານ",
  lzyhdk4title: "3. ເງິນ ກູ້ ຈາກ ທະນາຄານ",
  lzfxtz4title: "4. ຈາກ ການ ລົງ ທ ື ນ",
  lzqtqd4title: ".. ຈາກ ແ ຫ ຼ ່ ງ ອ ື ່ ນ ໆ",
  xgzctsqk5title: "ຫ ້ າ, ການ ຈັດຕັ້ງ ປະຕິບັດ ນະໂຍບາຍ ທີ່ ກ່ຽວຂ້ອງ",
  sbjjkc5title:
    "ສະ ໝັກ ການ ຫ ັ ກ ລົບ ເພີ່ມ ເຕີມ ສຳ ລັບ ການຄົ້ນຄວ້າ ແລະ ການ ໃຊ້ຈ່າຍ ໃນ ການ ພັດທະນາ",
  jjkcjmsje5title: "ການ ຫ ັ ກ ລົບ ເພີ່ມ ເຕີມ ແລະ ການ ຫ ັ ກ ອ າ ກອນ",
  gxjsqyjmsje5title: "ການ ຫ ຼ ຸ ດ ຜ່ອນ ພາສີ ສຳ ລັບ ວິ ສາ ຫ ະ ກິດ",
  qybyjkfjg6title:
    "6. ສະຖານະ ພາບ ຂອງ ສະຖາບັນ ຄົ້ນຄວ້າ ແລະ ພັດທະນາ ວິ ສາ ຫ ະ ກິດ (ພາຍໃນ ປະເທດ)",
  qmjgsg6title: "ຈຳ ນວນ ສະຖາບັນ ໃນ ຕອນ ທ້າຍ ຂອງ ໄລຍະ ເວລາ",
  jgyjkfry6title: "ນັກ ຄົ້ນຄວ້າ ແລະ ພັດທະນາ ສະຖາບັນ",
  qzbsby6title: "ໃນນັ້ນ: ປະ ລິນ ຍາ ເ ອ ກ",
  ssby6title: "ປະ ລິນ ຍາ ໂທ",
  jgyjkffy6title: "ຄ່າໃຊ້ຈ່າຍ ໃນ ການຄົ້ນຄວ້າ ແລະ ພັດທະນາ ສະຖາບັນ",
  qmyqhsbyj6title: "ລາຄາ ຕົ້ນສະບັບ ຂອງ ເຄື່ອງ ມື ແລະ ອ ຸ ປະ ກອນ ສິ້ນສຸດ",
  yjkfccjxgqk7title:
    "7. ຜົນ ການຄົ້ນຄວ້າ ແລະ ການ ພັດທະນາ ແລະ ສະພາບ ການທີ່ ກ່ຽວຂ້ອງ",
  zlqk7title: "(1) ສະຖານະ ພາບ ສິດທິບັດ",
  dlzlsqs7title: "ຈຳ ນວນ ຄຳ ຮ້ອງຂໍ ສິດທິບັດ ສຳ ລັບ ປີ",
  qzfmzl7title: "ໃນນັ້ນ: ສິດທິບັດ ປະດິດ ສ້າງ",
  qmyxfmzls7title:
    "ຈຳ ນວນ ສິດທິບັດ ປະດິດ ສ້າງ ທີ່ ຖືກຕ້ອງ ໃນ ຕອນ ທ້າຍ ຂອງ ໄລຍະ ເວລາ",
  qzybss7title: "ໃນນັ້ນ: ໄດ້ ຈັດຕັ້ງ ປະຕິບັດ ແລ້ວ",
  zlsyqzrjxks7title: "ຈຳ ນວນ ການ ໂ ອ ນ ກຳ ມະ ສິດ ແລະ ໃບ ອະນຸຍາດ",
  zlsyqxksr7title: "ການ ໂ ອ ນ ກຳ ມະ ສິດ ສິດທິບັດ ແລະ ລາຍໄດ້ ຈາກ ໃບ ອະນຸຍາດ",
  xcpqk7title: "(2) ສະຖານະການ ຜະລິດຕະພັນ ໃ ໝ ່",
  xcpxssr7title: "ລາຍໄດ້ ຈາກ ການ ຂາຍ ຜະລິດຕະພັນ ໃ ໝ ່",
  qzck7title: "ໃນນັ້ນ: ການ ສົ່ງ ອ ອ ກ",
  qtqk7title: "(3) ສະພາບ ການ ອ ື ່ ນ ໆ",
  qmzcsb7title: "ເຄື່ອງ ໝາ ຍ ການຄ້າ ທີ່ ຈົດທະບຽນ ໃນ ຕອນ ທ້າຍ ຂອງ ໄລຍະ ເວລາ",
  fbkjlw7title: "ເຜີຍແຜ່ ເ ອ ກະ ສານ ວິທະຍາສາດ",
  xcgjhhybz7title: "ສ້າງ ມາດຕະຖານ ແຫ່ງຊາດ ຫ ລື ອ ຸ ດ ສາ ຫ ະ ກຳ",
  jian7title: "件",
  pian7title: "篇",
  xiang7title: "项",
  qtxgqk8title: "ແປດ, ສະພາບ ການທີ່ ກ່ຽວຂ້ອງ ອ ື ່ ນ ໆ",
  jsgchjshqqk8title: "(1) ການ ຫ ັ ນ ເປັນ ເ ຕັກ ໂນ ໂລ ຢ ີ ແລະ ການຊື້ ເ",
  jsgzjfzc8title: "ການ ໃຊ້ຈ່າຍ ໃນ ການ ຫ ັ ນ ເປັນ ເ ຕັກ ໂນ ໂລ",
  gmjljsjfzc8title: "ລາຍຈ່າຍ ສຳ ລັບ ການຊື້ ເ ຕັກ ໂນ ໂລ ຢ ີ ພາຍໃນ ປະເທດ",
  yjjwjsjfzc8title: "ລາຍຈ່າຍ ສຳ ລັບ ການ ແນະ ນຳ ເ ຕັກ ໂນ ໂລ ຢ ີ ຢູ່ ຕ່າງປະເທດ",
  yjjwjsdxhxs8title:
    "ການຍ່ອຍອາຫານ ແລະ ການ ດູດ ຊຶມ ການ ນຳ ໃຊ້ ເ ຕັກ ໂນ ໂລ ຢ ີ ຢູ່ ຕ່າງປະເທດ",
  qybyjkfjg8title:
    "(2) ສະຖານະ ພາບ ຂອງ ສະຖາບັນ ຄົ້ນ ຄ ້ ວາ ແລະ ພັດທະນາ ວິ ສາ ຫ ະ ກິດ (ຢູ່ ຕ່າງປະເທດ)",
  qmqyzjwsldyjkfjgs8title:
    "ຈຳ ນວນ ສະຖາບັນ ຄົ້ນ ຄ ້ ວາ ແລະ ພັດທະນາ ທີ່ ສ້າງ ຕັ້ງຂຶ້ນ ໂດຍ ວິ ສາ ຫ ະ ກິດ ຢູ່",
  ge8title: "个",
  dhtabletitledh:
    "ແບບ ຟ ອ ມ ເກັບ ກຳ ຂໍ້ ມູນ ສຳ ລັບ ຜົນ ສຳ ເ ລັດ ດ້ານ ວິທະຍາສາດ ແລະ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  bhdh: "ຈຳ ນວນ",
  txsjdh: "ຕື່ມ ຂໍ້ ມູນ ໃສ່ ເວລາ",
  dwxxdh: "1. ຂໍ້ ມູນ ຂອງ ໜ່ວຍ ງານ",
  dwmcdh: "ຊື່ ໜ່ວຍ ງານ",
  fddbrdh: "ຜູ້ ຕາງ ໜ້າ ທາງ ດ້ານ ກົດ ໝາ ຍ",
  lxyxdh: "ອ ີ ເມ ວ ຕິດຕໍ່",
  lxrdh: "ຕິດຕໍ່",
  lxdhdh: "ເບີ ໂທລະສັບ ຕິດຕໍ່",
  dwrygkdh: "ຂໍ້ ມູນ ບຸກ ຄະ ລາ ກອນ ຂອງ ໜ່ວຍ ງານ",
  zzrydh: "ຮັບໃຊ້ ພະນັກງານ",
  dzysjsrcdh: "ບຸກ ຄະ ລາ ກອນ ດ້ານ ວິຊາການ ໃນ ວິທະຍາໄລ ຫ ຼ ື ຂ້າງເທິງ",
  qzbsdh: "ໃນ ບັນດາ ທ່ານ ຫ ມໍ",
  zscqyydh: "ການ ເປັນ ເຈົ້າຂອງ ຊັບສິນ ທາງ ປັນຍາ/ແ ຫ ຼ ່ ງ ຂໍ້ ມູນ",
  wgsjzldh: "ສິດທິບັດ ອ ອ ກ ແບບ",
  syxzldh: "ສິດທິບັດ ປະຕິບັດ",
  fmzldh: "ສິດທິບັດ ປະດິດ ສ້າງ",
  rzqdh: "ການ ເປັນ ເຈົ້າຂອງ ອ ່ ອ ນ",
  gmdh: "ຊື້",
  zzyfdh: "ການຄົ້ນຄວ້າ ແລະ ພັດທະນາ ເ ອ ກະ ລາດ",
  hzkfdh: "ການ ພັດທະນາ ຮ່ວມມື",
  qtdh: "ອ ື ່ ນ ໆ",
  sfwgxjsqydh: "ແມ່ນ ວິ ສາ ຫ ະ ກິດ ເ ຕັກ ໂນ ໂລ ຢ ີ ສູງ",
  shidh: "是",
  szsbh: "ແມ່ນ ເລກ ໃບຢັ້ງຢືນ",
  foubh: "否",
  sfwkjxzxqybh: "ມັນ ແມ່ນ SMEs ທີ່ ໃຊ້ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  qtlxscztbh: "ປະເພດ ອ ື ່ ນ ໆ ຂອງ ຫ ົ ວ ໜ່ວຍ ການ ຕະຫຼາດ",
  sfjscxcyptbh: "ບໍ່ວ່າ ຈະ ສ້າງ ເວທີ ການ ປະດິດ ສ້າງ ແລະ ຜູ້ ປະກອບ ການ",
  zckjbh: "ອ າ ວະ ກາດ ຫ ຼ າ ຍ ແ ຫ ່ ງ",
  xctdbh: "ໂລກ ໃ ໝ ່",
  qyjszxbh: "ສູນ ເ ຕັກ ໂນ ໂລ ຢ ີ ວິ ສາ ຫ ະ ກິດ",
  gcyjzxbh: "ສູນ ຄົ້ນຄວ້າ ວິສະວະ ກຳ",
  jscxzxbh: "ສູນ ນະ ວັດຕະ ກຳ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  kjqyfhqbh: "Incubator ທຸລະກິດ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  snjjyxxbh: "2. ຂໍ້ ມູນ ການ ດຳ ເນ ີ ນ ງານ ຂອງ ປີກ່ອນ (10,000 ຢ ວ ນ)",
  zzcbh: "ຊັບສິນ ທັງ ໝ ົ ດ",
  jzcbh: "ຊັບສິນສຸດທິ.\n",
  yysrbh: "营业收入",
  zyftrbh: "ການ ລົງ ທ ື ນ R & D",
  zyysrbh: "ລາຍໄດ້ຈາກທຸລະກິດຫລັກ.\n",
  jlrbh: "ກຳ ໄລ ສຸດທິ",
  yftrbh: "ການ ລົງ ທ ື ນ R & D",
  ydwswybh: "1 ເ ຖ ິ ງ 500,000 ຢ ວ ນ",
  wsdwbwybh: "500,000-5 ລ້ານ ຢ ວ ນ",
  wbdwbwybh: "5-10 ລ້ານ ຢ ວ ນ",
  yqwwbwybh: "ຫ ລາຍ ກວ່າ 10 ລ້ານ ຢ ວ ນ",
  yftrzyysrbblbh: "ການ ລົງ ທ ື ນ R & D ເປັນ ເປີ ເຊັນ ຂອງ ລາຍໄດ້ ປະຕິບັດງານ",
  bfz1bh: "1%",
  bfz1d3bh: "1%-3%",
  bfz3d5bh: "3%-5%",
  bfz5d10bh: "5%-1 0%",
  bfz10bh: "ຫ ຼ າ ຍ ກ ່ ວາ 10%",
  zyywjgxjscpbh:
    "ທຸລະກິດ ຕົ້ນຕໍ ແລະ ຜະລິດຕະພັນ ເ ຕັກ ໂນ ໂລ ຢ ີ ສູງ (ການ ບໍລິການ)",
  sfhkjzxjjbh: "ບໍ່ວ່າ ທ່ານ ຈະ ໄດ້ຮັບ ທຶນ ພິເສດ ສຳ ລັບ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  gjsbh: "ປະເທດ/ແຂວງ",
  sfyhjgtzbh: "ບໍ່ວ່າ ຈະ ເປັນການ ລົງ ທ ື ນ ຂອງ ສະຖາບັນ",
  ljbh: "ສະສົມ",
  jgtzjewybh: "[ຈຳ ນວນ ການ ລົງ ທ ື ນ ຂອງ ສະຖາບັນ] 10,000 ຢ ວ ນ",
  cpjbqkbh: "3. ເງື່ອນໄຂ ພື້ນຖານ ຂອງ ຜະລິດຕະພັນ",
  cpmcbh: "ຊື່ ຜະລິດຕະພັນ",
  ssjslybh: "ຂະ ແໜງ ເ ຕັກ ນ ິ ກ",
  dzxxbh: "ຂໍ້ ມູນ ທາງ ອ ີ ເລັກ ໂທ ຣ ນ ິ ກ",
  rgzlbh: "ປັນຍາ ປະດິດ",
  zinzzgdzbbh: "ການ ຜະລິດ ອ ຸ ປະ ກອນ ລະດັບ ສູງ ທີ່ ສະຫຼາດ",
  qtbh: "ອ ື ່ ນ ໆ",
  xdlybh: "ກະ ສ ິ ກຳ ທັນ ສະ ໄ ໝ",
  gjsfubh: "ບໍລິການ ເ ຕັກ ໂນ ໂລ ຢ ີ ສູງ",
  xnyjjnjsbh: "ເ ຕັກ ໂນ ໂລ ຍີ ພະລັງງານ ແລະ ປະ ຫ ຍັດ ພະລັງງານ ໃ ໝ ່",
  hkhtjsbh: "ເ ຕັກ ໂນ ໂລ ຢ ີ ການ ບິນ ອະວະກາດ",
  zyyhjjsbh: "ຊັບພະຍາກອນ ແລະ ເ ຕັກ ໂນ ໂລ ຢ ີ ສິ່ງແວດລ້ອມ",
  xclbh: "ວັດ ສະ ດຸ ໃ ໝ ່",
  yyhybh: "ອ ຸ ດ ສາ ຫ ະ ກຳ ການ ນຳ ໃຊ້",
  xtrhbh: "ຂໍ້ ມູນ ຂ່າວສານ, ການ ສື່ສານ, ຊອບ ແວ, ອິນເຕີເນັດ",
  llmybh: "ກະ ສ ິ ກຳ, ປ່າໄມ້, ການລ້ຽງສັດ ແລະ ການຫາປາ",
  wshshgzbh: "ວຽກງານ ສາທາລະນະສຸກ ແລະ ສັງຄົມ",
  jtyscchyzbh: "ການຂົນສົ່ງ, ສາງ ແລະ ໄປສະນີ",
  ckybh: "ອ ຸ ດ ສະຫະ ກຳ ບໍ່ແຮ່",
  szjzbh: "ອ າ ຄານ ເທດສະບານ",
  slhjhgyssglbh: "ການ ຄຸ້ມ ຄອງ ນໍ ້ າ, ສິ່ງແວດລ້ອມ ແລະ ເຄື່ອງໃຊ້ ໄຟຟ້າ",
  kxyjhjsfwybh: "ການຄົ້ນຄວ້າ ວິທະຍາສາດ ແລະ ການ ບໍລິການ ດ້ານ ເ ຕັກ ນ ິ ກ",
  zcybh: "ການ ຜະລິດ",
  dlrlrqjscshgybh:
    "ການ ຜະລິດ ແລະ ການ ສະ ໜ ອ ງ ໄຟຟ້າ, ຄວາມຮ້ອນ, ອ າ ຍ ແກ ັ ສ ແລະ",
  gjzzbh: "ອົງການຈັດຕັ້ງ ສາກົນ",
  hgbh: "ສານ ເຄມີ",
  ggglshbzhshzzbh: "ການ ບໍລິຫານ ລັດ, ປະ ກັນ ສັງຄົມ ແລະ ອົງການຈັດຕັ້ງ ທາງສັງຄົມ",
  zphswfwbh: "ການໃຫ້ ເຊົ່າ ແລະ ບໍລິການ ທຸລະກິດ",
  jybh: "ການສຶກສາ",
  yysxbh: "ນຳ ໃຊ້ ຄຸ ນ ລັກສະນະ ຕ່າງໆ",
  yysxcxbh: "ນະ ວັດຕະ ກຳ ໃ ໝ ່",
  jseckfbh: "ການ ພັດທະນາ ເ ຕັກ ໂນ ໂລ ຢ ີ ຂັ້ນ ສອງ",
  jsyjzcxbh: "ການ ແນະ ນຳ ແລະ ການ ປະດິດ ສ້າງ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  zhcxbh: "ນະ ວັດຕະ ກຳ ການ ລົງ ທ ື ນ",
  yfjdbh: "ໄລຍະ R & D",
  yfzbh: "ໃນ R & D",
  xsjdbh: "ໄລຍະ ທົດລອງ",
  zsbh: "ການ ທົດລອງ ທົດລອງ",
  ypbh: "ຕົວຢ່າງ",
  xplbh: "ຊຸດ ນ້ອຍໆ",
  kcyhbh: "ອ ຸ ດ ສາ ຫ ະ ກຳ",
  kjcgxmxqbbh:
    "ສີ່, ຄວາມຕ້ອງການ ໂຄງການ ສຳ ເ ລັດ ດ້ານ ວິທະຍາສາດ ແລະ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  fwxqbh: "ຄວາມຕ້ອງການ ດ້ານ ການ ບໍລິການ",
  jsrcyjbh: "ການ ແນະ ນຳ ບຸກ ຄະ ລາ ກອນ ດ້ານ ວິຊາການ",
  cgzhyxmhzbh: "ການ ຫ ັ ນ ປ່ຽນ ຜົນ ໄດ້ຮັບ ແລະ ການ ຮ່ວມມື ໂຄງການ",
  zljskfgmbh: "ການ ພັດທະນາ/ການຊື້ ເ ຕັກ ໂນ ໂລ ຢ ີ ທີ່ ມີ ສິດທິບັດ",
  xcpyfbh: "ການ ພັດທະນາ ຜະລິດຕະພັນ ໃ ໝ ່",
  qypxbh: "ການ ຝຶກ ອ ົ ບ ຮົມ ວິ ສາ ຫ ະ ກິດ",
  scglbh: "ການ ຄຸ້ມ ຄອງ ການ ຜະລິດ",
  yxchbh: "ການ ວາງແຜນການ ຕະຫຼາດ",
  zyjnbh: "ທັກ ສະ ວິຊາຊີບ",
  xljybh: "ການສຶກສາ ຊັ້ນສູງ",
  qyzxbh: "ການ ຫ ັ ນ ເປັນ ວິ ສາ ຫ ະ ກິດ",
  xyjsjcqkbh: "ພື້ນຖານ ຂອງ ສິນລະປະ ກ່ອນ ໜ້າ ນີ້",
  xyjjdxqwtbh:
    "ບັນຫາ ຄວາມຕ້ອງການ/ຄວາມຕ້ອງການ ດ້ານ ເ ຕັກ ນ ິ ກ ທີ່ ຕ້ອງ ໄດ້ຮັບ ການ ແກ້ໄຂ",
  zljsbh: "ເ ຕັກ ໂນ ໂລ ຢ ີ ທີ່ ມີ ສິດທິບັດ",
  xmhzykfbh: "ການ ຮ່ວມມື ແລະ ພັດທະນາ ໂຄງການ",
  xmsbbh: "ການປະກາດ ໂຄງການ",
  rcyjbh: "ການ ແນະ ນຳ ພອນ ສະຫວັນ",
  xqjsbh: "ຂໍ້ ມູນ ຄວາມຕ້ອງການ",
  qwhzfsbh: "ຄາດວ່າ ເວ ລາການ ຮ່ວມມື",
  gtkfbh: "ການ ພັດທະນາ ຮ່ວມກັນ",
  gmfwbh: "ບໍລິການ ຊື້",
  slzdbh: "ຕັ້ງ ເວ ັ ບ ໄຊ ທ ໌ ້",
  qwhzsjbh: "ຄາດວ່າ ເວ ລາການ ຮ່ວມມື",
  dq1dsbh: "ໄລຍະ ສັ້ນ (1-3 ປີ)",
  cq5nysbh: "ໄລຍະ ຍາວ (ຫ ຼ າ ຍ ກວ່າ 5 ປີ)",
  jjxqwzbh: "ຈົນ ກ ່ ວາ ຄວາມຕ້ອງການ ຖືກ ແກ້ໄຂ",
  yqtrbh: "ການ ລົງ ທ ື ນ ທີ່ ຄາດວ່າ ຈະ",
  swyyxbh: "ຕ ່ ຳ ກວ່າ 100,000 ຢ ວ ນ",
  sdsswybh: "100,000 ເ ຖ ິ ງ 300,000 ຢ ວ ນ",
  ssdwsbh: "300,000-500,000 ຢ ວ ນ",
  wsdybwbh: "500,000 ເ ຖ ິ ງ 1 ລ້ານ ຢ ວ ນ",
  ybdybwbh: "1-1,5 ລ້ານ ຢ ວ ນ",
  ybwdebbh: "1,5-2 ລ້ານ ຢ ວ ນ",
  ebdsbbh: "2-3 ລ້ານ ຢ ວ ນ",
  wbysbh: "ຫ ລາຍ ກວ່າ 5 ລ້ານ ຢ ວ ນ",
  qtysfwbh: "ອ ື ່ ນ ໆ (ຂອບເຂດ ງົບປະມານ)",
  glxxbh: "ຫ ້ າ, ຂໍ້ ມູນ ການ ຄຸ້ມ ຄອງ",
  tygkxqxxbh: "ຕົກລົງ ເ ຫ ັ ນ ດີ ທີ່ ຈະ ເປີດເຜີຍ ຂໍ້ ມູນ ຄວາມຕ້ອງການ",
  bfgkbh: "ການ ເປີດເຜີຍ ບາງ ສ່ວນ",
  tyxyzjfwbh: "ຕົກລົງ ເ ຫ ັ ນ ດີວ່າ ຕ້ອງ ມີການ ບໍລິການ ຊ່ຽວຊານ",
  tycydjjfadsxpjbh:
    "ຕົກລົງ ເ ຫ ັ ນ ດີ ເຂົ້າຮ່ວມ ໃນ ການ ກວດກາ ແລະ ປະເມີນ ຜົນ ວິທີ ແກ້ໄຂ",
  dcxyjhzjlhddjyhxqbh:
    "ຄຳ ແນະ ນຳ ແລະ ຄວາມຕ້ອງການ ສຳ ລັບ ການ ຮ່ວມມື ດ້ານ ອ ຸ ດ ສາ ຫ ະ ກຳ-ມະຫາວິທະຍາໄລ-ການຄົ້ນຄວ້າ",
  tjbh: "ຍື່ນ ສະ ເ ໜີ",
  dybh: "ພິມ",
  qtxbhbh: "ກະ ລຸ ນາ ຕື່ມ ໃສ່ ເບີ ໂທລະສັບ",
  qtxdwmcbh: "ກະ ລຸ ນາ ຂຽນ ຊື່ ອ ົ ງ ກອນ",
  qtxlxrbh: "ກະ ລຸ ນາ ຕື່ມ ໃສ່ ລາຍຊື່ ຜູ້ຕິດຕໍ່",
  qtxlxdhbh: "ກະ ລຸ ນາ ຕື່ມ ໃສ່ ເບີ ໂທລະສັບ ຕິດຕໍ່",
  zztjztsjbh: "ຂໍ້ ມູນ ນີ້ ກຳ ລ ັ ງ ຖືກ ສົ່ງ, ທ່ານ ຕ້ອງການ ດຳ ເນ ີ ນ ການ ຕໍ່ໄປ",
  tjcgbh: "ການ ຍື່ນ ສະ ເ ໜີ ທີ່ ປະສົບ ຜົນ ສຳ ເ ລັດ",
  nccenter: "ຊື່ ຫ ຼ ິ ້ ນ",
  bindcenter: "ຜູກມັດ",
  sqsmrzcenter: "ສະ ໝັກ ເ ອ ົ າ ໃບຢັ້ງຢືນ ຊື່ ແທ້",
  smrzcenter: "ການ ກວດສອບ ຊື່ ແທ້",
  sqzjrzcenter: "ໃບຢັ້ງຢືນ ຜູ້ຊ່ຽວຊານ",
  sqjgrzcenter: "ສະ ໝັກ ເ ອ ົ າ ໃບຢັ້ງຢືນ ສະຖາບັນ",
  jgrzcenter: "机构认证",
  scdlcenter: "ເຂົ້າ ສູ່ ລະບົບ ຄັ້ງສຸດທ້າຍ",
  xqlcenter: "ຄວາມຕ້ອງການ",
  cgzscenter: "ການ ນຳ ສະ ເ ໜີ ຜົນ ໄດ້ຮັບ",
  zhaqcenter: "ຄວາມປອດໄພ ຂອງ ບັນຊີ",
  dlmmcenter: "ລະຫັດ ຜ່ານ ເຂົ້າ ສູ່ ລະບົບ",
  yybhzhxxcenter:
    "ໃຊ້ ເພື່ອ ປົກປ້ອງ ຂໍ້ ມູນ ບັນຊີ ແລະ ຄວາມປອດໄພ ໃນ ການ ເຂົ້າ ສູ່ ລະບົບ",
  ggcenter: "ປ່ຽນ",
  aqsjcenter: "ໂທລະສັບ ປອດໄພ",
  yyzktfxcenter:
    "ຢືນຢັນ, ທ່ານ ສາມາດ ເຂົ້າ ສູ່ ລະບົບ ຜ່ານ ໂທລະສັບ ມືຖື ຂອງທ່ານ ຫ ຼ ື ເ ອ ົ າ ລະຫັດ ຜ່ານ ຂອງທ່ານ",
  xgdlmmcenter: "ປ່ຽນ ລະຫັດ ຜ່ານ ເຂົ້າ ສູ່ ລະບົບ",
  dqmmcenter: "ລະຫັດ ຜ່ານ ປະຈຸບັນ",
  xmmcenter: "ລະຫັດ ລັບ ໃ ໝ ່",
  qrmmcenter: "ຢືນຢັນ ລະຫັດ ຜ່ານ",
  mmxgcgcenter: "ການ ປ່ຽນ ລະຫັດ ຜ່ານ ປະສົບ ຜົນ ສຳ ເ ລັດ!",
  tjcenter: "ຍື່ນ ສະ ເ ໜີ",
  qxcenter: "ຍົກເລີກ",
  qdcenter: "ຕົກລົງ",
  bdsjhcenter: "ມັດ ໂທລະສັບ ໃ ໝ ່",
  xsjhmcenter: "ເບີ ໂທລະສັບ ມືຖື ໃ ໝ ່",
  yzmcenter: "ລະຫັດ ຢືນຢັນ",
  hqcenter: "ເ ອ ົ າ ລະຫັດ ຢືນຢັນ",
  qsrsjhmvocenter: "ກະ ລຸ ນາ ໃສ່ ເບີ ໂທລະສັບ ມືຖື ຂອງທ່ານ",
  cdwsygzfvocenter: "11 ຕົວ ອັກສອນ ຍາວ",
  qsryzmvocenter: "ກະ ລຸ ນາ ໃສ່ ລະຫັດ ຢືນຢັນ",
  qsrmmvocenter: "ກະ ລຸ ນາ ໃສ່ ລະຫັດ ຜ່ານ",
  qzcsrmmmvocenter: "ກະ ລຸ ນາ ໃສ່ ລະຫັດ ຜ່ານ ອ ີ ກ ຄັ້ງ",
  lcsrmmbyzvocenter: "ລະຫັດ ຜ່ານ ບໍ່ ກົງ ກັນ ສອງຄັ້ງ!",
  zzxgmmvocenter: "ການ ປ່ຽນ ລະຫັດ ຜ່ານ, ທ່ານ ຕ້ອງການ ດຳ ເນ ີ ນ ການ ຕໍ່ ບໍ?",
  cgvocenter: "ຄວາມ ສຳ ເ ລັດ",
  zzxgsjhmvocenter: "ທ່ານ ກຳ ລ ັ ງ ປ່ຽນ ເບີ ໂທລະສັບ ຂອງທ່ານ ຢູ່ ບໍ?",
  tsvocenter: "ຄຳ ແນະ ນຳ",
  dlsjhcenter: "ໝາ ຍ ເລກ ໂທລະສັບ ເຂົ້າ ສູ່ ລະບົບ",
  ybdcenter: "ຜູກມັດ",
  wbdcenter: "ຜູກມັດ",
  ghbdcenter: "ການ ທົດແທນ ການຜູກມັດ",
  qbdcenter: "ເພື່ອ ຜູກມັດ",
  xgmmcenter: "ປ່ຽນ ລະຫັດ ຜ່ານ",
  jbxxcenter: "ຂໍ້ ມູນ ພື້ນຖານ",
  jbxxdesccenter:
    "ການໃຫ້ ຂໍ້ ມູນ ຕົວ ຕົນ ທີ່ ຖືກຕ້ອງ ສາມາດ ຊ່ວຍ ໃນ ການ ກວດສອບ ຕົວ ຕົນ ແລະ ປັບປຸງ ຮູບ ພາບ ສິນ ເຊື່ອ",
  zsxmccenter: "ຊື່ ແທ້",
  zsxmcvomcenter: "ກະ ລຸ ນາ ໃສ່ຊື່ ແທ້ ຂອງທ່ານ",
  xbvomcenter: "ກະ ລຸ ນາ ເລືອກ ເພດ",
  cslyvomcenter: "ກະ ລຸ ນາ ເລືອກ ວັນເກີດ",
  qsrzssfzhvomcenter: "ກະ ລຸ ນາ ໃສ່ ເລກ ປະ ຈຳ ຕົວ ທີ່ ແທ້ຈິງ",
  qscsfzzmvomcenter: "ກະ ລຸ ນາ ອ ັ ບ ບັດ ປະ ຈຳ ຕົວ ຢູ່ ທາງ ໜ້າ",
  qscsfzbmvomcenter: "ກະ ລຸ ນາ ອ ັ ບ ໂ ຫ ລ ດ ບັດ ປະ ຈຳ",
  zzthsmxxcenter:
    "ການ ສົ່ງ ຂໍ້ ມູນ ຊື່ ແທ້, ທ່ານ ຕ້ອງການ ດຳ ເນ ີ ນ ການ ຕໍ່ໄປ ບໍ?",
  zzbcgrxxcenter: "ຂໍ້ ມູນ ສ່ວນຕົວ ກຳ ລ ັ ງ ຖືກ ບັນທຶກ, ທ່ານ ຕ້ອງການ ດຳ ເນ ີ ນ",
  xbcenter: "ເພດ",
  nancenter: "男",
  nvcenter: "女",
  wzcenter: "ບໍ່ຮູ້",
  cslycenter: "ວັນ ເດືອນ ປີ ເກີດ",
  sfzhcenter: "ເລກ ປະ ຈຳ ຕົວ",
  sfzzmcenter: "ດ້ານ ໜ້າ ຂອງ ເ ອ ກະ ສານ ປະ ຈຳ ຕົວ",
  sfzzmdesccenter:
    "ຮູບ ພາບ ຕົ້ນສະບັບ ທີ່ ຊັດເຈນ ຢູ່ ດ້ານ ໜ້າ ແລະ ດ້ານຫລັງ ຂອງ ບັດ ປະ ຈຳ ຕົວ ສ່ວນ ບຸກຄົນ ຮຽກຮ້ອງ ໃ ຫ ້ ມີ ຮູບ ແບບ JPG, GIF, PNG ຕ ່ ຳ ກວ່າ 200KB",
  sfzfmcenter: "ດ້ານ ກົງກັນຂ້າມ ຂອງ ເ ອ ກະ ສານ ປະ ຈຳ ຕົວ",
  tjsmxxcenter: "ຍື່ນ ສະ ເ ໜີ ຂໍ້ ມູນ ຊື່ ແທ້",
  grzlcenter: "ຂໍ້ ມູນ ສ່ວນຕົວ",
  sctxcenter: "ອ ັ ບ ໂ ຫ ລ ດ avatar",
  sctxdesccenter: "ແນະ ນຳ ໃ ຫ ້ ອ ັ ບ ໂ ຫ ລ ດ ຮູບ ປະ ຈຳ",
  yhlccenter: "ຊື່ ຫ ຼ ິ ້ ນ ຂອງ ຜູ້ ໃຊ້",
  szdqcenter: "ພື້ນທີ່",
  qxzcenter: "ກະ ລຸ ນາ ເລືອກ",
  yxcenter: "ກ່ອງ ຈົດ ໝາ ຍ",
  yxdesccenter: "ກະ ລຸ ນາ ໃສ່ ອ ີ ເມ ວ ປົກກະຕິ ຂອງທ່ານ",
  jzdzcenter: "ທີ່ຢູ່ ທີ່ຢູ່ອາໄສ",
  zycenter: "ອາຊີ ບ",
  zydesccenter: "ອ ຸ ດ ສາ ຫ ະ ກຳ ຫ ລື ອາຊີ ບ",
  qqcenter: "QQ",
  qqdesccenter:
    "ກະ ລຸ ນາ ໃສ່ QQ ທີ່ ແທ້ຈິງ ເພື່ອ ອ ຳ ນວຍ ຄວາມສະດວກ ໃນ ການ ສື່ສານ ລະຫວ່າງ ການ ບໍລິການ ລູກຄ້າ ແລະ ທ່ານ",
  bccenter: "ບັນທຶກ",
  qsrnccenter: "ກະ ລຸ ນາ ໃສ່ຊື່ ຫ ຼ ິ ້ ນ",
  qsryxcenter: "ກະ ລຸ ນາ ໃສ່ ອ ີ ເມ ວ",
  qsrxjzdcenter: "ກະ ລຸ ນາ ໃສ່ ທີ່ຢູ່ ທີ່ຢູ່ອາໄສ",
  qsrzycenter: "ກະ ລຸນ າ ໃສ່ ອາຊີ ບ",
  qsrqqcenter: "ກະ ລຸ ນາ ໃສ່ QQ",
  qsctxcenter: "ກະ ລຸ ນາ ອ ັ ບ ໂ ຫ ລ ດ avatar",
  qxzsfcenter: "ກະ ລຸ ນາ ເລືອກ ແຂວງ",
  qxzcscenter: "ກະ ລຸ ນາ ເລືອກ ເມືອງ",
  qxzqycenter: "ກະ ລຸ ນາ ເລືອກ ພື້ນທີ່",
  jgrzdesccenter:
    "ການໃຫ້ ຂໍ້ ມູນ ທີ່ ຖືກຕ້ອງ ສາມາດ ຊ່ວຍ ໃນ ການ ກວດສອບ ຄວາມ ຖືກຕ້ອງ ຕື່ມ ອ ີ ກ.",
  qymcccenter: "ຊື່ ທຸລະກິດ",
  qydzcenter: "ທີ່ຢູ່ ທຸລະກິດ",
  fddbrcenter: "ຜູ້ ຕາງ ໜ້າ ທາງ ດ້ານ ກົດ ໝາ ຍ",
  tyshxydmcenter: "ລະຫັດ ສິນ ເຊື່ອ ສັງຄົມ ທີ່ ເປັນ ເ ອ ກະ ພາບ",
  yyzzcenter: "ໃບ ອະນຸຍາດ ດຳ ເນ ີ ນ ທຸລະກິດ",
  yyzzdesccenter:
    "ຮູບ ພາບ ໃບ ອະນຸຍາດ ດຳ ເນ ີ ນ ທຸລະກິດ ທີ່ ຈະແຈ້ງ ຮຽກຮ້ອງ ໃ ຫ ້ ມີ ຮູບ ພາບ JPG, GIF, ຮູບ ແບບ PNG ຕໍ່ າ ກວ່າ 200KB",
  tgrzxxcenter: "ໃ ຫ ້ ຂໍ້ ມູນ ການ ຢັ້ງຢືນ",
  qyyjkfxmqkcenter: "ສະຖານະ ພາບ ຂອງ ໂຄງ ການຄົ້ນຄວ້າ ແລະ ພັດທະນາ ວິ ສາ ຫ ະ ກິດ",
  qbcenter: "ທັງ ໝ ົ ດ",
  wshtgcenter: "ບໍ່ ໄດ້ຮັບ ການ ອະນຸມັດ",
  yshcenter: "ກວດສອບ ແລ້ວ",
  dshcenter: "ທີ່ ຍັງ ຄ້າງ ຄາ",
  sscenter: "ຄົ້ນຫາ",
  qsrgjzcenter: "ກະ ລຸ ນາ ໃສ່ ຄຳ ສຳ ຄັນ",
  xmmccenter: "ຊື່ ໂຄງການ",
  xmlycenter: "ແ ຫ ຼ ່ ງ ຂໍ້ ມູນ ຂອງ ໂຄງການ",
  kzxscenter: "ແບບ ຟ ອ ມ ການ ພັດທະນາ",
  kssjcenter: "ເວລາ ເ ລີ່ ມ ຕົ້ນ",
  jssjcenter: "ເວລາ ສິ້ນສຸດ",
  fzrcenter: "ຜູ້ຮັບຜິດຊອບ",
  czcenter: "ການ ປະຕິບັດງານ",
  qyyjkfhdcenter: "ກິດ ຈະ ກຳ ຄົ້ນຄວ້າ ແລະ ພັດທະນາ ວິ ສາ ຫ ະ ກິດ",
  dwmccenter: "ຊື່ ໜ່ວຍ ງານ",
  dwfzrcenter: "ຫ ົ ວ ໜ້າ ໜ່ວຍ ງານ",
  tbrcenter: "ຜູ້ ປະກອບ ແບບ ຟ ອ ມ",
  yxqkscenter: "ເວລາ ເ ລີ່ ມ ຕົ້ນ ທີ່ ຖືກຕ້ອງ",
  yxqjscenter: "ເວລາ ສິ້ນສຸດ ຂອງ ວັນ ໝ ົ ດ ອາຍຸ",
  kjcgxqzjcenter:
    "ຄວາມຕ້ອງການ ສຳ ລັບ ຜົນ ສຳ ເ ລັດ ດ້ານ ວິທະຍາສາດ ແລະ ເ ຕັກ ໂນ ໂລ",
  cpmccenter: "ຊື່ ຜະລິດຕະພັນ",
  txsjcenter: "ຕື່ມ ຂໍ້ ມູນ ໃສ່ ເວລາ",
  fbkjcgcgxxcenter:
    "ເຜີຍແຜ່ ຂໍ້ ມູນ ຜົນ ສຳ ເ ລັດ ດ້ານ ວິທະຍາສາດ ແລະ ເ ຕັກ ໂນ ໂລ ຢ",
  fbkjcgcgdesccenter:
    "ຄວາມສົມບູນ ຂອງ ຂໍ້ ມູນ ສູງ ຂ ື ້ ນ, ມັນ ຈະ ງ່າຍ ກວ່າ ທີ່ ຈະ ໄດ້ ຮັບຄວາມ ສົນໃຈ ຈາກ ລູກຄ້າ ເປົ້າ ໝາ ຍ",
  fbkjcgjsmccenter: "ຊື່ ເ ຕັກ ນ ິ ກ",
  hylbcenter: "ປະເພດ ອ ຸ ດ ສາ ຫ ະ ກຳ",
  zscqztcenter: "ສະຖານະ ພາບ ຊັບສິນ ທາງ ປັນຍາ",
  zlmccenter: "ຊື່ ສິດທິບັດ",
  zlhcenter: "ເລກ ສິດທິບັດ",
  jjcenter: "ການ ແນະ ນຳ",
  sfcjcenter: "ບໍ່ວ່າ ຈະ ເປັນ ການຕົກລົງ",
  zrfscenter: "ວິທີການ ໂ ອ ນ ຍ້າຍ",
  cgszdwcenter: "ຫ ົ ວ ໜ່ວຍ ຜົນ ໄດ້ຮັບ",
  dwlxcenter: "ປະເພດ ຫ ົ ວ ໜ່ວຍ",
  szdwmccenter: "ຊື່ ອ ົ ງ ກອນ",
  cgszdcenter: "ສະຖານທີ່ ຜົນ ໄດ້ຮັບ",
  fbcgzszmclcenter: "ປ່ອຍ ເ ອ ກະ ສານ ສະແດງ ຜົນ-ຫ ຼ ັ ກ ຖານ ສະແດງ",
  fbcgdesczmcenter:
    "ອ ັ ບ ໂ ຫ ລ ດ ເ ອ ກະ ສານ ຢັ້ງຢືນ ເພື່ອ ອ ຳ ນວຍ ຄວາມສະດວກ ໃ ຫ",
  xxcycenter: "ອ ຸ ດ ສາ ຫ ະ ກຳ ໃ ໝ ່",
  kjcgpjspcenter: "ລະດັບ ການ ປະເມີນ ຜົນ ສຳ ເ ລັດ ດ້ານ ວິທະຍາສາດ ແລະ ເ ຕັກ ໂນ",
  csdcenter: "ການ ໃ ຫ ຍ ່ ເຕັມຕົວ",
  csdzmcenter: "ຫ ຼ ັ ກ ຖານ ສະແດງ ການ ໃ ຫ ຍ ່ ເຕັມຕົວ",
  csdzmdesccenter:
    "ຮູບ ພາບ ທີ່ ຊັດເຈນ ຮຽກຮ້ອງ ໃ ຫ ້ ມີ ຮູບ ພາບ ໃນ ຮູບ ແບບ jpg, gif, png ຕ ່ ຳ ກວ່າ 100KB.",
  jstpcenter: "ຮູບ ພາບ ດ້ານ ວິຊາການ",
  jspgjzcenter: "ມູນຄ່າ ການ ປະເມີນ ເ ຕັກ ໂນ ໂລ ຢ ີ",
  lxrcenter: "ຕິດຕໍ່",
  sjhmcenter: "ເບີ ໂທລະສັບ ມືຖື",
  zqsrsjhcenter: "ໃສ່ ເບີ ໂທລະສັບ ຂອງທ່ານ ຢ່າງ ຖືກຕ້ອງ",
  zqsrlxrcenter: "ຕື່ມ ຂໍ້ ມູນ ໃສ່ ລາຍຊື່ ຜູ້ຕິດຕໍ່ ຢ່າງ ຖືກຕ້ອງ",
  tjkjcgshcenter: "ຍື່ນ ສະ ເ ໜີ ການ ກວດກາ ຜົນ ສຳ ເ ລັດ ດ້ານ ວິທະຍາສາດ ແລະ",
  glcgcenter: "ຜົນ ການ ຄຸ້ມ ຄອງ",
  kjcgmccenter: "ຊື່ ຜົນ ສຳ ເ ລັດ ດ້ານ ວິທະຍາສາດ ແລະ ເ ຕັກ ໂນ ໂລ",
  zjzkzjxxcenter: "ຜູ້ຊ່ຽວຊານ ດ້ານ ຂໍ້ ມູນ ຂ່າວສານ Think Tank-ຊ່ຽວຊານ",
  zjzkzjxxdesccenter:
    "ການໃຫ້ ຂໍ້ ມູນ ຕົວ ຕົນ ທີ່ ຖືກຕ້ອງ ສາມາດ ຊ່ວຍ ໃນ ການ ກວດສອບ ຕົວ ຕົນ ແລະ ປັບປຸງ ຮູບ ພາບ ສິນ ເຊື່ອ",
  zgxlcenter: "ລະດັບ ສູງສຸດ",
  gzdwcenter: "ໜ່ວຍ ງານ ເຮັດວຽກ",
  zwcenter: "ຕຳ ແ ໜ ່ ງ",
  lxdhcenter: "ເບີ ໂທລະສັບ ຕິດຕໍ່",
  fwalcenter: "ກ ໍ ລະ ນ ີ ບໍລິການ",
  tjshcenter: "ຍື່ນ ສະ ເ ໜີ ການ ທົບ ທວນຄືນ",
  glzjzkxicenter:
    "ຜູ້ຊ່ຽວຊານ ດ້ານ ການ ຄຸ້ມ ຄອງ ຂໍ້ ມູນ ຂ່າວສານ ດ້ານ ການ ຄຸ້ມ ຄອງ",
  zjmzcenter: "ຊື່ ຜູ້ຊ່ຽວຊານ",
  zjhycenter: "ອ ຸ ດ ສາ ຫ ະ ກຳ ຊ່ຽວຊານ",
  tjrqcenter: "ເພີ່ມ ວັນທີ",
  zcxlcenter: "ຫ ົ ວ ຂໍ້/ການສຶກສາ",
  fbjsxqxqxxcenter: "ເຜີຍແຜ່ ຂໍ້ ມູນ ຄວາມຕ້ອງການ ດ້ານ ເ ຕັກ ນ ິ ກ-ຄວາມຕ້ອງການ",
  fbjsxqdesccenter:
    "ຕື່ມ ຂໍ້ ມູນ ໃສ່ ໃນ ຂໍ້ ມູນ ຢ່າງ ຖືກຕ້ອງ ແລະ ຜ່ານ ການ ກວດສອບ ຕໍ່ໄປ",
  xqfmccenter: "ການ ປົກ ຫ ຸ ້ ມ ຂອງ ຄວາມຕ້ອງການ",
  xqfmdesccenter:
    "ການ ປົກ ຫ ຸ ້ ມ ຂອງ ຄວາມຕ້ອງການ ດ້ານ ວິຊາ ການທີ່ ສະແດງ ຢູ່ໃນ ບັນຊີ.",
  xqmccenter: "ຊື່ ຄວາມຕ້ອງການ",
  fbxqlxxxcenter: "ເຜີຍແຜ່ ຂໍ້ ມູນ ຄວາມຕ້ອງການ ດ້ານ ເ ຕັກ ນ ິ ກ-ຕິດຕໍ່",
  fbxqlxxxdesccenter:
    "ຕື່ມ ຂໍ້ ມູນ ໃສ່ ໃນ ຂໍ້ ມູນ ການ ຕິດຕໍ່ ຢ່າງ ຖືກຕ້ອງ ເພື່ອໃຫ້ ລູກຄ້າ ສາມາດ ຕິດຕໍ່ ຫ າ ທ່ານ ໄດ້",
  tjjsxqshcenter: "ຍື່ນ ສະ ເ ໜີ ການ ກວດສອບ ຄວາມຕ້ອງການ ດ້ານ ເ ຕັກ ນ ິ ກ",
  glxqcenter: "ຄວາມຕ້ອງການ ດ້ານ ການ ຄຸ້ມ ຄອງ",
  xiugaicenter: "ດັດແປງ",
  sanchucenter: "ລົບ",
  kjcglycenter: "ຂໍ້ຄວາມ ສຳ ເ ລັດ ດ້ານ ວິທະຍາສາດ ແລະ ເ ຕັກ ໂນ ໂລ ຢ",
  lybkcenter: "ກະດານ ຂໍ້ຄວາມ",
  lydxcenter: "ຈຸດປະສົງ ຂໍ້ຄວາມ",
  dhcscenter: "ຈຳ ນວນ ການ ສົນທະນາ",
  jsxqlycenter: "ຂໍ້ຄວາມ ຄວາມຕ້ອງການ ດ້ານ ເ ຕັກ ນ ິ ກ",
  kjcgcenter: "ຂໍ້ຄວາມ ສຳ ເ ລັດ ດ້ານ ວິທະຍາສາດ ແລະ ເ ຕັກ ໂນ ໂລ ຢ",
  zjzklycenter: "ຂໍ້ຄວາມ ຂອງ ຜູ້ຊ່ຽວຊານ ດ້ານ ແນວ ຄິດ",
  jzmmL: "ຈື່ ລະຫັດ ຜ່ານ",
  "welogin": "ຍິນດີຕ້ອນຮັບເຂົ້າສູ່ລະບົບ.\n",
  "weuser": "ຊື່ຜູ້ໃຊ້.\n",
  "wepass": "ລະຫັດລັບ.\n",
  "weother": "ເຂົ້າສູ່ລະບົບອື່ນໆ.\n"
};
