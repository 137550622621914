<template>
  <div id="app">
    <PageHeader v-if="hederType&&hederType=='dehongweb'"></PageHeader>
    <ProjectHeader  v-else-if="hederType&&hederType=='shenbao'"></ProjectHeader>
    <router-view/>
    <PageFooter v-if="isChangeLang&&$route.path!='/kjcgxqadmin'" :class="hederType!='hide'&&(hederType&&hederType=='dehongweb'?'pa-footer':'po-footer')"></PageFooter>
    <!-- <ul class="ignore"  v-if="hederType&&hederType=='dehongweb'">
      <li><a href="javascript:translate.changeLanguage('english');">English</a></li>
      <li><a href="javascript:translate.changeLanguage('chinese_simplified');">简体中文</a>|</li>
      <li><a href="javascript:translate.changeLanguage('chinese_traditional');">繁體中文</a>|</li>
      <div style="clear: both;"></div>
    </ul> -->
  </div>
</template>

<script>
import { getToken, setToken, removeToken } from "@/utils/auth";
import PageHeader from '@/components/PageHeader.vue';
import ProjectHeader from '@/components/ProjectHeader.vue';
import PageFooter from '@/components/PageFooter.vue';
export default {
  components:{
    PageHeader,
    ProjectHeader,
    PageFooter
  },
  data() {
    return {
      routePath:'',
      hederType:'',
      // isReload:true,
      hideHeadAndFooter:['/loginold',"/register"]
    }
  },
  mounted(){
    this.winxinLogin()
  },
  computed:{
    isChangeLang(){
      return this.$store.state.isChangeLang
    },
  },
  beforeCreate(){
    this.routePath = this.$route.path
  },
  // provide() { // 提供可注入子组件属性
  //   return {
  //     reload: this.reload
  //   }
  // },
  watch:{
    $route(to,from){
      this.hederType = to.meta.hederType
      this.routePath = to.path
    }
  },
  methods:{
    getQueryVariable(variable){
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){return pair[1];}
        }
        return false;
    },
    winxinLogin(){
      var token = this.getQueryVariable("token");
      // console.log("this.$route.query",token);
      if(token){
        setToken(decodeURI(token));
        this.$store.commit("setToken", decodeURI(token));
        var that = this;
        that.$httpApi.usercenter.getUserInfo().then(function (res) {
          if (res.code == 200) {
            //  console.log(res,'resresresresres');
          }
        });
      }
    }
    // reload() {
    //   console.log(123123);
    //   this.isReload = false
    //   this.$nextTick(() => {
    //     this.isReload = true
    //   })
    // }
  }
}
</script>

<style>
.ignore {
  padding: 0 10px;
}
.ignore li{
  float: right;
  margin-right: 6px;
  line-height: 24px;
}
.ignore li a{
  font-size: 12px;
  margin-right: 4px;
}
</style>
