export default {
  wecome:
    "Xin chào và chào mừng bạn đến với nền tảng dịch vụ chuyển giao công nghệ Dehongzhou!",
  login: "Đăng nhập",
  nick: "Biệt danh",
  bind: "Sự ràng buộc",
  search: "Tìm kiếm",
  qxzxyssdflsearch: "Vui lòng chọn danh mục cần tìm kiếm",
  searchP: "Vui lòng chọn",
  searchI: "Vui lòng nhập nội dung",
  register: "Đăng ký",
  center: "Trung tâm cá nhân",
  zcxwtitle: "Tin tức chính sách",
  tzgotitle: "Thông báo Thông báo",
  kjhztitle: "Hợp tác khoa học công nghệ",
  kjcgtitle: "Thành tựu khoa học công nghệ",
  kjjrtitle: "Tài chính công nghệ",
  zjzktitle: "Nhóm chuyên gia cố vấn",
  zjzkftitle: "Chuyên gia Thinktank Sàng lọc",
  kjcgftitle: "Sàng lọc thành tựu khoa học công nghệ",
  jsxqtitle: "Nhu cầu công nghệ",
  jsxqftitle: "Sàng lọc nhu cầu công nghệ",
  jslinytitle: "Lĩnh vực công nghệ",
  fbxqtitle: "Nhu cầu xuất bản",
  wxxztitle: "Tài liệu tải về",
  zbdwname: "Đơn vị tổ chức",
  dhname: "Điện thoại",
  jszcname: "Hỗ trợ kỹ thuật",
  dzname: "Địa chỉ",
  syname: "Trang chủ",
  fbcg: "Công bố kết quả",
  xmsb: "Quản lý dự án \n",
  sbtz: "Thông báo kê khai",
  all: "Tất cả",
  in_progress: "Đang tiến hành",
  stop: "Đã được cắt",
  notopen: "Chưa bắt đầu",
  expired: "Hết hạn sử dụng",
  kjtj_title: "Thống kê khoa học và công nghệ",
  lilantitle: "Trình duyệt",
  layuantitle: "Nguồn tin",
  lilanltitle: "Số lượt xem",
  timetitle: "Thời gian",
  zwtitle: "Văn bản",
  fbsjtitle: "Thời gian phát hành",
  hyfltitle: "Phân loại ngành nghề",
  zjjjtitle: "Giới thiệu chuyên gia",
  lxzjtitle: "Liên hệ với chuyên gia",
  xlxwtitle: "Trình độ học vấn/Bằng cấp",
  zczwtitle: "Chức danh Chức vụ",
  xmtitle: "Tên gọi",
  fjtitle: "Phụ kiện",
  xqtitle: "Chi tiết",
  hylbtitle: "Danh mục ngành nghề",
  jssptitle: "Trình độ công nghệ",
  lxdhtitle: "Số điện thoại liên hệ",
  rcktitle: "Người dân xem",
  ljcktitle: "Xem tích lũy",
  zrfstitle: "Phương thức chuyển nhượng",
  zscqzttitle: "Tình trạng sở hữu trí tuệ",
  zlmcttitle: "Tên bằng sáng chế",
  cgszdwttitle: "Các đơn vị có kết quả",
  cgjjttitle: "Giới thiệu về kết quả",
  zlhttitle: "Bằng sáng chế số",
  yxtitle: "Hộp thư điện tử",
  xqjjtitle: "Giới thiệu về nhu cầu",
  lxtitle: "Liên hệ",
  my: "Có thể thương lượng",
  jg: "Giá cả",
  mslxtitle: "Liên hệ với bây giờ",
  swdlsfjx: "Chưa đăng nhập, đi đăng nhập, có tiếp tục không?",
  ts: "Lời khuyên",
  ok: "Quyết tâm",
  successs: "Thành công",
  cancel: "Hủy bỏ",
  qsrnxsdh: "Vui lòng nhập những gì bạn muốn nói",
  lxcg: "Liên hệ thành công!",
  lycghxgryhqdlx:
    "Sau khi tin nhắn thành công, những người liên quan sẽ liên lạc với bạn, vui lòng giữ điện thoại thông suốt, có tiếp tục không?",
  qyyjkfxmqk_title: "Tình hình nghiên cứu, xây dựng đề án của các doanh nghiệp",
  qyyjkfhdjxgqktitle:
    "Hoạt động nghiên cứu, phát triển của doanh nghiệp và tình hình liên quan đến",
  qsrsjhmtitle: "Vui lòng nhập số điện thoại di động",
  qsrdlzhtitle: "Vui lòng nhập tài khoản đăng nhập",
  qsrdlmmtitle: "Vui lòng nhập mật khẩu đăng nhập",
  wecomestitle: "Chào mừng!",
  cd3d5title: "Chiều dài từ 3 đến 15 ký tự",
  qsrmmtitle: "Vui lòng nhập mật khẩu",
  wjmmtitle: "Quên mật khẩu",
  fhsytitle: "Trở về trang chủ",
  grzctitle: "Đăng ký cá nhân",
  jgzctitle: "Đăng ký tổ chức",
  jjxzgzytitle: "Sắp tải tài nguyên, có tiếp tục không?",
  sjhmtitle: "Số điện thoại di động",
  szmmtitle: "Đặt mật khẩu",
  qszmmtitle: "Vui lòng đặt mật khẩu",
  qrmmtitle: "Xác nhận mật khẩu",
  xmmtitle: "Xác nhận mật khẩu",
  szdqtitle: "Các địa phương nơi",
  qtxyzmtitle: "Vui lòng điền CAPTCHA",
  qxzzztitle: "Vui lòng chọn",
  yzmtitle: "CAPTCHA",
  ljzctitle: "Đăng ký ngay",
  qxzsftitle: "Vui lòng chọn tỉnh",
  qxzcstitle: "Vui lòng chọn thành phố",
  qxzqytitle: "Vui lòng chọn khu vực",
  lcsrmmbyytitle: "Hai lần nhập mật khẩu không giống nhau",
  hqyzmtitle: "Nhận CAPTCHA",
  zccgtitle: "Đăng ký thành công",
  jglxtitle: "Loại tổ chức (chọn tối đa 3)",
  qyttitle: "Các doanh nghiệp",
  kyttitle: "Nghiên cứu khoa học",
  dxttitle: "Các trường đại học",
  fwttitle: "Dịch vụ",
  tzttitle: "Đầu tư",
  tjttitle: "Trình bày \n",
  jgmctitle: "Tên cơ quan",
  qsrjgmctitle: "Vui lòng nhập tên tổ chức",
  qzsxzyzjglxtitle: "Vui lòng chọn Chọn ít nhất một loại tổ chức",
  zdxzsgjglxtitle: "Chọn tối đa ba loại tổ chức",
  cdw11gzftitle: "Chiều dài là 11 ký tự",
  zzczmmtitle: "Đang đặt lại mật khẩu, có tiếp tục không?",
  sbtz_title: "Thông báo kê khai",
  wsj_title: "Không có dữ liệu",
  fbbm_title: "Bộ phận phát hành",
  lx_title: "Các loại",
  zt_title: "Tình trạng",
  kssj_title: "Thời gian bắt đầu",
  jzsj_title: "Thời gian đóng cửa",
  more: "Hơn thế nữa",
  fb: "Phát hành",
  zhi: "至",
  djsb: "Click vào tờ khai",
  ljdh_title: "Chuyển hướng nhanh",
  jscgname_table: "Tên thành tựu công nghệ",
  ssdwname_table: "Các đơn vị trực thuộc",
  szdwname_table: "Các đơn vị trực thuộc",
  hylbname_table: "Danh mục ngành nghề",
  cgspname_table: "Mức độ kết quả",
  csdname_table: "Sự trưởng thành",
  fbsjname_table: "Thời gian phát hành",
  kjxq_title: "Nhu cầu khoa học công nghệ",
  xglj_title: "Liên kết liên quan",
  xl_title: "Trình độ học vấn",
  dw_title: "Các đơn vị",
  zjgdxx_title: "Chuyên gia Thêm thông tin",
  zw_title: "Chức vụ",
  hy_title: "Ngành công nghiệp",
  hylb_title: "Danh mục ngành nghề",
  szd_title: "Địa phương",
  dwmc_title: "Tên đơn vị",
  xmmc_title: "Tên dự án",
  fbsj_title: "Thời gian phát hành",
  ly_title: "Các lĩnh vực",
  lll_title: "Số lượt xem",
  zrfs_title: "Phương thức chuyển nhượng",
  cgsp_title: "Mức độ kết quả",
  sfcj_title: "Có giao dịch hay không",
  lxr_title: "Liên hệ",
  tyshxydmtitle: "Mã tín dụng xã hội thống nhất",
  yxqtitle: "Thời hạn sử dụng",
  ztitle: "至",
  xmlytitle: "Nguồn dự án",
  qxzlytitle: "Vui lòng chọn nguồn dự án",
  xmlkzxstitle: "Hình thức thực hiện dự án",
  qsrxmmcname_table: "Vui lòng nhập tên dự án",
  qsryjkfrs_table: "Vui lòng nhập số lượng nghiên cứu và phát triển",
  qxzjzjd_table: "Vui lòng chọn giai đoạn tiến độ",
  qxzxmlkzxstitle: "Vui lòng chọn hình thức thực hiện dự án",
  xmdncgxstitle: "Hình thức kết quả thực hiện dự án trong năm",
  qxzxmdncgxstitle: "Vui lòng chọn hình thức kết quả của dự án trong năm",
  xmjsjjmbtitle: "Mục tiêu kinh tế kỹ thuật của dự án",
  qxzxmjsjjmbtitle: "Vui lòng chọn mục tiêu kinh tế kỹ thuật của dự án",
  xmqsrqtitle: "Ngày bắt đầu dự án",
  qxzxmqsrqtitle: "Vui lòng chọn ngày bắt đầu dự án",
  xmwcrqtitle: "Ngày hoàn thành dự án",
  qxzxmwcrqtitle: "Vui lòng chọn ngày hoàn thành dự án",
  knxmdlsczyjzjdtitle:
    "Dự án Tết Nguyên đán đang ở giai đoạn tiến độ chính trong năm",
  qxzknxmdlsczyjzjdtitle:
    "Vui lòng chọn giai đoạn tiến độ chính trong năm dự án Giao thừa",
  xmyjkfrytitle: "Nhân viên nghiên cứu và phát triển dự án (người dân)",
  qsrxmyjkfrytitle: "Vui lòng nhập vào nhà phát triển nghiên cứu dự án (người)",
  xmrysjgzsjtitle:
    "Thời gian làm việc thực tế của cán bộ dự án (người trong tháng)",
  qsrxmrysjgzsjtitle: "Vui lòng nhập thời gian làm việc của nhân viên",
  xmjfzctitle: "Chi phí cho dự án (nghìn đô la)",
  qsrxmjfzctitle: "Vui lòng nhập chi phí dự án",
  dwfzrtitle: "Thủ trưởng đơn vị",
  tbrtitle: "Người điền biểu mẫu",
  tbrqtitle: "Ngày điền thông tin",
  qxztbrqtitle: "Vui lòng chọn ngày điền",
  tjbtn: "Nộp hồ sơ",
  zbmctitle: "Tên các chỉ số",
  jldwtitle: "Đơn vị đo lường",
  dmtitle: "Mã số",
  sltitle: "Số lượng",
  jiatitle: "甲",
  yititle: "乙",
  bingtitle: "丙",
  yjkfryqk1title: "I. TÌNH HÌNH NGHIÊN CỨU PHÁT TRIỂN",
  yjkfryhj1title: "Các nhà phát triển nghiên cứu tổng hợp",
  qzglhfwry1title: "Trong đó: Đội ngũ cán bộ quản lý, phục vụ",
  qzglx1title: "Trong đó: Nữ",
  qzqzry1title: "Trong đó: cán bộ chuyên trách",
  qzbkbyjysry1title: "Trong đó: Người tốt nghiệp cử nhân trở lên",
  qzwpry1title: "Trong đó: Nhân sự ngoài",
  r1title: "人",
  yjkffyqk2title: "Thứ hai, tình hình chi phí nghiên cứu, phát triển",
  yjkffyhj2title: "Tổng chi phí nghiên cứu và phát triển",
  ryrgfy2title: "1. Chi phí lao động cho nhân viên",
  zjtrfy2title: "2. Chi phí đầu vào trực tiếp",
  zjfyycqdtfy2title: "3. Chi phí khấu hao và chi phí chờ đợi dài hạn",
  wxzctxfy2title: "4. Chi phí khấu hao tài sản vô hình",
  sjfy2title: "5. Chi phí thiết kế",
  zbtsfyysyfy2title: "6. Chi phí vận hành thiết bị và chi phí thử nghiệm",
  wttsfyysyfy2title: "7. Chi ủy thác nghiên cứu, phát triển bên ngoài",
  wtjlyjjg2title: "ủy thác cho các viện nghiên cứu trong nước",
  wtjlgdxx2title: "ủy thác cho các trường đại học, cao đẳng trong nước",
  wtjlqy2title: "ủy thác cho các doanh nghiệp trong nước",
  wtjwjg2title: "Tổ chức nước ngoài ủy thác",
  qtfy2title: "8. Các chi phí khác",
  qy2title: "Hàng nghìn nhân dân tệ",
  yjkfzcqk3title: "3. Nghiên cứu tình hình khai thác tài sản",
  dnxcyyyjkfdgdzc3title:
    "Hình thành tài sản cố định phục vụ nghiên cứu, phát triển trong năm",
  qizyqhsb3title: "Trong đó: Dụng cụ, thiết bị",
  yjkfzczjly4title: "Bốn là, nguồn kinh phí chi cho nghiên cứu, phát triển",
  lzqyzc4title: "1. Từ nguồn vốn tự có của doanh nghiệp",
  lzzfbm4title: "2. Từ các cơ quan chính phủ",
  lzyhdk4title: "3. Từ nguồn vốn vay ngân hàng",
  lzfxtz4title: "4. Từ vốn đầu tư mạo hiểm",
  lzqtqd4title: "5. Từ các kênh khác",
  xgzctsqk5title: "5. Việc thực hiện các chính sách liên quan",
  sbjjkc5title:
    "Khai bổ sung các khoản chi nghiên cứu, phát triển để khấu trừ, miễn, giảm thuế",
  jjkcjmsje5title: "Bổ sung số tiền được khấu trừ, miễn, giảm thuế",
  gxjsqyjmsje5title: "Số tiền miễn, giảm thuế cho doanh nghiệp công nghệ cao",
  qybyjkfjg6title:
    "Sáu là, tình hình các tổ chức nghiên cứu, phát triển (trong lãnh thổ) do doanh",
  qmjgsg6title: "Số cơ sở cuối kỳ",
  jgyjkfry6title: "Nhà phát triển nghiên cứu tổ chức",
  qzbsby6title: "Trong đó: TS tốt nghiệp",
  ssby6title: "Tốt nghiệp thạc sĩ",
  jgyjkffy6title: "Chi nghiên cứu, phát triển của các cơ quan",
  qmyqhsbyj6title: "Giá gốc các dụng cụ, thiết bị cuối kỳ",
  yjkfccjxgqk7title:
    "7. Kết quả nghiên cứu, phát triển đầu ra và tình hình liên quan",
  zlqk7title: "(1) Tình hình sáng chế",
  dlzlsqs7title: "Số đơn xin cấp bằng sáng chế trong năm",
  qzfmzl7title: "Trong đó: Bằng độc quyền sáng chế",
  qmyxfmzls7title: "Số bằng sáng chế có hiệu lực vào cuối kỳ",
  qzybss7title: "Trong đó: đã thực hiện",
  zlsyqzrjxks7title:
    "Việc chuyển giao quyền sở hữu bằng sáng chế và số lượng giấy phép",
  zlsyqxksr7title:
    "Thu nhập từ chuyển nhượng quyền sở hữu bằng sáng chế và cấp phép",
  xcpqk7title: "(2) Tình hình sản phẩm mới",
  xcpxssr7title: "Doanh thu từ bán sản phẩm mới",
  qzck7title: "Trong đó: xuất khẩu",
  qtqk7title: "(3) Các trường hợp khác",
  qmzcsb7title: "Có nhãn hiệu đã đăng ký vào cuối kỳ",
  fbkjlw7title: "Công bố các bài báo khoa học và công nghệ",
  xcgjhhybz7title: "Hình thành các tiêu chuẩn quốc gia hoặc công nghiệp",
  jian7title: "件",
  pian7title: "篇",
  xiang7title: "项",
  qtxgqk8title: "8. Các tình huống khác có liên quan",
  jsgchjshqqk8title: "(1) Tình hình chuyển đổi công nghệ và tiếp thu công nghệ",
  jsgzjfzc8title: "Chi kinh phí chuyển đổi công nghệ",
  gmjljsjfzc8title: "Chi cho kinh phí mua công nghệ trong nước",
  yjjwjsjfzc8title: "Chi kinh phí giới thiệu công nghệ nước ngoài",
  yjjwjsdxhxs8title:
    "Chi tiêu cho tiêu hóa và hấp thụ các công nghệ nước ngoài được giới thiệu",
  qybyjkfjg8title:
    "(2) Tình hình các tổ chức nghiên cứu, phát triển (ở nước ngoài) do doanh",
  qmqyzjwsldyjkfjgs8title:
    "Số tổ chức nghiên cứu, phát triển do doanh nghiệp thành lập ở nước ngoài vào cuối nhiệm",
  ge8title: "个",
  dhtabletitledh:
    "Bảng thu thập nhu cầu thành tựu khoa học công nghệ của nền tảng chuyển giao công nghệ Dehong",
  bhdh: "Số lượng",
  txsjdh: "Thời gian hoàn thành",
  dwxxdh: "I. THÔNG TIN ĐƠN VỊ",
  dwmcdh: "Tên đơn vị",
  fddbrdh: "Người đại diện theo pháp luật",
  lxyxdh: "Email liên hệ",
  lxrdh: "Liên hệ",
  lxdhdh: "Số điện thoại liên hệ",
  dwrygkdh: "Tổng quan về nhân sự của đơn vị",
  zzrydh: "Những cán bộ làm công tác",
  dzysjsrcdh: "Nhân viên kỹ thuật từ cao đẳng trở lên",
  qzbsdh: "Trong đó TS",
  zscqyydh: "Quyền sở hữu trí tuệ/Nguồn",
  wgsjzldh: "Bằng sáng chế thiết kế",
  syxzldh: "Bằng sáng chế loại tiện ích",
  fmzldh: "Bằng sáng chế phát minh",
  rzqdh: "Quyền tác giả mềm",
  gmdh: "Mua hàng",
  zzyfdh: "Nghiên cứu và phát triển độc lập",
  hzkfdh: "Hợp tác phát triển",
  qtdh: "Khác",
  sfwgxjsqydh: "Là doanh nghiệp công nghệ cao hay không",
  shidh: "是",
  szsbh: "Là số giấy chứng nhận",
  foubh: "否",
  sfwkjxzxqybh:
    "Là doanh nghiệp vừa và nhỏ dựa trên nền tảng khoa học công nghệ",
  qtlxscztbh: "Các loại thị trường khác",
  sfjscxcyptbh: "Có nên xây dựng nền tảng khởi nghiệp đổi mới sáng tạo",
  zckjbh: "Không gian sáng tạo",
  xctdbh: "Đất trời mới sáng tạo",
  qyjszxbh: "Trung tâm công nghệ doanh nghiệp",
  gcyjzxbh: "Trung tâm Nghiên cứu Kỹ thuật",
  jscxzxbh: "Trung tâm đổi mới công nghệ",
  kjqyfhqbh: "Vườn ươm doanh nghiệp khoa học và công nghệ",
  snjjyxxbh: "II. THÔNG TIN HOẠT ĐỘNG CỦA NĂM TRƯỚC (triệu USD)",
  zzcbh: "Tổng tài sản",
  jzcbh: "Tài sản ròng \n",
  yysrbh: "营业收入",
  zyftrbh: "Đầu tư cho nghiên cứu và phát triển",
  zyysrbh: "Doanh thu từ hoạt động kinh doanh chính \n",
  jlrbh: "Lợi nhuận ròng",
  yftrbh: "Đầu tư cho nghiên cứu và phát triển",
  ydwswybh: "Từ 1-5 triệu nhân dân tệ",
  wsdwbwybh: "50-5 triệu nhân dân tệ",
  wbdwbwybh: "500-10 triệu nhân dân tệ",
  yqwwbwybh: "Hơn 10 triệu nhân dân tệ",
  yftrzyysrbblbh:
    "Tỷ lệ đầu tư cho nghiên cứu và phát triển trên doanh thu hoạt động",
  bfz1bh: "1%",
  bfz1d3bh: "1%-3%",
  bfz3d5bh: "3%-5%",
  bfz5d10bh: "5%-10%",
  bfz10bh: "Hơn 10%",
  zyywjgxjscpbh:
    "Ngành nghề kinh doanh chính và sản phẩm (dịch vụ) công nghệ cao",
  sfhkjzxjjbh: "Cho dù được cấp kinh phí đặc biệt cho khoa học và công nghệ",
  gjsbh: "Quốc gia/Tỉnh",
  sfyhjgtzbh: "Cho dù đã được các cơ quan đầu tư",
  ljbh: "Tích lũy",
  jgtzjewybh: "[Số tiền đầu tư tổ chức] triệu nhân dân tệ",
  cpjbqkbh: "3. Thông tin cơ bản về sản phẩm",
  cpmcbh: "Tên sản phẩm",
  ssjslybh: "Các lĩnh vực công nghệ thuộc về",
  dzxxbh: "Thông tin điện tử",
  rgzlbh: "Trí tuệ nhân tạo",
  zinzzgdzbbh: "Sản xuất thiết bị cao cấp thông minh",
  qtbh: "Khác",
  xdlybh: "Nông nghiệp hiện đại",
  gjsfubh: "Dịch vụ kỹ thuật cao",
  xnyjjnjsbh: "Năng lượng mới và công nghệ tiết kiệm năng lượng",
  hkhtjsbh: "Công nghệ hàng không vũ trụ",
  zyyhjjsbh: "Công nghệ Tài nguyên và Môi trường",
  xclbh: "Vật liệu mới",
  yyhybh: "Ngành công nghiệp ứng dụng",
  xtrhbh: "Thông tin, Truyền thông, Phần mềm, Internet",
  llmybh: "Nông lâm nghiệp, chăn nuôi và thủy sản",
  wshshgzbh: "Công tác y tế và xã hội",
  jtyscchyzbh: "Giao thông vận tải, kho bãi và bưu chính",
  ckybh: "Ngành công nghiệp khai khoáng",
  szjzbh: "Tòa nhà đô thị",
  slhjhgyssglbh: "Quản lý nước, môi trường và tiện ích",
  kxyjhjsfwybh: "Nghiên cứu khoa học và dịch vụ công nghệ",
  zcybh: "Công nghiệp chế tạo",
  dlrlrqjscshgybh: "Sản xuất và cung cấp điện, nhiệt, khí và nước",
  gjzzbh: "Các tổ chức quốc tế",
  hgbh: "Hóa chất",
  ggglshbzhshzzbh: "Hành chính công, an sinh xã hội và các tổ chức xã hội",
  zphswfwbh: "Dịch vụ cho thuê và kinh doanh",
  jybh: "Giáo dục",
  yysxbh: "Thuộc tính ứng dụng",
  yysxcxbh: "Đổi mới nguyên bản",
  jseckfbh: "Phát triển công nghệ thứ cấp",
  jsyjzcxbh: "Giới thiệu công nghệ và đổi mới",
  zhcxbh: "Đổi mới kết hợp",
  yfjdbh: "Giai đoạn nghiên cứu và phát triển",
  yfzbh: "Trong nghiên cứu và phát triển",
  xsjdbh: "Giai đoạn thử nghiệm nhỏ",
  zsbh: "Thí điểm thí điểm",
  ypbh: "Mẫu thử",
  xplbh: "Kích thước lô nhỏ",
  kcyhbh: "Có thể công nghiệp hóa",
  kjcgxmxqbbh: "Thứ tư, nhu cầu của các dự án thành tựu khoa học và công nghệ",
  fwxqbh: "Nhu cầu phục vụ",
  jsrcyjbh: "Giới thiệu nhân sự kỹ thuật",
  cgzhyxmhzbh: "Chuyển đổi kết quả và hợp tác với dự án",
  zljskfgmbh: "Phát triển/Mua công nghệ bằng sáng chế",
  xcpyfbh: "Nghiên cứu và phát triển sản phẩm mới",
  qypxbh: "Đào tạo cho doanh nghiệp",
  scglbh: "Quản lý sản xuất",
  yxchbh: "Lập kế hoạch tiếp thị",
  zyjnbh: "Kỹ năng nghề nghiệp",
  xljybh: "Giáo dục bằng cấp",
  qyzxbh: "Chuyển đổi doanh nghiệp",
  xyjsjcqkbh: "Hiện trạng hạ tầng kỹ thuật",
  xyjjdxqwtbh: "Các vấn đề nhu cầu cần giải quyết/nhu cầu kỹ thuật",
  zljsbh: "Công nghệ được cấp bằng sáng chế",
  xmhzykfbh: "Hợp tác và phát triển dự án",
  xmsbbh: "Tờ khai dự án",
  rcyjbh: "Giới thiệu nhân tài",
  xqjsbh: "Giới thiệu về nhu cầu",
  qwhzfsbh: "Kỳ vọng thời gian hợp tác",
  gtkfbh: "Cùng phát triển",
  gmfwbh: "Dịch vụ mua hàng",
  slzdbh: "Thiết lập trang web",
  qwhzsjbh: "Kỳ vọng thời gian hợp tác",
  dq1dsbh: "Ngắn hạn (1-3 năm)",
  cq5nysbh: "Dài hạn (trên 5 năm)",
  jjxqwzbh: "Giải quyết nhu cầu đến nay",
  yqtrbh: "Dự kiến đầu vào",
  swyyxbh: "Dưới 100.000 nhân dân tệ",
  sdsswybh: "10-300 nghìn nhân dân tệ",
  ssdwsbh: "30-500 nghìn nhân dân tệ",
  wsdybwbh: "50-1 triệu nhân dân tệ",
  ybdybwbh: "100-1,5 triệu nhân dân tệ",
  ybwdebbh: "150-2 triệu nhân dân tệ",
  ebdsbbh: "200-3 triệu nhân dân tệ",
  wbysbh: "Hơn 5 triệu nhân dân tệ",
  qtysfwbh: "Khác (Phạm vi ngân sách)",
  glxxbh: "V. THÔNG TIN QUẢN LÝ",
  tygkxqxxbh: "Đồng ý công khai thông tin về nhu cầu",
  bfgkbh: "Một phần công khai",
  tyxyzjfwbh: "Đồng ý cần dịch vụ chuyên gia",
  tycydjjfadsxpjbh: "đồng ý tham gia đánh giá sàng lọc các giải pháp",
  dcxyjhzjlhddjyhxqbh:
    "Nghị, nhu cầu về các hoạt động hợp tác, trao đổi giữa các ngành công nghiệp, học",
  tjbh: "Nộp hồ sơ",
  dybh: "In ấn",
  qtxbhbh: "Vui lòng điền số",
  qtxdwmcbh: "Vui lòng điền tên đơn vị",
  qtxlxrbh: "Vui lòng điền đầy đủ thông tin liên hệ",
  qtxlxdhbh: "Vui lòng điền vào số điện thoại liên hệ",
  zztjztsjbh: "Đang gửi dữ liệu này, có tiếp tục không?",
  tjcgbh: "Nộp hồ sơ thành công",
  nccenter: "Biệt danh",
  bindcenter: "Sự ràng buộc",
  sqsmrzcenter: "Đăng ký chứng nhận tên thật",
  smrzcenter: "Chứng nhận tên thật",
  sqzjrzcenter: "Chứng nhận chuyên gia",
  sqjgrzcenter: "Đơn xin xác nhận của tổ chức",
  jgrzcenter: "Chứng nhận của tổ chức",
  scdlcenter: "Đăng nhập lần cuối",
  xqlcenter: "Nhu cầu",
  cgzscenter: "Triển lãm thành tựu",
  zhaqcenter: "Bảo mật tài khoản",
  dlmmcenter: "Mật khẩu đăng nhập",
  yybhzhxxcenter:
    "Được sử dụng để bảo vệ thông tin tài khoản và bảo mật đăng nhập",
  ggcenter: "Thay đổi",
  aqsjcenter: "Điện thoại di động an toàn",
  yyzktfxcenter:
    "đã được xác minh, có thể đăng nhập hoặc lấy lại mật khẩu bằng điện thoại di",
  xgdlmmcenter: "Sửa đổi mật khẩu đăng nhập",
  dqmmcenter: "Mật khẩu hiện tại",
  xmmcenter: "Mật khẩu mới",
  qrmmcenter: "Xác nhận mật khẩu",
  mmxgcgcenter: "Thay đổi mật khẩu thành công!",
  tjcenter: "Nộp hồ sơ",
  qxcenter: "Hủy bỏ",
  qdcenter: "Quyết tâm",
  bdsjhcenter: "Ràng buộc điện thoại mới",
  xsjhmcenter: "Số điện thoại di động mới",
  yzmcenter: "CAPTCHA",
  hqcenter: "Nhận CAPTCHA",
  qsrsjhmvocenter: "Vui lòng nhập số điện thoại di động",
  cdwsygzfvocenter: "Chiều dài là 11 ký tự",
  qsryzmvocenter: "Vui lòng nhập mã xác thực",
  qsrmmvocenter: "Vui lòng nhập mật khẩu",
  qzcsrmmmvocenter: "Vui lòng nhập lại mật khẩu",
  lcsrmmbyzvocenter: "Hai lần nhập mật khẩu không nhất quán!",
  zzxgmmvocenter: "Đang thay đổi mật khẩu, có tiếp tục không?",
  cgvocenter: "Thành công",
  zzxgsjhmvocenter: "Đang thay đổi số điện thoại, có tiếp tục không?",
  tsvocenter: "Lời khuyên",
  dlsjhcenter: "Đăng nhập vào số điện thoại di động",
  ybdcenter: "Đã được ràng buộc",
  wbdcenter: "Đã được ràng buộc",
  ghbdcenter: "Thay thế ràng buộc",
  qbdcenter: "Đi đến ràng buộc",
  xgmmcenter: "Thay đổi mật khẩu",
  jbxxcenter: "Thông tin cơ bản",
  jbxxdesccenter:
    "Cung cấp thông tin nhận dạng chính xác, góp phần xác thực, nâng cao hình ảnh tín",
  zsxmccenter: "Tên thật",
  zsxmcvomcenter: "Vui lòng nhập tên thật",
  xbvomcenter: "Vui lòng chọn giới tính",
  cslyvomcenter: "Vui lòng chọn sinh nhật",
  qsrzssfzhvomcenter: "Vui lòng nhập số ID thật",
  qscsfzzmvomcenter: "Vui lòng tải lên mặt trước của thẻ ID",
  qscsfzbmvomcenter: "Vui lòng tải lên mặt sau của thẻ ID",
  zzthsmxxcenter: "Đang gửi thông tin tên thật, có tiếp tục không?",
  zzbcgrxxcenter: "Đang lưu thông tin cá nhân, có tiếp tục không?",
  xbcenter: "Giới tính",
  nancenter: "男",
  nvcenter: "女",
  wzcenter: "Không rõ",
  cslycenter: "Năm và tháng sinh",
  sfzhcenter: "Số CMND",
  sfzzmcenter: "Mặt trước giấy tờ tùy thân",
  sfzzmdesccenter:
    "Rõ ràng ID cá nhân trước và sau ảnh gốc Yêu cầu hình ảnh định dạng JPG, GIF, PNG dưới 200KB",
  sfzfmcenter: "Mặt trái của giấy tờ tùy thân",
  tjsmxxcenter: "Gửi thông tin tên thật",
  grzlcenter: "Hồ sơ cá nhân",
  sctxcenter: "Tải ảnh đại diện",
  sctxdesccenter: "Đề nghị tải lên một inch ảnh giấy tờ",
  yhlccenter: "Biệt danh người dùng",
  szdqcenter: "Các địa phương nơi",
  qxzcenter: "Vui lòng chọn",
  yxcenter: "Hộp thư điện tử",
  yxdesccenter: "Nhập vào hộp thư thông thường của bạn",
  jzdzcenter: "Địa chỉ cư trú",
  zycenter: "Nghề nghiệp",
  zydesccenter: "Ngành, nghề thực hiện",
  qqcenter: "QQ",
  qqdesccenter:
    "Vui lòng nhập QQ thực sự để tạo điều kiện cho dịch vụ khách hàng giao tiếp với bạn",
  bccenter: "Tiết kiệm",
  qsrnccenter: "Vui lòng nhập nickname",
  qsryxcenter: "Vui lòng nhập email",
  qsrxjzdcenter: "Vui lòng nhập địa chỉ cư trú",
  qsrzycenter: "Vui lòng nhập nghề nghiệp",
  qsrqqcenter: "Vui lòng nhập QQ",
  qsctxcenter: "Vui lòng tải lên ảnh đại diện",
  qxzsfcenter: "Vui lòng chọn tỉnh",
  qxzcscenter: "Vui lòng chọn thành phố",
  qxzqycenter: "Vui lòng chọn khu vực",
  jgrzdesccenter: "Cung cấp thông tin chính xác, góp phần xác minh thêm.",
  qymcccenter: "Tên doanh nghiệp",
  qydzcenter: "Địa chỉ doanh nghiệp",
  fddbrcenter: "Người đại diện theo pháp luật",
  tyshxydmcenter: "Mã tín dụng xã hội thống nhất",
  yyzzcenter: "Giấy phép kinh doanh",
  yyzzdesccenter:
    "Yêu cầu ảnh giấy phép kinh doanh thương mại rõ ràng Hình ảnh định dạng JPG, GIF, PNG dưới 200KB",
  tgrzxxcenter: "Cung cấp thông tin chứng nhận",
  qyyjkfxmqkcenter: "Tình hình nghiên cứu, xây dựng đề án của các doanh nghiệp",
  qbcenter: "Tất cả",
  wshtgcenter: "Không kiểm toán và phê duyệt",
  yshcenter: "Đã được kiểm toán",
  dshcenter: "Đang chờ xem xét",
  sscenter: "Tìm kiếm",
  qsrgjzcenter: "Vui lòng nhập từ khóa",
  xmmccenter: "Tên dự án",
  xmlycenter: "Nguồn dự án",
  kzxscenter: "Các hình thức thực hiện",
  kssjcenter: "Thời gian bắt đầu",
  jssjcenter: "Thời gian kết thúc",
  fzrcenter: "Người chịu trách nhiệm",
  czcenter: "Hoạt động",
  qyyjkfhdcenter: "Hoạt động nghiên cứu và phát triển của doanh nghiệp",
  dwmccenter: "Tên đơn vị",
  dwfzrcenter: "Thủ trưởng đơn vị",
  tbrcenter: "Người điền biểu mẫu",
  yxqkscenter: "Thời gian bắt đầu hiệu lực",
  yxqjscenter: "Thời gian kết thúc hiệu lực",
  kjcgxqzjcenter: "Yêu cầu thu thập thành tựu khoa học và công nghệ",
  cpmccenter: "Tên sản phẩm",
  txsjcenter: "Thời gian hoàn thành",
  fbkjcgcgxxcenter:
    "Công bố thông tin về thành tựu khoa học và công nghệ-thành tựu",
  fbkjcgcgdesccenter:
    "đầy đủ thông tin càng cao, càng dễ thu hút sự chú ý của khách hàng mục tiêu",
  fbkjcgjsmccenter: "Tên công nghệ",
  hylbcenter: "Danh mục ngành nghề",
  zscqztcenter: "Tình trạng sở hữu trí tuệ",
  zlmccenter: "Tên bằng sáng chế",
  zlhcenter: "Bằng sáng chế số",
  jjcenter: "Giới thiệu tóm tắt",
  sfcjcenter: "Có giao dịch hay không",
  zrfscenter: "Phương thức chuyển nhượng",
  cgszdwcenter: "Các đơn vị có kết quả",
  dwlxcenter: "Loại đơn vị",
  szdwmccenter: "Tên đơn vị đang hoạt động",
  cgszdcenter: "Vị trí của kết quả",
  fbcgzszmclcenter: "Hiển thị kết quả xuất bản-tài liệu chứng minh",
  fbcgdesczmcenter:
    "Tải lên tài liệu chứng minh, tạo điều kiện cho khách hàng hiểu thêm về hiển thị kết",
  xxcycenter: "Các ngành công nghiệp mới nổi",
  kjcgpjspcenter: "Mức độ đánh giá thành tựu khoa học và công nghệ",
  csdcenter: "Sự trưởng thành",
  csdzmcenter: "Bằng chứng về sự trưởng thành",
  csdzmdesccenter:
    "Ảnh rõ ràng Yêu cầu ảnh định dạng jpg, gif, png dưới 100KB.",
  jstpcenter: "Hình ảnh kỹ thuật",
  jspgjzcenter: "Giá trị thẩm định công nghệ",
  lxrcenter: "Liên hệ",
  sjhmcenter: "Số điện thoại di động",
  zqsrsjhcenter: "Nhập đúng số điện thoại",
  zqsrlxrcenter: "Điền thông tin liên hệ chính xác",
  tjkjcgshcenter: "Trình kiểm toán kết quả khoa học và công nghệ",
  glcgcenter: "Kết quả quản lý",
  kjcgmccenter: "Tên thành tựu khoa học và công nghệ",
  zjzkzjxxcenter: "Expert Thinktank-Thông tin chuyên gia",
  zjzkzjxxdesccenter:
    "Cung cấp thông tin nhận dạng chính xác, góp phần xác thực, nâng cao hình ảnh tín",
  zgxlcenter: "Học vị cao nhất",
  gzdwcenter: "Đơn vị làm việc",
  zwcenter: "Chức vụ",
  lxdhcenter: "Số điện thoại liên hệ",
  fwalcenter: "Các trường hợp dịch vụ",
  tjshcenter: "Gửi bài kiểm tra",
  glzjzkxicenter: "Thông tin về Admin Expert Thinktank",
  zjmzcenter: "Tên chuyên gia",
  zjhycenter: "Ngành chuyên gia",
  tjrqcenter: "Thêm ngày",
  zcxlcenter: "Chức danh nghề nghiệp/Trình độ học vấn",
  fbjsxqxqxxcenter: "Công bố nhu cầu công nghệ-nhu cầu thông tin",
  fbjsxqdesccenter: "Điền thông tin chính xác, vượt qua kiểm toán thêm",
  xqfmccenter: "Bìa yêu cầu",
  xqfmdesccenter: "Bìa yêu cầu kỹ thuật hiển thị tại danh sách.",
  xqmccenter: "Tên yêu cầu",
  fbxqlxxxcenter: "Đăng yêu cầu công nghệ-Thông tin liên hệ",
  fbxqlxxxdesccenter:
    "Điền chính xác thông tin liên hệ để khách hàng có thể liên hệ với bạn",
  tjjsxqshcenter: "Gửi đánh giá nhu cầu kỹ thuật",
  glxqcenter: "Nhu cầu quản lý",
  xiugaicenter: "Sửa đổi",
  sanchucenter: "Gỡ bỏ",
  kjcglycenter: "Thông điệp từ thành tựu khoa học và công nghệ",
  lybkcenter: "Khối bảng tin",
  lydxcenter: "Đối tượng để lại tin nhắn",
  dhcscenter: "Số cuộc hội thoại",
  jsxqlycenter: "Yêu cầu kỹ thuật Leave a comment",
  kjcgcenter: "Thông điệp từ thành tựu khoa học và công nghệ",
  zjzklycenter: "Chuyên gia Thinktank Leave a comment",
  jzmmL: "Ghi nhớ mật khẩu",
  welogin:"Chào mừng đăng nhập \n",
  weother: "Đăng nhập khác \n",
  wepass: "Mã mật \n",
  weuser: "tên người dùng \n",
  };
