/**
 * 封装axios供html页面调用
 * 特点：最大程度适配所有浏览器
 * 作者：xiaoxin-ws
 * 请求拦截、相应拦截、错误统一处理，登录过期处理
 */
import axios from 'axios';
import qs from 'qs';
import store from '@/store'
import { MessageBox, Message } from 'element-ui'
import router from '@/router'
import { getToken, setToken, removeToken } from "@/utils/auth";
// var baseURL2 = "http://test.kjcg.test:800/api"
if (process.env.NODE_ENV === "production") {
  // console.log("生产");
  var baseURL2 = "/api"
  // baseURL3 = "http://www.dhjszy.com";
} else {
  // console.log("开发");
  var baseURL2 = "https://www.dhjszy.com/api"
  // var baseURL2 = "http://test.kjcg.test/api"
}
var server1 = axios.create({
  timeout: 120000,
  baseURL: baseURL2
})

// post请求头
// server1.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded;charset=UTF-8";

// 请求拦截器
server1.interceptors.request.use(
  function (config) {
    if (store.getters.token) {
      config.headers['Authorization'] = "Bearer " + store.getters.token
    }
    if (store.getters.cuLanguage) {
      config.headers['tgt'] = store.getters.cuLanguage
    }
    return config;
  },
  function (error) {
    // Message({
    //   message: res.message || 'Error',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.error(error);
  }
);

// 响应拦截器
server1.interceptors.response.use(
  function (response) {
    // console.log(response.data);
    // ELEMENT.Message.success("请求成功");
    //如果返回的状态码是200，可以正常拿到数据
    // 否则抛出错误
    if (response.status == 200) {
      if (response.data.code == 403) {
        // 未授权调取授权接口
      } else if (response.data.code == 401) {
        // 未登录跳转登录页
      } else {
        return Promise.resolve(response);
      }
    } else {
      Message({
        message:'Error code 500',
        type: 'error',
        duration: 2 * 1000
      })
      return Promise.reject(response);
    }
  },
  function (error) {
    // console.log(12312312,error);
    if(error.response.status==404){
      Message({
        message:'Error404',
        type: 'error',
        duration: 2 * 1000
      })
    }else if(error.response.status==401){
      // Message({
      //   message:'Error401',
      //   type: 'error',
      //   duration: 2 * 1000
      // })
      MessageBox.confirm('尚未登录,或登录过期,前往登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeToken();
        store.commit("setToken", "");
        router.push({path:'/login'});
      }).catch(()=>{
        removeToken();
        store.commit("setToken", "");
        router.push({path:'/'});
      })
    }else if(error.response.status==500){
      Message({
        message: error.response.data.message||'Error404',
        type: 'error',
        duration: 2 * 1000
      })
    }else{
      Message({
        message:error.response.data.message||'Error500',
        type: 'error',
        duration: 2 * 1000
      })
    }
    return Promise.reject(error.response);
  }
);

function get(url, params) {
  return new Promise(function (resolve, reject) {
    server1
      .get(url, {
        params: params,
      })
      .then(function (res) {
        resolve(res.data);
      })
      .catch(function (err) {
        reject(err.data);
      });
  });
}
function postFROM(url, data) {
  return new Promise(function (resolve, reject) {
    server1
      .post(url, qs.stringify(data),{
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      })
      .then(function (res) {
        resolve(res.data);
      })
      .catch(function (err) {
        reject(err.data);
      });
  });
}

function postJSON(url, data) {
  return new Promise(function (resolve, reject) {
    server1
      .post(url, data,{
        headers: { 'content-type': 'application/json' },
      })
      .then(function (res) {
        resolve(res.data);
      })
      .catch(function (err) {
        reject(err.data);
      });
  });
}

var request = {
  baseURL2,
  get,
  postFROM,
  postJSON,
};
export default request;