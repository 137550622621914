'use strict';

exports.__esModule = true;
exports.default = {
  el: {
    colorpicker: {
      "confirm": "အဆုံးအဖြတ်\n",
      "clear": "ချင်းပြည်နယ်\n"
  },
    datepicker: {
      "now": "ယခု အခိုက်\n",
      "today": "ယနေ့\n",
      "cancel": "ဖျက်သိမ်း\n",
      "clear": "ချင်းပြည်နယ်\n",
      "confirm": "အဆုံးအဖြတ်\n",
      "selectDate": "ရက် ရွေးချယ်ခြင်း\n",
      "selectTime": "အချိန် ရွေးချယ်ခြင်း\n",
      "startDate": "စတင်သည့်နေ့ရက်\n",
      "startTime": "စတင်ချိန်\n",
      "endDate": "အဆုံးသတ်သည့် နေ့ရက်များ\n",
      "endTime": "အဆုံးသတ်ချိန်\n",
      "prevYear": "ယခင်နှစ်\n",
      "nextYear": "နောက်တစ်နှစ်\n",
      "prevMonth": "ပြီးခဲ့တဲ့လ\n",
      "nextMonth": "နောက်လ\n",
      "year": "年",
      "month1": "၁ လ\n",
      "month2": "ဖေဖော်ဝါရီ\n",
      "month3": "၃ လ\n",
      "month4": "၄ လ\n",
      "month5": "မေလ\n",
      "month6": "၆ လ\n",
      "month7": "ဇူလိုင်လ\n",
      "month8": "၈ လ\n",
      "month9": "၉ လ\n",
      "month10": "၁၀ လ\n",
      "month11": "နိုဝင်ဘာလ\n",
      "month12": "၁၂ လ\n",
      // week: '周次',
      weeks: {
        sun: '日',
        mon: '一',
        tue: '二',
        wed: '三',
        thu: '四',
        fri: '五',
        sat: '六'
      },
      months: {
        "jan": "ဇန်နဝါရီလ\n",
        "feb": "ဖေဖော်ဝါရီ\n",
        "mar": "မတ်လ\n",
        "apr": "ဧပြီ\n",
        "may": "မေလ\n",
        "jun": "ဇွန်လ\n",
        "jul": "ဇူလိုင်\n",
        "aug": "ဩဂုတ်လ\n",
        "sep": "စက်တင်ဘာ\n",
        "oct": "အောက်တိုဘာ\n",
        "nov": "နိုဝင်ဘာ\n",
        "dec": "ဒီဇင်ဘာ\n"
    }
    },
    select: {
      "loading": "တင်ခြင်း\n",
      "noMatch": "ကိုက်ညီမှုမရှိသော အချက်အလက်\n",
      "noData": "အချက်အလက်မဲ့\n",
      "placeholder": "ရွေးချယ်ပေးပါ\n"
  },
    cascader: {
      "noMatch": "ကိုက်ညီမှုမရှိသော အချက်အလက်\n",
      "loading": "တင်ခြင်း\n",
      "placeholder": "ရွေးချယ်ပေးပါ\n",
      "noData": "ယာယီအချက်အလက်\n"
  },
    pagination: {
      "goto": "သွားရန်\n",
      "pagesize": "ဆောင်းပါး / စာမျက်နှာ\n",
      "total": "စုစုပေါင်း {total} ပုဒ်မများ\n",
      "pageClassifier": "页"
  },
    messagebox: {
      "title": "သိကောင်းစရာ\n",
      "confirm": "အဆုံးအဖြတ်\n",
      "cancel": "ဖျက်သိမ်း\n",
      "error": "ထည့်သွင်းထားသော အချက်အလက်များသည် တရားမ၀င်ပါ!\n"
  },
    upload: {
      "deleteTip": "delete key ကို နှိပ်၍ ဖျက်နိုင်သည်\n",
      "delete": "ဖျက်ခြင်း\n",
      "preview": "ပုံကိုကြည့်ပါ\n",
      "continue": "ဆက်တင်ရန်\n"
  },
    table: {
      "emptyText": "ယာယီအချက်အလက်\n",
      "confirmFilter": "စိစစ်\n",
      "resetFilter": "ပြန်လည်သတ်မှတ်ခြင်း\n",
      "clearFilter": "အားလုံး\n",
      "sumText": "စုစုပေါင်း\n"
  },
    tree: {
      "emptyText": "ယာယီအချက်အလက်\n"
  },
    transfer: {
      noMatch: "ကိုက်ညီမှုမရှိသော အချက်အလက်\n",
      noData: "အချက်အလက်မဲ့\n",
      titles: ['စာရင်း ၁\n', '"စာရင်း ၂\n"'],
      filterPlaceholder: "ရှာဖွေသည့် အကြောင်းအရာကို ရိုက်ထည့်ပေးပါ\n",
      noCheckedFormat: '共 {total} 项',
      hasCheckedFormat: 'ရွေးချယ်လိုက်ပြီ\n {checked}/{total} 项'
    },
    image: {
      "error": "မအောင်မြင်မှုကို တင်ခြင်း\n"
  },
    pageHeader: {
      "title": "ပြန်လာ\n"
  },
    popconfirm: {
      "confirmButtonText": "အဆုံးအဖြတ်\n",
      "cancelButtonText": "ဖျက်သိမ်း\n"
  },
    empty: {
      "description": "ယာယီအချက်အလက်\n"
  }
  }
};