export default {
  wecome: "ជំរាបសួរសូមស្វាគមន៍មកកាន់វេទិកាសេវាផ្ទេរបច្ចេកវិទ្យាតេជោសែន!\n",
  login: "ចូល\n",
  nick: "ឈ្មោះហៅក្រៅ\n",
  bind: "ចង.\n",
  search: "ស្វែងរក\n",
  qxzxyssdflsearch: "សូមជ្រើសរើសចំណាត់ថ្នាក់ដែលត្រូវការស្វែងរក\n",
  searchP: "សូមជ្រើសរើស\n",
  searchI: "សូមបញ្ចូលខ្លឹមសារ\n",
  register: "ការចុះឈ្មោះ\n",
  center: "មជ្ឈមណ្ឌលផ្ទាល់ខ្លួន\n",
  zcxwtitle: "ព័ត៌មានគោលនយោបាយ\n",
  tzgotitle: "សេចក្តីប្រកាសជូនដំណឹង\n",
  kjhztitle: "កិច្ចសហប្រតិបត្តិការបច្ចេកវិទ្យា\n",
  kjcgtitle: "លទ្ធផលបច្ចេកវិទ្យា\n",
  kjjrtitle: "ហិរញ្ញវត្ថុបច្ចេកវិទ្យា\n",
  zjzktitle: "អ្នកជំនាញការ\n",
  zjzkftitle: "ការពិនិត្យវិភាគរបស់អ្នកជំនាញ\n",
  kjcgftitle: "ការពិនិត្យលទ្ធផលបច្ចេកវិទ្យា\n",
  jsxqtitle: "តម្រូវការបច្ចេកវិទ្យា\n",
  jsxqftitle: "ការពិនិត្យតម្រូវការបច្ចេកទេស\n",
  jslinytitle: "វិស័យបច្ចេកវិទ្យា\n",
  fbxqtitle: "ចេញផ្សាយតម្រូវការ\n",
  wxxztitle: "ទាញយកឯកសារ\n",
  zbdwname: "អង្គភាពរៀបចំ\n",
  dhname: "ទូរស័ព្ទ\n",
  jszcname: "ការគាំទ្រផ្នែកបច្ចេកទេស\n",
  dzname: "អាស័យដ្ឋាន\n",
  syname: "ទំព័រដើម\n",
  fbcg: "ចេញផ្សាយលទ្ធផល\n",
  xmsb: "ការគ្រប់គ្រងគម្រោង\n",
  sbtz: "សេចក្តីជូនដំណឹងស្តីពីការដាក់លិខិតប្រកាស\n",
  all: "ទាំងអស់\n",
  in_progress: "ដំណើរការកណ្ដាល\n",
  stop: "បានបិទ\n",
  notopen: "មិនបានចាប់ផ្តើម\n",
  expired: "បានផុតកំណត់\n",
  kjtj_title: "ស្ថិតិវិទ្យាសាស្ត្រនិងបច្ចេកវិទ្យា\n",
  lilantitle: "រុករក\n",
  layuantitle: "ប្រភព\n",
  lilanltitle: "ចំនួនមើល\n",
  timetitle: "ពេលវេលា\n",
  zwtitle:
    "អត្ថបទ​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​​\n",
  fbsjtitle: "ម៉ោងចេញផ្សាយ\n",
  hyfltitle: "ចំណាត់ថ្នាក់ឧស្សាហកម្ម\n",
  zjjjtitle: "ប្រវត្តិរូបអ្នកជំនាញ\n",
  lxzjtitle: "ទាក់ទងអ្នកជំនាញ\n",
  xlxwtitle: "ការសិក្សា/សញ្ញាបត្រ\n",
  zczwtitle: "មុខងារ\n",
  xmtitle: "ឈ្មោះ\n",
  fjtitle: "ឯកសារភ្ជាប់\n",
  xqtitle: "ព័ត៌មានលម្អិត\n",
  hylbtitle: "ប្រភេទឧស្សាហកម្ម\n",
  jssptitle: "កម្រិតបច្ចេកទេស\n",
  lxdhtitle: "ទាក់ទងទូរស័ព្ទលេខ\n",
  rcktitle: "មនុស្សមើល\n",
  ljcktitle: "សរុបមើល\n",
  zrfstitle: "វិធីផ្ទេរ\n",
  zscqzttitle: "ស្ថានភាពកម្មសិទ្ធិបញ្ញា\n",
  zlmcttitle: "ឈ្មោះប៉ាតង់\n",
  cgszdwttitle: "អង្គភាពដែលមានលទ្ធផល\n",
  cgjjttitle: "ប្រវត្តិរូបសង្ខេបលទ្ធផល\n",
  zlhttitle: "លេខប៉ាតង់\n",
  yxtitle: "ប្រអប់សំបុត្រ\n",
  xqjjtitle: "ប្រវត្តិរូបតម្រូវការ\n",
  lxtitle: "ទាក់ទង\n",
  my: "សំរាយមុខ\n",
  jg: "តម្លៃ\n",
  mslxtitle: "ទាក់ទងមកភ្លាមៗ\n",
  swdlsfjx: "មិនទាន់ចុះឈ្មោះចូលទេទៅចុះឈ្មោះចូលហើយតើនៅតែបន្តទៀតទេ?\n",
  ts: "ព័ត៌មានជំនួយ\n",
  ok: "កំណត់\n",
  successs: "ជោគជ័យ\n",
  cancel: "លុបចោល\n",
  qsrnxsdh: "សូមបញ្ចូលនូវអ្វីដែលអ្នកចង់និយាយ\n",
  lxcg: "ទាក់ទងជោគជ័យ!\n",
  lycghxgryhqdlx:
    "បន្ទាប់ពីសារបានជោគជ័យអ្នកពាក់ព័ន្ធនឹងធ្វើការទាក់ទងជាមួយអ្នកសូមរក្សាទូរស័ព្ទឱ្យរលូនតើនៅតែបន្តឬទេ?\n",
  qyyjkfxmqk_title: "ស្ថានភាពគម្រោងអភិវឌ្ឍន៍ការសិក្សាស្រាវជ្រាវរបស់ក្រុមហ៊ុន\n",
  qyyjkfhdjxgqktitle:
    "សកម្មភាពអភិវឌ្ឍន៍ការស្រាវជ្រាវរបស់សហគ្រាសនិងស្ថានភាពពាក់ព័ន្ធ\n",
  qsrsjhmtitle: "សូមបញ្ចូលលេខទូរស័ព្ទដៃ\n",
  qsrdlzhtitle: "សូមបញ្ចូលគណនីចូល\n",
  qsrdlmmtitle: "សូមបញ្ចូលលេខសម្ងាត់ចូល\n",
  wecomestitle: "សូមស្វាគមន៍អ្នក!\n",
  cd3d5title: "ដែលមានប្រវែងពី៣ទៅ១៥តួអក្សរ\n",
  qsrmmtitle: "សូមបញ្ចូលលេខសម្ងាត់\n",
  wjmmtitle: "ភ្លេចពាក្យសម្ងាត់\n",
  fhsytitle: "ត្រឡប់ទៅទំព័រមុខ\n",
  grzctitle: "ការចុះឈ្មោះផ្ទាល់ខ្លួន\n",
  jgzctitle: "ការចុះឈ្មោះស្ថាប័ន\n",
  jjxzgzytitle: "ទាញយកធនធាននោះក្នុងពេលឆាប់ៗនេះតើនៅតែបន្តឬអត់?\n",
  sjhmtitle: "ទូរស័ព្ទដៃចំនួន\n",
  szmmtitle: "កំណត់ពាក្យសម្ងាត់\n",
  qszmmtitle: "សូមកំណត់លេខសម្ងាត់\n",
  qrmmtitle: "បញ្ជាក់លេខសម្ងាត់\n",
  xmmtitle: "បញ្ជាក់លេខសម្ងាត់\n",
  szdqtitle: "តំបន់ដែលមានទីតាំងស្ថិតនៅ\n",
  qtxyzmtitle: "សូមបំពេញលេខកូដផ្ទៀងផ្ទាត់\n",
  qxzzztitle: "សូមជ្រើសរើស\n",
  yzmtitle: "captcha\n",
  ljzctitle: "ចុះឈ្មោះជាបន្ទាន់\n",
  qxzsftitle: "សូមជ្រើសរើសខេត្ត\n",
  qxzcstitle: "សូមជ្រើសរើសទីក្រុង\n",
  qxzqytitle: "សូមជ្រើសរើសតំបន់\n",
  lcsrmmbyytitle: "បញ្ចូលលេខសម្ងាត់ពីរដងខុសគ្នា\n",
  hqyzmtitle: "យកលេខកូដផ្ទៀងផ្ទាត់\n",
  zccgtitle: "ភាពជោគជ័យក្នុងការចុះឈ្មោះ\n",
  jglxtitle: "ប្រភេទស្ថាប័ន(ជ្រើសរើសរហូតដល់៣រូប)\n",
  qyttitle: "សហគ្រាស\n",
  kyttitle: "ការសិក្សាផ្នែកវិទ្យាសាស្ត្រ\n",
  dxttitle: "សាកលវិទ្យាល័យ\n",
  fwttitle: "សេវាកម្ម\n",
  tzttitle: "វិនិយោគ\n",
  tjttitle: "ដាក់ស្នើ\n",
  jgmctitle: "ឈ្មោះស្ថាប័ន\n",
  qsrjgmctitle: "សូមបញ្ចូលឈ្មោះស្ថាប័ន\n",
  qzsxzyzjglxtitle: "សូមជ្រើសរើសយ៉ាងហោចណាស់ជ្រើសរើសប្រភេទស្ថាប័នមួយ\n",
  zdxzsgjglxtitle: "ជ្រើសរើសប្រភេទស្ថាប័នដល់ទៅបី\n",
  cdw11gzftitle: "ដែលមានប្រវែង១១តួអក្សរ\n",
  zzczmmtitle: "កំពុងធ្វើការកំណត់លេខសម្ងាត់ឡើងវិញតើនៅតែបន្តឬអត់?\n",
  sbtz_title: "សេចក្តីជូនដំណឹងស្តីពីការដាក់លិខិតប្រកាស\n",
  wsj_title: "គ្មានទិន្នន័យ\n",
  fbbm_title: "នាយកដ្ឋានផ្សព្វផ្សាយ\n",
  lx_title: "ប្រភេទ\n",
  zt_title: "ស្ថានភាព\n",
  kssj_title: "ពេលវេលាចាប់ផ្តើម\n",
  jzsj_title: "ពេលវេលាកំណត់\n",
  more: "ជាច្រើនទៀត\n",
  fb: "ចេញផ្សាយ\n",
  zhi: "至",
  djsb: "ចុចប្រកាស\n",
  ljdh_title: "ការរុករកយ៉ាងឆាប់រហ័ស\n",
  jscgname_table: "ឈ្មោះលទ្ធផលបច្ចេកទេស\n",
  ssdwname_table: "គ្រឿងក្នុង\n",
  szdwname_table: "អង្គភាពដែលមានទីតាំងស្ថិតនៅ\n",
  hylbname_table: "ប្រភេទឧស្សាហកម្ម\n",
  cgspname_table: "កម្រិតលទ្ធផល\n",
  csdname_table: "ភាពចាស់ទុំ\n",
  fbsjname_table: "ម៉ោងចេញផ្សាយ\n",
  kjxq_title: "តម្រូវការបច្ចេកវិទ្យា\n",
  xglj_title: "តំណភ្ជាប់ដែលទាក់ទង\n",
  xl_title: "ការសិក្សា\n",
  dw_title: "គ្រឿង\n",
  zjgdxx_title: "អ្នកជំនាញព័ត៌មានបន្ថែម\n",
  zw_title: "តំណែង\n",
  hy_title: "ឧស្សាហកម្ម\n",
  hylb_title: "ប្រភេទឧស្សាហកម្ម\n",
  szd_title: "ទីតាំង\n",
  dwmc_title: "ឈ្មោះអង្គភាព\n",
  xmmc_title: "ឈ្មោះគម្រោង\n",
  fbsj_title: "ម៉ោងចេញផ្សាយ\n",
  ly_title: "ដែន\n",
  lll_title: "ចំនួនមើល\n",
  zrfs_title: "វិធីផ្ទេរ\n",
  cgsp_title: "កម្រិតលទ្ធផល\n",
  sfcj_title: "តើការឆ្លងកាត់\n",
  lxr_title: "ទំនាក់ទំនង\n",
  tyshxydmtitle: "កូដឥណទានសង្គមឯកសណ្ឋាន\n",
  yxqtitle: "សុពលភាព\n",
  ztitle: "至",
  xmlytitle: "ប្រភពគម្រោង\n",
  qxzlytitle: "សូមជ្រើសរើសប្រភពគម្រោង\n",
  xmlkzxstitle: "ទម្រង់នៃការអនុវត្តគម្រោង\n",
  qsrxmmcname_table: "សូមបញ្ចូលឈ្មោះគម្រោង\n",
  qsryjkfrs_table: "សូមបញ្ចូលចំនួនអ្នកអភិវឌ្ឍការស្រាវជ្រាវ\n",
  qxzjzjd_table: "សូមជ្រើសរើសដំណាក់កាលរីកចម្រើន\n",
  qxzxmlkzxstitle: "សូមជ្រើសរើសទម្រង់អនុវត្តគម្រោង\n",
  xmdncgxstitle: "ទម្រង់លទ្ធផលឆ្នាំរបស់គម្រោង\n",
  qxzxmdncgxstitle: "សូមជ្រើសរើសទម្រង់លទ្ធផលឆ្នាំរបស់គម្រោង\n",
  xmjsjjmbtitle: "គោលដៅសេដ្ឋកិច្ចបច្ចេកទេសគម្រោង\n",
  qxzxmjsjjmbtitle: "សូមជ្រើសរើសគោលដៅសេដ្ឋកិច្ចបច្ចេកទេសគម្រោង\n",
  xmqsrqtitle: "កាលបរិច្ឆេទចាប់ផ្តើមគម្រោង\n",
  qxzxmqsrqtitle: "សូមជ្រើសរើសកាលបរិច្ឆេទចាប់ផ្តើមគម្រោង\n",
  xmwcrqtitle: "កាលបរិច្ឆេទបញ្ចប់គម្រោង\n",
  qxzxmwcrqtitle: "សូមជ្រើសរើសកាលបរិច្ឆេទបញ្ចប់គម្រោង\n",
  knxmdlsczyjzjdtitle: "ដំណាក់កាលរីកចម្រើនសំខាន់ៗនៅឆ្នាំគម្រោងឆ្លងឆ្នាំ\n",
  qxzknxmdlsczyjzjdtitle:
    "សូមជ្រើសរើសដំណាក់កាលរីកចម្រើនសំខាន់ៗនៅឆ្នាំគម្រោងឆ្លងឆ្នាំ\n",
  xmyjkfrytitle: "អ្នកអភិវឌ្ឍការសិក្សាគម្រោង(នាក់)\n",
  qsrxmyjkfrytitle: "សូមបញ្ចូលអ្នកអភិវឌ្ឍការសិក្សាគម្រោង(នាក់)\n",
  xmrysjgzsjtitle: "ម៉ោងធ្វើការជាក់ស្តែងរបស់បុគ្គលិកគម្រោង(នាក់ខែ)\n",
  qsrxmrysjgzsjtitle: "សូមបញ្ចូលម៉ោងធ្វើការរបស់បុគ្គលិក\n",
  xmjfzctitle: "ចំណាយថវិកាគម្រោង(ពាន់ដុល្លារ)\n",
  qsrxmjfzctitle: "សូមបញ្ចូលការចំណាយថវិកាគម្រោង\n",
  dwfzrtitle: "ប្រធានអង្គភាព\n",
  tbrtitle: "អ្នកបំពេញ\n",
  tbrqtitle: "កាលបរិច្ឆេទបំពេញ\n",
  qxztbrqtitle: "សូមជ្រើសរើសកាលបរិច្ឆេទបំពេញ\n",
  tjbtn: "ដាក់ស្នើ\n",
  zbmctitle: "ឈ្មោះសូចនាករ\n",
  jldwtitle: "អង្គភាពរង្វាស់\n",
  dmtitle: "កូដ\n",
  sltitle: "ចំនួន\n",
  jiatitle: "甲",
  yititle: "乙",
  bingtitle: "丙",
  yjkfryqk1title: "an,សិក្សាពីស្ថានភាពអ្នកអភិវឌ្ឍន៍\n",
  yjkfryhj1title: "អ្នកអភិវឌ្ឍន៍ការសិក្សាស្រាវជ្រាវសរុបចំនួន\n",
  qzglhfwry1title: "ក្នុងនោះ៖បុគ្គលិកផ្នែកគ្រប់គ្រងនិងសេវាកម្ម\n",
  qzglx1title: "ក្នុងនោះ៖មនុស្សស្រី\n",
  qzqzry1title: "ក្នុងនោះ៖បុគ្គលិកពេញម៉ោង\n",
  qzbkbyjysry1title:
    "ក្នុងនោះ៖អ្នកដែលបានបញ្ចប់ការសិក្សាថ្នាក់បរិញ្ញាបត្រឡើងទៅ\n",
  qzwpry1title: "ក្នុងនោះ៖ពលករបរទេស\n",
  r1title: "人",
  yjkffyqk2title: "ទី២សិក្សាពីស្ថានភាពចំណាយលើការអភិវឌ្ឍ\n",
  yjkffyhj2title: "សរុបការចំណាយលើការអភិវឌ្ឍការស្រាវជ្រាវ\n",
  ryrgfy2title: "ចំនួន១.ការចំណាយសិប្បនិម្មិតសម្រាប់បុគ្គលិក\n",
  zjtrfy2title: "២.ការដាក់ចំណាយដោយផ្ទាល់\n",
  zjfyycqdtfy2title: "៣.ថ្លៃរំលោះធៀបនឹងថ្លៃតូបដែលនៅសល់រយៈពេលវែង\n",
  wxzctxfy2title: "៤.ថ្លៃឈ្នួលរំលោះទ្រព្យសម្បត្តិមើលមិនឃើញ\n",
  sjfy2title: "៥.ការចំណាយលើការរចនា\n",
  zbtsfyysyfy2title:
    "៦.ការចំណាយលើការបំបាត់កំហុសលើការបំពាក់ធៀបនឹងការចំណាយលើការសាកល្បង\n",
  wttsfyysyfy2title: "៧.ប្រគល់ការចំណាយលើការអភិវឌ្ឍការស្រាវជ្រាវខាងក្រៅ\n",
  wtjlyjjg2title: "បានប្រគល់ឱ្យស្ថាប័នស្រាវជ្រាវខាងក្នុង\n",
  wtjlgdxx2title: "ចាត់តាំងសាលាជាន់ខ្ពស់ខាងក្នុង\n",
  wtjlqy2title: "ធ្វើប្រតិភូកម្មអាជីវកម្មក្នុងប្រទេស\n",
  wtjwjg2title: "ប្រគល់សិទ្ធិឱ្យស្ថាប័នក្រៅដែន\n",
  qtfy2title: "៨.ការចំណាយផ្សេងៗ\n",
  qy2title: "០០០យ័ន\n",
  yjkfzcqk3title: "ទី៣ការសិក្សាពីស្ថានភាពទ្រព្យសម្បត្តិអភិវឌ្ឍន៍\n",
  dnxcyyyjkfdgdzc3title:
    "ទ្រព្យសម្បត្តិថេរត្រូវបានបង្កើតឡើងនៅឆ្នាំសម្រាប់ការស្រាវជ្រាវការអភិវឌ្ឍ\n",
  qizyqhsb3title: "ក្នុងនោះ៖ឧបករណ៍និងឧបករណ៍\n",
  yjkfzczjly4title: "ទី៤ការសិក្សាអំពីប្រភពថវិកាសម្រាប់ចំណាយលើការអភិវឌ្ឍ\n",
  lzqyzc4title: "ចំនួន១.ពីការបង្កើតអាជីវកម្មដោយខ្លួនឯង\n",
  lzzfbm4title: "២.ពីនាយកដ្ឋានរដ្ឋាភិបាល\n",
  lzyhdk4title: "៣.ពីកម្ចីធនាគារ\n",
  lzfxtz4title: "៤.ពីការវិនិយោគទុន\n",
  lzqtqd4title: "៥.ពីបណ្តាញផ្សេងទៀត\n",
  xgzctsqk5title: "ប្រាំការអនុវត្តគោលនយោបាយពាក់ព័ន្ធ\n",
  sbjjkc5title:
    "ប្រកាសស្តីពីការបូកសរុបកាត់កងចំណាយលើការអភិវឌ្ឍការស្រាវជ្រាវការកាត់បន្ថយការលើកលែងពន្ធ\n",
  jjkcjmsje5title: "បូកសរុបចំនួនទឹកប្រាក់កាត់បន្ថយពន្ធកាត់ទុក\n",
  gxjsqyjmsje5title:
    "ចំនួនទឹកប្រាក់កាត់បន្ថយពន្ធសម្រាប់សហគ្រាសបច្ចេកវិទ្យាកូស៊ីន\n",
  qybyjkfjg6title:
    "ប្រាំមួយគ្រឹះស្ថានសិក្សាស្រាវជ្រាវនិងអភិវឌ្ឍន៍(ខាងក្នុង)ស្ថានភាព\n",
  qmjgsg6title: "ចំនួនស្ថាប័នចុងក្រោយ\n",
  jgyjkfry6title: "អ្នកអភិវឌ្ឍន៍ការស្រាវជ្រាវរបស់ស្ថាប័ន\n",
  qzbsby6title: "ក្នុងនោះ៖បញ្ចប់ការសិក្សាថ្នាក់វេជ្ជបណ្ឌិត\n",
  ssby6title: "បញ្ចប់ការសិក្សាថ្នាក់អនុបណ្ឌិត\n",
  jgyjkffy6title: "ការចំណាយលើការអភិវឌ្ឍការស្រាវជ្រាវរបស់ស្ថាប័ន\n",
  qmyqhsbyj6title: "តម្លៃដើមឧបករណ៍និងឧបករណ៍បញ្ចប់វគ្គ\n",
  yjkfccjxgqk7title: "ទី៧ការសិក្សាអំពីលទ្ធផលនៃការអភិវឌ្ឍនិងស្ថានភាពពាក់ព័ន្ធ\n",
  zlqk7title: "(មួយ)ករណីប៉ាតង់\n",
  dlzlsqs7title: "ចំនួនពាក្យស្នើសុំប៉ាតង់ឆ្នាំ\n",
  qzfmzl7title: "ក្នុងនោះ៖ប៉ាតង់នៃការច្នៃប្រឌិត\n",
  qmyxfmzls7title: "ចំនួនប៉ាតង់នៃការច្នៃប្រឌិតដ៏មានប្រសិទ្ធភាពនៃវគ្គបញ្ចប់\n",
  qzybss7title: "ក្នុងនោះ៖ត្រូវបានដាក់ឲ្យអនុវត្ត\n",
  zlsyqzrjxks7title: "ការផ្ទេរកម្មសិទ្ធិប៉ាតង់និងចំនួនអាជ្ញាប័ណ្ណ\n",
  zlsyqxksr7title:
    "ប្រាក់ចំណូលពីការផ្ទេរកម្មសិទ្ធិប៉ាតង់និងការផ្តល់អាជ្ញាប័ណ្ណ\n",
  xcpqk7title: "(ii)ស្ថានភាពផលិតផលថ្មី\n",
  xcpxssr7title: "ចំណូលពីការលក់ផលិតផលថ្មី\n",
  qzck7title: "ក្នុងនោះ៖ការនាំចេញ\n",
  qtqk7title: "(បី)ករណីផ្សេងទៀត\n",
  qmzcsb7title: "ចុងបញ្ចប់មានពាណិជ្ជសញ្ញាដែលបានចុះបញ្ជី\n",
  fbkjlw7title: "ចេញផ្សាយនិក្ខេបបទវិទ្យាសាស្ត្រនិងបច្ចេកវិទ្យា\n",
  xcgjhhybz7title: "បង្កើតស្តង់ដារជាតិឬឧស្សាហកម្ម\n",
  jian7title: "件",
  pian7title: "篇",
  xiang7title: "项",
  qtxgqk8title: "eightករណីពាក់ព័ន្ធផ្សេងៗទៀត\n",
  jsgchjshqqk8title: "(មួយ)ការកែប្រែបច្ចេកទេសនិងស្ថានភាពទទួលបានបច្ចេកទេស\n",
  jsgzjfzc8title: "ការចំណាយថវិកាកែប្រែបច្ចេកទេស\n",
  gmjljsjfzc8title: "ការចំណាយថវិកាសម្រាប់ការទិញបច្ចេកវិទ្យាខាងក្នុង\n",
  yjjwjsjfzc8title: "ការនាំចូលការចំណាយថវិកាបច្ចេកវិទ្យាក្រៅប្រទេស\n",
  yjjwjsdxhxs8title:
    "ការចំណាយថវិកាសម្រាប់ការស្រូបយកការរំលាយអាហារដែលនាំមកនូវបច្ចេកវិទ្យាក្រៅប្រទេស\n",
  qybyjkfjg8title:
    "(ii)ស្ថានភាពគ្រឹះស្ថានសិក្សាស្រាវជ្រាវនិងអភិវឌ្ឍន៍(ក្រៅព្រំដែន)\n",
  qmqyzjwsldyjkfjgs8title:
    "ចំនួនទីភ្នាក់ងារស្រាវជ្រាវនិងអភិវឌ្ឍន៍ដែលសហគ្រាសបញ្ចប់វគ្គបានបង្កើតឡើងនៅក្រៅព្រំដែន\n",
  ge8title: "个",
  dhtabletitledh:
    "តារាងប្រមូលផ្ដុំតម្រូវការលទ្ធផលបច្ចេកវិទ្យាវេទិកាផ្ទេរបច្ចេកវិទ្យាតេជោសែន\n",
  bhdh: "ចំនួន\n",
  txsjdh: "ពេលវេលាបំពេញ\n",
  dwxxdh: "មួយព័ត៌មានអង្គភាព\n",
  dwmcdh: "ឈ្មោះអង្គភាព\n",
  fddbrdh: "អ្នកតំណាងស្របច្បាប់\n",
  lxyxdh: "ទាក់ទងប្រអប់សំបុត្រ\n",
  lxrdh: "ទំនាក់ទំនង\n",
  lxdhdh: "ទាក់ទងទូរស័ព្ទលេខ\n",
  dwrygkdh: "ប្រវត្តិរូបបុគ្គលិកអង្គភាព\n",
  zzrydh: "បុគ្គលិកបម្រើការងារ\n",
  dzysjsrcdh: "ទេពកោសល្យបច្ចេកទេសចាប់ពីថ្នាក់ឧត្តមសិក្សាឡើងទៅ\n",
  qzbsdh: "ក្នុងនោះលោកបណ្ឌិត\n",
  zscqyydh: "ភាពជាម្ចាស់កម្មសិទ្ធិបញ្ញា/ប្រភព\n",
  wgsjzldh: "PatentDesignDesignរូបរាង\n",
  syxzldh: "ប័ណ្ណប៉ាតង់ប្រភេទជាក់ស្តែង\n",
  fmzldh: "ប៉ាតង់នៃការច្នៃប្រឌិត\n",
  rzqdh: "សិទ្ធិអ្នកនិពន្ធទន់\n",
  gmdh: "ទិញ\n",
  zzyfdh: "ការស្រាវជ្រាវនិងអភិវឌ្ឍន៍ដោយស្វ័យប្រវត្តិ\n",
  hzkfdh: "សហប្រតិបត្តិការអភិវឌ្ឍន៍\n",
  qtdh: "ផ្សេងទៀត\n",
  sfwgxjsqydh: "តើជាសហគ្រាសបច្ចេកវិទ្យាថ្មីខ្ពស់\n",
  shidh: "是",
  szsbh: "គឺជាលេខវិញ្ញាបនបត្រ\n",
  foubh: "否",
  sfwkjxzxqybh: "តើជាសហគ្រាសខ្នាតតូចនិងមធ្យមប្រភេទបច្ចេកវិទ្យា\n",
  qtlxscztbh: "ប្រធានបទទីផ្សារប្រភេទផ្សេងទៀត\n",
  sfjscxcyptbh: "តើការសាងសង់វេទិកាសហគ្រិនភាពច្នៃប្រឌិត\n",
  zckjbh: "ទស្សនិកជន\n",
  xctdbh: "ដីកំណើតថ្មី\n",
  qyjszxbh: "មជ្ឈមណ្ឌលបច្ចេកវិទ្យាសហគ្រាស\n",
  gcyjzxbh: "មជ្ឈមណ្ឌលសិក្សាវិស្វកម្ម\n",
  jscxzxbh: "មជ្ឈមណ្ឌលច្នៃប្រឌិតបច្ចេកវិទ្យា\n",
  kjqyfhqbh: "អ្នកបង្កើតអាជីវកម្មបច្ចេកវិទ្យា\n",
  snjjyxxbh: "ទី២ព័ត៌មានអំពីប្រតិបត្តិការឆ្នាំមុន(ម៉ឺនយ័ន)\n",
  zzcbh: "ទ្រព្យសម្បត្តិសរុប\n",
  jzcbh: "ទ្រព្យសម្បត្តិសុទ្ធ\n",
  yysrbh: "营业收入",
  zyftrbh: "ស្រាវជ្រាវនិងអភិវឌ្ឍន៍ធាតុចូល\n",
  zyysrbh: "ប្រាក់ចំណូលអាជីវកម្មបោះជំរុំសំខាន់\n",
  jlrbh: "ប្រាក់ចំណេញសុទ្ធ\n",
  yftrbh: "ស្រាវជ្រាវនិងអភិវឌ្ឍន៍ធាតុចូល\n",
  ydwswybh: "១-៥០០,០០០យ័ន\n",
  wsdwbwybh: "៥០-៥លានយ័ន\n",
  wbdwbwybh: "៥០០-១០លានយ័ន\n",
  yqwwbwybh: "ជាង១០លានយ័ន\n",
  yftrzyysrbblbh: "ការស្រាវជ្រាវនិងអភិវឌ្ឍន៍ធាតុចូលសមាមាត្រនៃចំណូលអាជីវកម្ម\n",
  bfz1bh: "១%\n",
  bfz1d3bh: "១%-៣.\n",
  bfz3d5bh: "៣%-៥%\n",
  bfz5d10bh: "៥%-១០%\n",
  bfz10bh: "ជាង១០%\n",
  zyywjgxjscpbh:
    "អាជីវកម្មបោះជំរុំសំខាន់និងផលិតផលបច្ចេកវិទ្យាខ្ពស់ថ្មី(សេវាកម្ម)\n",
  sfhkjzxjjbh: "តើទទួលបានមូលនិធិពិសេសខាងបច្ចេកវិទ្យា\n",
  gjsbh: "ជាតិ/ខេត្ត\n",
  sfyhjgtzbh: "តើបានទទួលការវិនិយោគពីស្ថាប័ន\n",
  ljbh: "បង្កេីត\n",
  jgtzjewybh: "]ចំនួនទឹកប្រាក់វិនិយោគស្ថាប័ន]លានយ័ន\n",
  cpjbqkbh: "បីសាចស្ថានភាពមូលដ្ឋានផលិតផល\n",
  cpmcbh: "ឈ្មោះផលិតផល\n",
  ssjslybh: "វិស័យបច្ចេកវិទ្យាដែលគេស្គាល់ថា\n",
  dzxxbh: "សារអេឡិចត្រូនិច\n",
  rgzlbh: "បញ្ញាសិប្បនិម្មិត\n",
  zinzzgdzbbh: "ឆ្លាតវៃក្នុងការផលិតឧបករណ៍លំដាប់ខ្ពស់\n",
  qtbh: "ផ្សេងទៀត\n",
  xdlybh: "កសិកម្មទំនើប\n",
  gjsfubh: "សេវាកម្មបច្ចេកវិទ្យាខ្ពស់\n",
  xnyjjnjsbh: "បច្ចេកវិទ្យាថាមពលថ្មីនិងសន្សំសំចៃថាមពល\n",
  hkhtjsbh: "បច្ចេកវិទ្យាអវកាស\n",
  zyyhjjsbh: "ធនធាននិងបច្ចេកវិទ្យាបរិស្ថាន\n",
  xclbh: "សម្ភារៈថ្មី\n",
  yyhybh: "ឧស្សាហកម្មកម្មវិធី\n",
  xtrhbh: "ព័ត៌មាន,ទំនាក់ទំនង,កម្មវិធី,អ៊ីនធឺណិត\n",
  llmybh: "រុក្ខាប្រមាញ់និងនេសាទ\n",
  wshshgzbh: "សុខាភិបាលនិងការងារសង្គម\n",
  jtyscchyzbh: "ការដឹកជញ្ជូនឃ្លាំងស្តុកទំនិញនិងប្រៃសណីយ៍\n",
  ckybh: "ឧស្សាហកម្មរុករករ៉ែ\n",
  szjzbh: "អគារក្រុង\n",
  slhjhgyssglbh: "ការគ្រប់គ្រងធនធានទឹកបរិស្ថាននិងឧបករណ៍ប្រើប្រាស់\n",
  kxyjhjsfwybh: "ស្រាវជ្រាវវិទ្យាសាស្ត្រនិងសេវាកម្មបច្ចេកវិទ្យា\n",
  zcybh: "ផលិតកម្ម\n",
  dlrlrqjscshgybh:
    "ការផលិតនិងការផ្គត់ផ្គង់អគ្គិសនីថាមពលកម្តៅការផលិតនិងការប្រើប្រាស់ឧស្ម័ននិងទឹក៕\n",
  gjzzbh: "អង្គការអន្តរជាតិ\n",
  hgbh: "គីមី\n",
  ggglshbzhshzzbh: "រដ្ឋបាលសាធារណៈរបបសន្តិសុខសង្គមនិងអង្គការសង្គម\n",
  zphswfwbh: "សេវាកម្មជួលនិងអាជីវកម្ម\n",
  jybh: "អប់រំ\n",
  yysxbh: "លក្ខណៈសម្បត្តិអនុវត្ត\n",
  yysxcxbh: "ការច្នៃប្រឌិតដើម\n",
  jseckfbh: "ការអភិវឌ្ឍបន្ទាប់បន្សំនៃបច្ចេកវិទ្យា\n",
  jsyjzcxbh: "បច្ចេកវិទ្យាណែនាំការច្នៃប្រឌិតឡើងវិញ\n",
  zhcxbh: "ការច្នៃប្រឌិតរួមបញ្ចូលគ្នា\n",
  yfjdbh: "ដំណាក់កាលស្រាវជ្រាវនិងអភិវឌ្ឍន៍\n",
  yfzbh: "ក្នុងការស្រាវជ្រាវនិងអភិវឌ្ឍន៍\n",
  xsjdbh: "ដំណាក់កាលសាកល្បងខ្នាតតូច\n",
  zsbh: "សាកល្បងមធ្យម\n",
  ypbh: "គំរូ\n",
  xplbh: "បាច់តូច\n",
  kcyhbh: "កម្មសិទ្ធិបញ្ញា\n",
  kjcgxmxqbbh: "ទី៤ស្ថានភាពតម្រូវការគម្រោងលទ្ធផលបច្ចេកវិទ្យា\n",
  fwxqbh: "តម្រូវការសេវាកម្ម\n",
  jsrcyjbh: "ការណែនាំទេពកោសល្យបច្ចេកវិទ្យា\n",
  cgzhyxmhzbh: "ការផ្លាស់ប្តូរលទ្ធផលនិងកិច្ចសហប្រតិបត្តិការលើគម្រោង\n",
  zljskfgmbh: "ការអភិវឌ្ឍ/ការទិញបច្ចេកវិទ្យាប៉ាតង់\n",
  xcpyfbh: "ការស្រាវជ្រាវនិងអភិវឌ្ឍន៍ផលិតផលថ្មី\n",
  qypxbh: "ការបណ្តុះបណ្តាលសហគ្រាស\n",
  scglbh: "ការគ្រប់គ្រងផលិតកម្ម\n",
  yxchbh: "ផែនការទីផ្សារ\n",
  zyjnbh: "ជំនាញវិជ្ជាជីវៈ\n",
  xljybh: "ការអប់រំតាមប្រព័ន្ធអេឡិចត្រូនិច\n",
  qyzxbh: "ការផ្លាស់ប្តូរអាជីវកម្ម\n",
  xyjsjcqkbh: "ស្ថានភាពមូលដ្ឋានបច្ចេកទេសដែលមានស្រាប់\n",
  xyjjdxqwtbh: "បញ្ហាតម្រូវការ/តម្រូវការបច្ចេកវិទ្យាដែលត្រូវដោះស្រាយ\n",
  zljsbh: "បច្ចេកវិទ្យាប៉ាតង់\n",
  xmhzykfbh: "កិច្ចសហប្រតិបត្តិការនិងការអភិវឌ្ឍគម្រោង\n",
  xmsbbh: "ការប្រកាសគម្រោង\n",
  rcyjbh: "ការណែនាំអំពីទេពកោសល្យ\n",
  xqjsbh: "ប្រវត្តិរូបតម្រូវការ\n",
  qwhzfsbh: "រំពឹងថានឹងសហការពេលវេលា\n",
  gtkfbh: "ការអភិវឌ្ឍរួមគ្នា\n",
  gmfwbh: "សេវាកម្មទិញ\n",
  slzdbh: "បង្កើតគេហទំព័រ\n",
  qwhzsjbh: "រំពឹងថានឹងសហការពេលវេលា\n",
  dq1dsbh: "រយៈពេលខ្លី(១-៣ឆ្នាំ)\n",
  cq5nysbh: "រយៈពេលវែង(លើសពី៥ឆ្នាំ)\n",
  jjxqwzbh: "ដើម្បីដោះស្រាយតម្រូវការ\n",
  yqtrbh: "រំពឹងទុកធាតុចូល\n",
  swyyxbh: "ក្រោម១០ម៉ឺនយ័ន\n",
  sdsswybh: "១០-៣០ម៉ឺនយ័ន\n",
  ssdwsbh: "៣០-៥០០,០០០យ័ន\n",
  wsdybwbh: "៥០-១លានយ័ន\n",
  ybdybwbh: "១០០-១,៥លានយ័ន\n",
  ybwdebbh: "១៥០-២លានយ័ន\n",
  ebdsbbh: "២០០-៣លានយ័ន\n",
  wbysbh: "៥លានយ័នជាង\n",
  qtysfwbh: "ផ្សេងទៀត(វិសាលភាពថវិកា)\n",
  glxxbh: "ប្រាំព័ត៌មានគ្រប់គ្រង\n",
  tygkxqxxbh: "យល់ព្រមលើព័ត៌មានតម្រូវការជាសាធារណៈ\n",
  bfgkbh: "សាធារណៈផ្នែក\n",
  tyxyzjfwbh: "យល់ព្រមត្រូវការសេវាកម្មជំនាញ\n",
  tycydjjfadsxpjbh: "យល់ព្រមចូលរួមពិនិត្យវាយតម្លៃដំណោះស្រាយ\n",
  dcxyjhzjlhddjyhxqbh:
    "អនុសាសន៍និងតម្រូវការសម្រាប់សកម្មភាពផ្លាស់ប្តូរកិច្ចសហប្រតិបត្តិការលើការសិក្សាស្រាវជ្រាវផលិតផល\n",
  tjbh: "ដាក់ស្នើ\n",
  dybh: "បោះពុម្ព\n",
  qtxbhbh: "សូមបំពេញលេខ\n",
  qtxdwmcbh: "សូមបំពេញឈ្មោះអង្គភាព\n",
  qtxlxrbh: "សូមបំពេញទំនាក់ទំនង\n",
  qtxlxdhbh: "សូមបំពេញលេខទូរស័ព្ទទំនាក់ទំនង\n",
  zztjztsjbh: "កំពុងបញ្ជូនទិន្នន័យនេះហើយតើនៅតែបន្តឬយ៉ាងណា?\n",
  tjcgbh: "ដាក់ស្នើភាពជោគជ័យ\n",
  nccenter: "ឈ្មោះហៅក្រៅ\n",
  bindcenter: "ចង.\n",
  sqsmrzcenter: "ដាក់ពាក្យសុំការបញ្ជាក់ឈ្មោះពិត\n",
  smrzcenter: "ការបញ្ជាក់ឈ្មោះពិត\n",
  sqzjrzcenter: "ការទទួលស្គាល់ពីអ្នកជំនាញ\n",
  sqjgrzcenter: "ការទទួលស្គាល់ពីស្ថាប័នដាក់ពាក្យស្នើសុំ\n",
  jgrzcenter: "ការទទួលស្គាល់ពីស្ថាប័ន\n",
  scdlcenter: "ចូលលើកមុន\n",
  xqlcenter: "តម្រូវការ\n",
  cgzscenter: "ការបង្ហាញលទ្ធផល\n",
  zhaqcenter: "សុវត្ថិភាពគណនី\n",
  dlmmcenter: "ពាក្យសម្ងាត់ចូល\n",
  yybhzhxxcenter: "សម្រាប់ការពារព័ត៌មានគណនីនិងសុវត្ថិភាពនៃការចូល\n",
  ggcenter: "ការផ្លាស់ប្តូរ\n",
  aqsjcenter: "ទូរស័ព្ទដៃសុវត្ថិភាព\n",
  yyzktfxcenter: "បានផ្ទៀងផ្ទាត់អាចចូលឬទទួលបានលេខសម្ងាត់វិញតាមរយៈទូរស័ព្ទដៃ\n",
  xgdlmmcenter: "កែប្រែលេខសម្ងាត់ចូល\n",
  dqmmcenter: "ពាក្យសម្ងាត់បច្ចុប្បន្ន\n",
  xmmcenter: "ពាក្យសម្ងាត់ថ្មី\n",
  qrmmcenter: "បញ្ជាក់លេខសម្ងាត់\n",
  mmxgcgcenter: "ការកែប្រែពាក្យសម្ងាត់បានជោគជ័យ!\n",
  tjcenter: "ដាក់ស្នើ\n",
  qxcenter: "លុបចោល\n",
  qdcenter: "កំណត់\n",
  bdsjhcenter: "ចងទូរស័ព្ទថ្មី\n",
  xsjhmcenter: "លេខទូរស័ព្ទដៃថ្មី\n",
  yzmcenter: "captcha\n",
  hqcenter: "យកលេខកូដផ្ទៀងផ្ទាត់\n",
  qsrsjhmvocenter: "សូមបញ្ចូលលេខទូរស័ព្ទដៃ\n",
  cdwsygzfvocenter: "ដែលមានប្រវែង១១តួអក្សរ\n",
  qsryzmvocenter: "សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់\n",
  qsrmmvocenter: "សូមបញ្ចូលលេខសម្ងាត់\n",
  qzcsrmmmvocenter: "សូមបញ្ចូលលេខសម្ងាត់ម្តងទៀត\n",
  lcsrmmbyzvocenter: "បញ្ចូលលេខសម្ងាត់ពីរដងមិនស៊ីគ្នា!\n",
  zzxgmmvocenter: "កំពុងតែកែប្រែលេខសម្ងាត់តើនៅតែបន្តឬអត់?\n",
  cgvocenter: "ជោគជ័យ\n",
  zzxgsjhmvocenter: "កំពុងធ្វើការផ្លាស់ប្តូរលេខទូរស័ព្ទដៃតើនៅតែបន្តឬអត់?\n",
  tsvocenter: "ព័ត៌មានជំនួយ\n",
  dlsjhcenter: "ចូលលេខទូរស័ព្ទដៃ\n",
  ybdcenter: "បានចង.\n",
  wbdcenter: "បានចង.\n",
  ghbdcenter: "ការចងប្តូរ\n",
  qbdcenter: "ទៅចង.\n",
  xgmmcenter: "កែប្រែពាក្យសម្ងាត់\n",
  jbxxcenter: "ព័ត៌មានមូលដ្ឋាន\n",
  jbxxdesccenter:
    "ផ្តល់ព័ត៌មានអត្តសញ្ញាណត្រឹមត្រូវជួយធ្វើការផ្ទៀងផ្ទាត់អត្តសញ្ញាណលើកកម្ពស់រូបភាពឥណទាន\n",
  zsxmccenter: "ឈ្មោះពិត\n",
  zsxmcvomcenter: "សូមបញ្ចូលឈ្មោះពិត\n",
  xbvomcenter: "សូមជ្រើសរើសភេទ\n",
  cslyvomcenter: "សូមជ្រើសរើសថ្ងៃកំណើត\n",
  qsrzssfzhvomcenter: "សូមបញ្ចូលលេខអត្តសញ្ញាណប័ណ្ណពិត\n",
  qscsfzzmvomcenter: "សូមបញ្ចូលផ្នែកខាងមុខនៃអត្តសញ្ញាណប័ណ្ណ\n",
  qscsfzbmvomcenter: "សូមបញ្ចូលផ្នែកខាងក្រោយនៃអត្តសញ្ញាណប័ណ្ណ\n",
  zzthsmxxcenter: "កំពុងដាក់ព័ត៌មានឈ្មោះពិតតើនៅបន្តឬអត់?\n",
  zzbcgrxxcenter: "កំពុងរក្សាទុកព័ត៌មានផ្ទាល់ខ្លួនតើនៅតែបន្តឬទេ?\n",
  xbcenter: "ភេទ\n",
  nancenter: "男",
  nvcenter: "女",
  wzcenter: "មិនស្គាល់\n",
  cslycenter: "ខែឆ្នាំកំណើត\n",
  sfzhcenter: "លេខសម្គាល់អត្តសញ្ញាណ\n",
  sfzzmcenter: "ផ្នែកខាងមុខនៃឯកសារបញ្ជាក់អត្តសញ្ញាណ\n",
  sfzzmdesccenter:
    "អត្តសញ្ញាណប័ណ្ណផ្ទាល់ខ្លួនច្បាស់ត្រឹមត្រូវរូបថតដើមស្នើសុំរូបភាពក្នុងទម្រង់jpg,gif,pngខាងក្រោម២០០គ.\n",
  sfzfmcenter: "ឯកសារបញ្ជាក់អត្តសញ្ញាណបញ្ច្រាស\n",
  tjsmxxcenter: "បញ្ជូនព័ត៌មានឈ្មោះពិត\n",
  grzlcenter: "ប្រវត្តិរូប\n",
  sctxcenter: "រូបសំណាកបុព្វេសន្និវាស\n",
  sctxdesccenter: "ស្នើឱ្យផ្ទុកឡើងinchរូបថត\n",
  yhlccenter: "ឈ្មោះហៅក្រៅរបស់អ្នកប្រើប្រាស់\n",
  szdqcenter: "តំបន់ដែលមានទីតាំងស្ថិតនៅ\n",
  qxzcenter: "សូមជ្រើសរើស\n",
  yxcenter: "ប្រអប់សំបុត្រ\n",
  yxdesccenter: "បញ្ចូលប្រអប់សំបុត្រប្រើទូទៅរបស់អ្នក\n",
  jzdzcenter: "អាស័យដ្ឋានស្នាក់នៅ\n",
  zycenter: "វិជ្ជាជីវៈ\n",
  zydesccenter: "ពាណិជ្ជកម្មឬមុខរបរដែលប្រកបមុខរបរ\n",
  qqcenter: "qq\n",
  qqdesccenter:
    "សូមបញ្ចូលqqពិតដើម្បីងាយស្រួលដល់ភ្ញៀវក្នុងការទំនាក់ទំនងជាមួយអ្នក\n",
  bccenter: "អភិរក្ស\n",
  qsrnccenter: "សូមបញ្ចូលឈ្មោះហៅក្រៅ\n",
  qsryxcenter: "សូមបញ្ចូលប្រអប់សំបុត្រ\n",
  qsrxjzdcenter: "សូមបញ្ចូលអាសយដ្ឋានស្នាក់នៅ\n",
  qsrzycenter: "សូមបញ្ចូលវិជ្ជាជីវៈ\n",
  qsrqqcenter: "សូមបញ្ចូលqq\n",
  qsctxcenter: "សូមបញ្ចូលរូបក្បាល\n",
  qxzsfcenter: "សូមជ្រើសរើសខេត្ត\n",
  qxzcscenter: "សូមជ្រើសរើសទីក្រុង\n",
  qxzqycenter: "សូមជ្រើសរើសតំបន់\n",
  jgrzdesccenter: "ផ្តល់ព័ត៌មានត្រឹមត្រូវនិងជួយផ្ទៀងផ្ទាត់បន្ថែមទៀត។\n",
  qymcccenter: "ឈ្មោះអាជីវកម្ម\n",
  qydzcenter: "អាស័យដ្ឋានសហគ្រាស\n",
  fddbrcenter: "អ្នកតំណាងស្របច្បាប់\n",
  tyshxydmcenter: "កូដឥណទានសង្គមឯកសណ្ឋាន\n",
  yyzzcenter: "អាជ្ញាប័ណ្ណអាជីវកម្ម\n",
  yyzzdesccenter:
    "រូបថតអាជ្ញាប័ណ្ណអាជីវកម្មអាជីវកម្មច្បាស់លាស់ស្នើសុំរូបភាពក្នុងទម្រង់jpg,gif,pngក្រោម២០០kb\n",
  tgrzxxcenter: "ផ្តល់ព័ត៌មានបញ្ជាក់\n",
  qyyjkfxmqkcenter: "ស្ថានភាពគម្រោងអភិវឌ្ឍន៍ការសិក្សាស្រាវជ្រាវរបស់ក្រុមហ៊ុន\n",
  qbcenter: "ទាំងអស់\n",
  wshtgcenter: "ឆ្លងកាត់ដោយគ្មានការត្រួតពិនិត្យ\n",
  yshcenter: "បានធ្វើសវនកម្ម\n",
  dshcenter: "អង្គបុរេជំនុំជម្រះ\n",
  sscenter: "ស្វែងរក\n",
  qsrgjzcenter: "សូមបញ្ចូលពាក្យគន្លឹះ\n",
  xmmccenter: "ឈ្មោះគម្រោង\n",
  xmlycenter: "ប្រភពគម្រោង\n",
  kzxscenter: "ធ្វើការទម្រង់\n",
  kssjcenter: "ពេលវេលាចាប់ផ្តើម\n",
  jssjcenter: "ពេលវេលាបញ្ចប់\n",
  fzrcenter: "ប្រធាន\n",
  czcenter: "ប្រតិបត្តិការ\n",
  qyyjkfhdcenter: "សកម្មភាពស្រាវជ្រាវនិងអភិវឌ្ឍន៍សហគ្រាស\n",
  dwmccenter: "ឈ្មោះអង្គភាព\n",
  dwfzrcenter: "ប្រធានអង្គភាព\n",
  tbrcenter: "អ្នកបំពេញ\n",
  yxqkscenter: "ពេលវេលាចាប់ផ្តើមសុពលភាព\n",
  yxqjscenter: "ពេលវេលាបញ្ចប់សុពលភាព\n",
  kjcgxqzjcenter: "ការប្រមូលផ្តុំតម្រូវការលទ្ធផលបច្ចេកវិទ្យា\n",
  cpmccenter: "ឈ្មោះផលិតផល\n",
  txsjcenter: "ពេលវេលាបំពេញ\n",
  fbkjcgcgxxcenter: "ផ្សព្វផ្សាយលទ្ធផលបច្ចេកវិទ្យា-ព័ត៌មានលទ្ធផល\n",
  fbkjcgcgdesccenter:
    "ព័ត៌មានពេញលេញកាន់តែខ្ពស់កាន់តែងាយទទួលបានការចាប់អារម្មណ៍ពីអតិថិជនគោលដៅ\n",
  fbkjcgjsmccenter: "ឈ្មោះបច្ចេកទេស\n",
  hylbcenter: "ប្រភេទឧស្សាហកម្ម\n",
  zscqztcenter: "ស្ថានភាពកម្មសិទ្ធិបញ្ញា\n",
  zlmccenter: "ឈ្មោះប៉ាតង់\n",
  zlhcenter: "លេខប៉ាតង់\n",
  jjcenter: "ប្រវត្តិរូបសង្ខេប\n",
  sfcjcenter: "តើការឆ្លងកាត់\n",
  zrfscenter: "វិធីផ្ទេរ\n",
  cgszdwcenter: "អង្គភាពដែលមានលទ្ធផល\n",
  dwlxcenter: "ប្រភេទគ្រឿង\n",
  szdwmccenter: "ឈ្មោះអង្គភាពដែលមានទីតាំងស្ថិតនៅ\n",
  cgszdcenter: "ទីតាំងលទ្ធផល\n",
  fbcgzszmclcenter: "ការបង្ហាញលទ្ធផលនៃការបោះពុម្ពផ្សាយ-សម្ភារៈភស្តុតាង\n",
  fbcgdesczmcenter:
    "ផ្ទុកឡើងនូវសម្ភារៈភស្តុតាងដែលងាយស្រួលសម្រាប់អតិថិជនស្វែងយល់បន្ថែមអំពីការបង្ហាញលទ្ធផល\n",
  xxcycenter: "ឧស្សាហកម្មដែលកំពុងរីកចម្រើន\n",
  kjcgpjspcenter: "កម្រិតវាយតម្លៃលទ្ធផលបច្ចេកវិទ្យា\n",
  csdcenter: "ភាពចាស់ទុំ\n",
  csdzmcenter: "ការបញ្ជាក់ពីភាពចាស់ទុំ\n",
  csdzmdesccenter:
    "រូបថតច្បាស់តម្រូវការរូបភាពក្នុងទម្រង់jpg,gif,pngខាងក្រោម១០០គីឡូបៃ។\n",
  jstpcenter: "រូបភាពបច្ចេកវិទ្យា\n",
  jspgjzcenter: "តម្លៃវាយតម្លៃបច្ចេកទេស\n",
  lxrcenter: "ទំនាក់ទំនង\n",
  sjhmcenter: "ទូរស័ព្ទដៃចំនួន\n",
  zqsrsjhcenter: "បញ្ចូលលេខទូរស័ព្ទដៃឱ្យបានត្រឹមត្រូវ\n",
  zqsrlxrcenter: "បំពេញទំនាក់ទំនងឲ្យបានត្រឹមត្រូវ\n",
  tjkjcgshcenter: "ដាក់ជូនអង្គពិធីពិនិត្យលទ្ធផលបច្ចេកវិទ្យា\n",
  glcgcenter: "លទ្ធផលនៃការគ្រប់គ្រង\n",
  kjcgmccenter: "ឈ្មោះលទ្ធផលបច្ចេកវិទ្យា\n",
  zjzkzjxxcenter: "អ្នកជំនាញការ-ព័ត៌មានអំពីអ្នកជំនាញ\n",
  zjzkzjxxdesccenter:
    "ផ្តល់ព័ត៌មានអត្តសញ្ញាណត្រឹមត្រូវជួយធ្វើការផ្ទៀងផ្ទាត់អត្តសញ្ញាណលើកកម្ពស់រូបភាពឥណទាន\n",
  zgxlcenter: "ការសិក្សាខ្ពស់បំផុត\n",
  gzdwcenter: "អង្គភាពការងារ\n",
  zwcenter: "តំណែង\n",
  lxdhcenter: "ទាក់ទងទូរស័ព្ទលេខ\n",
  fwalcenter: "ករណីសេវាកម្ម\n",
  tjshcenter: "ដាក់ជូនពិនិត្យ\n",
  glzjzkxicenter: "ព័ត៌មានអ្នកជំនាញគ្រប់គ្រង\n",
  zjmzcenter: "ឈ្មោះអ្នកជំនាញ\n",
  zjhycenter: "ឧស្សាហកម្មអ្នកជំនាញ\n",
  tjrqcenter: "កាលបរិច្ឆេទបន្ថែម.\n",
  zcxlcenter: "ចំណងជើង/ការសិក្សា\n",
  fbjsxqxqxxcenter:
    "ផ្សព្វផ្សាយព័ត៌មានអំពីតម្រូវការបច្ចេកវិទ្យា-ព័ត៌មានអំពីតម្រូវការ\n",
  fbjsxqdesccenter:
    "បំពេញព័ត៌មានឲ្យបានត្រឹមត្រូវហើយឆ្លងកាត់ការត្រួតពិនិត្យទៀត\n",
  xqfmccenter: "គម្រូតម្រូវការ\n",
  xqfmdesccenter: "គម្របតម្រូវការបច្ចេកទេសដែលបង្ហាញនៅផ្នែកបញ្ជី។\n",
  xqmccenter: "ឈ្មោះតម្រូវការ\n",
  fbxqlxxxcenter: "ផ្សព្វផ្សាយតម្រូវការបច្ចេកវិទ្យា-ព័ត៌មានទាក់ទង\n",
  fbxqlxxxdesccenter:
    "បំពេញព័ត៌មានទាក់ទងឲ្យបានត្រឹមត្រូវដើម្បីងាយស្រួលឲ្យអតិថិជនទាក់ទងទៅអ្នក\n",
  tjjsxqshcenter: "ដាក់ស្នើសុំការពិនិត្យតម្រូវការបច្ចេកទេស\n",
  glxqcenter: "គ្រប់គ្រងតម្រូវការ\n",
  xiugaicenter: "កែប្រែ\n",
  sanchucenter: "លុប\n",
  kjcglycenter: "សារលទ្ធផលបច្ចេកវិទ្យា\n",
  lybkcenter: "ប្រអប់សារ\n",
  lydxcenter: "វត្ថុសារ.\n",
  dhcscenter: "ចំនួននៃការសន្ទនា\n",
  jsxqlycenter: "សារតម្រូវការបច្ចេកវិទ្យា\n",
  kjcgcenter: "សារលទ្ធផលបច្ចេកវិទ្យា\n",
  zjzklycenter: "សារមន្ទីរអ្នកជំនាញ\n",
  jzmmL: "ចងចាំពាក្យសម្ងាត់\n",
  "welogin": "សូមស្វាគមន៍មកកាន់\n",
  "weuser": "ឈ្មោះអ្នកប្រើ\n",
  "wepass": "កូដMax\n",
  "weother": "ការចូលផ្សេងទៀត\n"
};
