<template>
  <div>
    <div class="banner">
      <div class="top">
        <div class="base-width top-content" style="z-index: 999">
          <div
            class="floatl"
            style="font-size: 16px; font-weight: 400; color: #333333"
          >
            <img class="lang-img" :src="zhongguo" @click="changLang('')" alt="" srcset="">
            <img class="lang-img" :src="meguo" @click="changLang('en')" alt="" srcset="">
            <img class="lang-img" :src="jianpuzhai" @click="changLang('kh')" alt="" srcset="">
            <img class="lang-img" :src="laowo" @click="changLang('lo')" alt="" srcset="">
            <img class="lang-img" :src="miandian" @click="changLang('my')" alt="" srcset="">
            <img class="lang-img" :src="taiguo" @click="changLang('th')" alt="" srcset="">
            <img class="lang-img" :src="yuenan" @click="changLang('vi')" alt="" srcset="">
            <!-- <span style="margin-right: 8px">{{ ytimeStr }}</span>
            <span style="margin-right: 20px">{{ stimeStr }}</span>
            <span>{{ wtimeStr }}</span>
            <div style="display: inline-block; margin-left: 20px">
              <span style="font-weight: bold">{{ weather.result.city }}</span>
              <span
                style="margin-left: 8px"
                v-if="weather.result.future.length > 0"
                >{{ weather.result.future[0].weather }}</span
              >
              <span
                style="margin-left: 8px"
                v-if="weather.result.future.length > 0"
                >{{ weather.result.future[0].direct }}</span
              >
              <span
                style="margin-left: 8px; color: green"
                v-if="weather.result.future.length > 0"
                >{{ weather.result.future[0].temperature }}</span
              >
            </div> -->
            <!-- <iframe style="margin-left: 20px;height: 35px;vertical-align: middle;" scrolling="no" frameborder="0" allowtransparency="true" src="http://i.tianqi.com/index.php?c=code&id=34&icon=1&num=3"></iframe> -->
          </div>
          <div class="floatr login-wrap">
            <a class="top-text" href="javaScript:void(0);" @click="goHome"
              >{{cuLangData.wecome}}</a
            >
            <div class="login-link-wrap" v-if="!isLogin">
              <a href="javaScript:void(0);" @click="goRegister">{{cuLangData.register}}</a>
              <a href="javaScript:void(0);" @click="goLogin">{{cuLangData.login}}</a>
            </div>
            <div class="login-link-wrap" v-else>
              <a href="javaScript:void(0);" @click="goUserCenter">{{cuLangData.center}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="banner-wrap">
        <div class="banner-carousel">
          <el-carousel
            height="340px"
            indicator-position="none"
            :loop="true"
            arrow="never"
            :autoplay="true"
            :interval="4000"
          >
            <el-carousel-item v-for="(item, i) in banner" :key="'item_' + i">
              <a
                @click="goUrl(item)"
                v-if="item.image"
                target="_blank"
                class="banner-carousel-a"
                :style="{
                  width: '100%',
                  height: '100%',
                  backgroundImage: 'url(' + item.image_url + ')',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }"
              >
              </a>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="base-width banner-content">
          <!--          <div class="logo-text" @click="goHome">-->
          <!--            <img :src="LogoText" alt="" srcset="" />-->
          <!--          </div>-->
          <div class="search-wrap">
            <el-input
              :placeholder="cuLangData.searchI"
              v-model="se_keywords"
              class="input-with-select"
              @keyup.enter="goSearch"
            >
              <el-select v-model="selects" slot="prepend" :placeholder="cuLangData.searchP">
                <el-option
                  v-for="(item, i) in searchType"
                  :label="cuLangData[item.label]"
                  :value="item.name"
                  :key="'searchType' + i"
                ></el-option>
              </el-select>
              <el-button slot="append" @click="goSearch">{{cuLangData.search}}</el-button>
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
    <div class="nav">
      <div class="base-width">
        <div class="nav-wrap col-md-12 col-sm-12 col-xs-12">
          <ul class="layui-nav">
            <li
              class="layui-nav-item"
              :class="navActive == item.uri ? 'layui-this' : ''"
              v-for="(item, i) in nav_menu"
              :key="'nav_menu' + i"
              @click="goLinkNav(item)"
            >
              <a href="javaScript:void(0);">{{ item.title }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
  </div>
</template>

<script>
import zhongguo from "@/assets/images/banner_03.png"
import meguo from "@/assets/images/United States.png"
import jianpuzhai from "@/assets/images/banner_05.png";
import laowo from "@/assets/images/banner_07.png";
import miandian from "@/assets/images/banner_09.png";
import taiguo from "@/assets/images/banner_11.png";
import yuenan from "@/assets/images/banner_13.png";
import LogoText from "@/assets/images/logo-text.png";
export default {
  data() {
    return {
      LogoText,
      meguo,
      zhongguo,
      jianpuzhai,
      laowo,
      miandian,
      taiguo,
      yuenan,
      weather: {
        result: {
          city: "",
          future: [],
        },
      },
      banner: "",
      selects: "",
      se_keywords: "",
      query: {},
      navActive: "",
      nav_menu: [],
      timeI: null,
      timeStr: "",
      ytimeStr: "",
      stimeStr: "",
      wtimeStr: "",
      searchType: [
        // {
        //   id: 1,
        //   label: "请选择",
        //   name: "",
        //   value: "1",
        //   path: "",
        // },
        {
          id: 2,
          label: "zcxwtitle",
          value: "2",
          name: "ArticleList",
          path: "/articlelist?c_id=3",
        },
        {
          id: 8,
          label: "tzgotitle",
          value: "8",
          name: "TzggList",
          path: "/tzgglist",
        },
        {
          id: 3,
          label: "kjhztitle",
          value: "3",
          path: "/kjhzlist?c_id=4",
          name: "Kjhzlist",
        },
        {
          id: 4,
          label: "kjcgtitle",
          value: "4",
          path: "/kjcglist",
          name: "Kjcglist",
        },
        {
          id: 5,
          label: "zjzktitle",
          value: "5",
          path: "/zjzklist",
          name: "Zjzklist",
        },
        {
          id: 6,
          label: "jsxqtitle",
          value: "6",
          path: "/jsxqlist",
          name: "Jsxqlist",
        },
        {
          id: 7,
          label: "wxxztitle",
          value: "7",
          path: "/wxxzlist",
          name: "Wxxzlist",
        },
      ],
    };
  },
  // inject: ['reload'],
  computed: {
    isLogin() {
      return this.$store.state.token;
    },
  },
  methods: {
    changLang(str){
      if(this.$store.state.cuLanguage==str)return
      this.$store.commit("setLanguage", str);
      this.$router.push({
        path: "/redirect" + this.$route.path,
        query: this.$route.query,
        params: this.$route.params,
      })
      this.$store.dispatch('changeLanguage', str)
    },
    changeLanguage(str) {
      this.$store.dispatch("changeLanguage", str)
    },
    goSearch() {
      if (!this.selects) {
        this.$message({
          type: "success",
          message: "请选择需要搜索的分类",
        });
        return;
      }
      // if (!this.se_keywords) {
      //   this.$message({
      //     type: "success",
      //     message: "请输入搜索关键字",
      //   });
      //   return;
      // }
      const selects = this.searchType.filter(
        (item) => item.name == this.selects
      );
      if (selects.length > 0) {
        // console.log(this.$route.name, selects[0].name);
        if (this.$route.name == selects[0].name) {
          this.$router.push({
            path: "/redirect" + selects[0].path,
            query: { keywords: this.se_keywords },
          });
        } else {
          this.$router.push({
            path: selects[0].path,
            query: { keywords: this.se_keywords },
          });
        }
      }
    },
    getKjdhList(id) {
      var that = this;
      that.$httpApi.common
        .get_link_list({
          group_id: id,
        })
        .then(function (res) {
          if (res.code == 200) {
            // 首页主banner
            if (id == 1) {
              if (res.data) {
                that.banner = res.data;
              }
            }
            // // 友情链接
            // if (id == 2) {
            //   that.friendship = res.data;
            // }
            // // 快捷导航大图标
            // if (id == 3) {
            //   that.kjdhList2 = res.data;
            // }
            // // 快捷导航小图标
            // if (id == 4) {
            //   that.kjdhList1 = res.data;
            // }
            // // 首页banner1
            // if (id == 5) {
            //   if (res.data[0]) {
            //     that.banner1 = res.data[0].image_url;
            //   }
            // }
            // // 首页banner2
            // if (id == 6) {
            //   if (res.data[0]) {
            //     that.banner2 = res.data[0].image_url;
            //   }
            // }
            // // 首页滚动列表
            // if (id == 7) {
            //   that.gdList = res.data;
            //   that.$nextTick(function() {
            //     $('.gdOneBox').each(function () {
            //       var that = $(this);
            //       setInterval(function () {
            //         gdOneFunc(that)
            //       }, 3000);
            //     });
            //   });
            // }
          }
        });
    },
    getTime() {
      this.timeI = setInterval(() => {
        var cDate = new Date();
        var year = cDate.getFullYear();
        var month = Number(cDate.getMonth() + 1) + "";
        var date = cDate.getDate() + "";
        var hour =
          cDate.getHours() < 10 ? "0" + cDate.getHours() : cDate.getHours();
        var minute =
          cDate.getMinutes() < 10
            ? "0" + cDate.getMinutes()
            : cDate.getMinutes();
        var second =
          cDate.getSeconds() < 10
            ? "0" + cDate.getSeconds()
            : cDate.getSeconds();
        var week = new Array(
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六"
        )[cDate.getDay()];
        if (date.length == 1) {
          date = "0" + date;
        }
        if (month.length == 1) {
          month = "0" + month;
        }
        this.ytimeStr = year + "-" + month + "-" + date;
        this.stimeStr = hour + ":" + minute + ":" + second;
        this.wtimeStr = week;
        // this.timeStr = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second + '  ' + day
      }, 1000);
    },
    goHome() {
      if (this.$route.path == "/") return;
      this.$router.push({ path: "/" });
    },
    goLogin() {
      if (this.$route.path == "/login") return;
      this.$router.push({ path: "/login" });
    },
    goRegister() {
      if (this.$route.path == "/pageregister") return;
      this.$router.push({ path: "/pageregister" });
    },
    goUserCenter() {
      if (this.$route.path == "/center") return;
      this.$router.push({ path: "/user" });
    },
    get_dictionary_cate() {
      var that = this;
      that.query["cate_type[0]"] = "nav_menu";
      that.$httpApi.common.get_dictionary_cate(that.query).then(function (res) {
        if (res.data && res.data.nav_menu) {
          that.nav_menu = res.data.nav_menu;
          if (!that.navActive) {
            that.navActive = that.$route.path;
          }
        }
      });
    },
    getWeather() {
      var that = this;
      that.$httpApi.common.getWeather().then(function (res) {
        if (res.data) {
          that.weather = res.data;
        }
      });
    },
    goLinkNav(row) {
      if (!row.uri) return;
      if (this.$route.path == "/articlelist") {
        if (row.uri == "/articlelist?c_id=3") {
          return;
        }
      }
      if (this.$route.path == "/kjhzlist") {
        if (row.uri == "/kjhzlist?c_id=4") {
          return;
        }
      }
      if (row.uri == this.$route.path || row.uri == this.$route.fullPath)
        return;
      // this.navActive = row.uri
      if (new RegExp("^http.*$").test(row.uri)) {
        if(row.uri.includes("qikan.cqvip.com")){
          if (!this.isLogin) {
            this.$confirm(this.cuLangData.swdlsfjx, this.cuLangData.ts, {
                confirmButtonText:  this.cuLangData.ok,
                cancelButtonText:  this.cuLangData.cancel,
                type: "warning",
              }).then(() => {
                this.$router.push({path:'/login'});
              }).catch(() => {
                // this.$router.go(-1);
                // this.$message({
                //   type: 'info',
                //   message: '已取消删除'
                // });
              });
          }else{
            window.open(row.uri);
          }
        }else{
          window.open(row.uri);
        }
        // window.open(row.uri);
      } else {
        this.$router.push({ path: row.uri });
      }
      // this.$router.push({ path: row.uri });
      // if(this.$route.path == row.uri){
      //   this.$router.push({ path: '/redirect'+ this.selects.path,query:{ keywords: this.se_keywords}})
      // }else{
      //   this.$router.push({ path: row.uri});
      // }
      // window.open(encodeURI(row.uri+'.html?'+'c_id='+row.id))
    },
    goUrl(row) {
      if (!row.url) return;
      if (new RegExp("^http.*$").test(row.url)) {
        window.open(row.url);
      } else {
        this.$router.push({ path: row.url });
      }
    },
  },

  // computed: {
  //   isNavActive(){
  //     return (url)=>{
  //       if(new RegExp("^http.*$").test(row.url)) {

  //       }else{
  //         this.$router.push({ path: row.url});
  //       }
  //       return true;
  //     }
  //   }
  // },
  watch: {
    $route(to, from) {
      if (to.path == "/articlelist") {
        this.navActive = "/articlelist?c_id=3";
      } else if (to.path == "/kjhzlist") {
        this.navActive = "/kjhzlist?c_id=4";
      } else {
        this.navActive = to.path;
      }
      if (this.$route.query.keywords) {
        this.se_keywords = this.$route.query.keywords;
        const selects = this.searchType.filter(
          (item) => item.name == this.$route.name
        );
        if (selects.length > 0) {
          this.selects = selects[0].name;
        }
      } else {
        this.selects = "";
        this.se_keywords = "";
      }

      // console.log(from.path);//从哪来
      // console.log(to.fullPath);//到哪去
      // console.log(to);//到哪去
    },
  },
  created() {
    // console.log(this.$route.name);
    if (this.$route.path == "/articlelist") {
      this.navActive = "/articlelist?c_id=3";
    } else if (this.$route.path == "/kjhzlist") {
      this.navActive = "/kjhzlist?c_id=4";
    } else {
      this.navActive = this.$route.path;
    }
    if (this.$route.query.keywords) {
      this.se_keywords = this.$route.query.keywords;
      const selects = this.searchType.filter(
        (item) => item.name == this.$route.name
      );
      if (selects.length > 0) {
        this.selects = selects[0].name;
      }
    } else {
      this.selects = "";
      this.se_keywords = "";
    }
    this.changeLanguage();
    // this.getWeather();
    this.getKjdhList(1);
    this.getTime();
    this.get_dictionary_cate();
  },
};
</script>
<style>
.lang-img{
  width: 36px;
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
}
.banner-wrap {
  position: relative;
}
.banner-carousel-a {
  display: inline-block;
}
.banner-carousel {
  z-index: 1;
  width: 100%;
  height: 340px;
  position: absolute;
  top: 0;
  left: 0;
}
.banner-content {
  z-index: 1;
  pointer-events: none;
}
.search-wrap {
  z-index: 2;
  pointer-events: auto;
}
.banner-carousel .el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.banner-carousel .el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.banner-carousel .el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
