<template>
  <div class="top-header">
    <div class="base-project-width top-content">
      <div class="floatl" style="margin-left: 40px">
        <div class="project-logo" @click="goHome">德宏州科技管理信息系统</div>
        <div class="project-sub-logo">
          Dehong Prefecture Technology Management Information System
        </div>
      </div>
      <div class="floatr">
        <!-- <div class="text">当前在线人数：<span>275</span>人</div> -->
        <!-- <div class="line"></div> -->
        <div class="text">欢迎您，<span class="usena" @click="goUserCenter">{{ userinfoForm.nickname }}</span></div>
        <div class="text text1">
          工作提醒<el-badge :value="2" class="item" type="warning">
            <i class="iconfont icon-tixing"></i>
          </el-badge>
        </div>
        <div class="line"></div>
        <div class="text">
          <el-button size="small" v-if="isLogin" @click="loginOut">退出</el-button>
          <el-button size="small" v-else>登录</el-button>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>
    <div class="top-nav">
      <div class="base-project-width top-nav-wrap">
        <div class="top-nav-item" v-for="(item, i) in nav_menu" :class="acNav.includes(item.path)?'active':''" :key="'nav_menu'+i" @click="handleNavClick(item)">{{ item.name }}</div>
        <!-- <div class="top-nav-item active">首页</div>
        <div class="top-nav-item">申报管理</div>
        <div class="top-nav-item">立项管理</div>
        <div class="top-nav-item">项目验收管理</div>
        <div class="top-nav-item">系统管理</div> -->
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      acNav:'-1',
      nav_menu:[
        // {
        //   id:1,
        //   name:'首页',
        //   path:'/phome',
        // },
        {
          id:2,
          name:'申报管理',
          path:'/declarepro',
        },
        {
          id:3,
          name:'立项管理',
          path:'/projectapprovallist',
        },
        {
          id:4,
          name:'项目验收管理',
          path:'/projectacceptancelist',
        },
        {
          id:5,
          name:'系统管理',
          path:'/psystem',
        },
      ],
      userinfoForm:{
        nickname: '',
      }
    };
  },
  // inject: ['reload'],
  computed: {
    isLogin() {
      return this.$store.state.token;
    },
  },
  methods: {
    loginOut(){
      this.$confirm('即将离开本系统, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch("loginOut").then(() => {
            this.$message({
              type: 'success',
              message: '退出成功!'
            });
            this.$router.push({path:'/pjapp'});
          })
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    getUserInfo() {
      var that = this;
      that.$httpApi.usercenter.getUserInfo().then(function (res) {
        if (res.code == 200) {
          that.userinfoForm = {
            nickname: res.data.nickname,
            email: res.data.email,
            address: res.data.address,
            career: res.data.career,
            qq: res.data.qq,
            avatar: res.data.avatar,
            province_id:res.data.province_id,
            city_id:res.data.city_id,
            area_id:res.data.area_id,
          };
        }
      });
    },
    handleNavClick(row){
      if (!row.path) return;
      if (this.$route.path.includes(row.path) || this.$route.fullPath.includes(row.path))
        return;
      // this.navActive = row.uri
      if (new RegExp("^http.*$").test(row.path)) {
        window.open(row.path);
      } else {
        this.$router.push({ path: row.path });
      }
    },
    getTime() {
      this.timeI = setInterval(() => {
        var cDate = new Date();
        var year = cDate.getFullYear();
        var month = Number(cDate.getMonth() + 1) + "";
        var date = cDate.getDate() + "";
        var hour =
          cDate.getHours() < 10 ? "0" + cDate.getHours() : cDate.getHours();
        var minute =
          cDate.getMinutes() < 10
            ? "0" + cDate.getMinutes()
            : cDate.getMinutes();
        var second =
          cDate.getSeconds() < 10
            ? "0" + cDate.getSeconds()
            : cDate.getSeconds();
        var week = new Array(
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六"
        )[cDate.getDay()];
        if (date.length == 1) {
          date = "0" + date;
        }
        if (month.length == 1) {
          month = "0" + month;
        }
        this.ytimeStr = year + "-" + month + "-" + date;
        this.stimeStr = hour + ":" + minute + ":" + second;
        this.wtimeStr = week;
        // this.timeStr = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second + '  ' + day
      }, 1000);
    },
    goHome() {
      if (this.$route.path == "/") return;
      this.$router.push({ path: "/" });
    },
    goLogin() {
      if (this.$route.path == "/login") return;
      this.$router.push({ path: "/login" });
    },
    goRegister() {
      if (this.$route.path == "/register") return;
      this.$router.push({ path: "/register" });
    },
    goUserCenter() {
      if (this.$route.path == "/center") return;
      this.$router.push({ path: "/user" });
    },
    get_dictionary_cate() {
      var that = this;
      that.query["cate_type[0]"] = "nav_menu";
      that.$httpApi.common.get_dictionary_cate(that.query).then(function (res) {
        if (res.data && res.data.nav_menu) {
          that.nav_menu = res.data.nav_menu;
          if (!that.navActive) {
            that.navActive = that.$route.path;
          }
        }
      });
    },
    getWeather() {
      var that = this;
      that.$httpApi.common.getWeather().then(function (res) {
        if (res.data) {
          that.weather = res.data;
        }
      });
    },
    goLinkNav(row) {
      if (!row.uri) return;
      if (row.uri == this.$route.path || row.uri == this.$route.fullPath)
        return;
      // this.navActive = row.uri
      if (new RegExp("^http.*$").test(row.uri)) {
        window.open(row.uri);
      } else {
        this.$router.push({ path: row.uri });
      }
      // this.$router.push({ path: row.uri });
      // if(this.$route.path == row.uri){
      //   this.$router.push({ path: '/redirect'+ this.selects.path,query:{ keywords: this.se_keywords}})
      // }else{
      //   this.$router.push({ path: row.uri});
      // }
      // window.open(encodeURI(row.uri+'.html?'+'c_id='+row.id))
    },
  },
  // computed: {
  //   isNavActive(){
  //     return (url)=>{
  //       if(new RegExp("^http.*$").test(row.url)) {

  //       }else{
  //         this.$router.push({ path: row.url});
  //       }
  //       return true;
  //     }
  //   }
  // },
  watch: {
    $route(to, from) {
      if (to.path == "/articlelist") {
        this.acNav = "/articlelist?c_id=3";
      } else {
        this.acNav = to.fullPath;
      }
      // console.log(from.path);//从哪来
      // console.log(to.fullPath);//到哪去
      // console.log(to);//到哪去
    },
  },
  created() {
    this.acNav = this.$route.fullPath;
    this.getUserInfo();
    // this.getWeather();
    // this.getKjdhList(1);
    // this.getTime();
    // this.get_dictionary_cate();
  },
};
</script>

<style>
.top-header{
  padding-top: 23px;
}
.top-header .top-content{
  margin: 0 auto;
  padding-bottom: 17px;
}
.top-header .top-content .project-logo{
  font-size: 36px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}
.top-header .top-content .project-sub-logo{
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.top-header .top-content .line{
  display: inline-block;
  vertical-align: middle;
  width: 2px;
  height: 21px;
  background: #CCCCCC;
  margin: 0 30px;
}
.top-header .top-content .text{
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  /* padding-top: 20px; */
}
.top-header .top-content .text.text1{
  margin-left: 40px;
}
.top-header .top-content .text .usena{
  font-weight: bold;
  color: #3C6CFE;
  font-size: 18px;
  cursor: pointer;
}

.top-header .top-content .text i{
  font-size: 21px;
  color: #CCCCCC;
}
.top-header .top-content .floatr{
  padding-top: 20px;
}

.top-header .top-nav{
  height: 40px;
  background: #3C6CFE;
  min-width: 1460px;
}
.top-header .top-nav .top-nav-wrap{
  margin: 0 auto;
}
.top-header .top-nav .top-nav-wrap .top-nav-item{
  display: inline-block;
  line-height: 40px;
  padding: 0 37px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
}
.top-header .top-nav .top-nav-wrap .top-nav-item.active,
.top-header .top-nav .top-nav-wrap .top-nav-item:hover{
  background-color: #FFB302;
  color: #FFFFFF;
}
</style>