import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import httpApi from "@/api/index"
import VueSignaturePad from "vue-signature-pad";
Vue.config.productionTip = false
Vue.prototype.$httpApi = httpApi
Vue.use(ElementUI);
Vue.use(VueSignaturePad);
Vue.filter("phoneDeal", function (phone) {
  return phone.replace(/(\d{3})\d+(\d{2})/, '$1******$2');
});

Vue.prototype.is = function (value) {
    return value => value === 'login' && store.getters.token;
};
Vue.mixin({
  computed:{
    cuLangData(){
      return this.$store.state.cuLangData
    },
    langData(){
      return this.$store.state.cuLanguage1
    },
    homeLanguage(){
      return this.$store.state.cuHomeLanguage
    },
    cuCentserLanguage(){
      return this.$store.state.cuCentserLanguage
    }
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
