'use strict';

exports.__esModule = true;
exports.default = {
  el: {
    colorpicker: {
      "confirm": "ຕົກລົງ",
      "clear": "ເປົ່າ"
  },
    datepicker: {
      "now": "ດຽວນີ້",
      "today": "ມື້ນີ້",
      "cancel": "ຍົກເລີກ",
      "clear": "ເປົ່າ",
      "confirm": "ຕົກລົງ",
      "selectDate": "ເລືອກ ວັນທີ",
      "selectTime": "ເລືອກ ເວລາ",
      "startDate": "ວັນທີ ເ ລີ່ ມ ຕົ້ນ",
      "startTime": "ເວລາ ເ ລີ່ ມ ຕົ້ນ",
      "endDate": "ວັນທີ ສິ້ນສຸດ",
      "endTime": "ເວລາ ສິ້ນສຸດ",
      "prevYear": "ປີກ່ອນ",
      "nextYear": "ປີ ຕໍ່ມາ",
      "prevMonth": "ເດືອນທີ່ຜ່ານມາ",
      "nextMonth": "ເດືອນ ຖັດ ໄປ",
      "year": "年",
      "month1": "1 ເດືອນ",
      "month2": "2 ເດືອນ",
      "month3": "3 ເດືອນ",
      "month4": "4 ເດືອນ",
      "month5": "5 ເດືອນ",
      "month6": "ເດືອນມິຖຸນາ",
      "month7": "ເດືອນ ກ ໍ ລະ ກົດ",
      "month8": "ເດືອນ ສິງຫາ",
      "month9": "ເດືອນ ກັນຍາ",
      "month10": "10 ເດືອນ",
      "month11": "ພະ ຈິກ",
      "month12": "ເດືອນ ທັນວາ",
      // week: '周次',
      weeks: {
        sun: '日',
        mon: '一',
        tue: '二',
        wed: '三',
        thu: '四',
        fri: '五',
        sat: '六'
      },
      months: {
        "jan": "ມັງກອນ",
        "feb": "ເດືອນ ກຸມພາ",
        "mar": "ເດືອນມີນາ",
        "apr": "ເດືອນເມສາ",
        "may": "ພຶດສະພາ",
        "jun": "ມິຖຸນາ",
        "jul": "ເດືອນ ກ ໍ ລະ ກົດ",
        "aug": "ເດືອນ ສິງຫາ",
        "sep": "ເດືອນ ກັນຍາ",
        "oct": "ເດືອນ ຕຸລາ",
        "nov": "ເດືອນພະຈິກ",
        "dec": "ທັນວາ"
    }
    },
    select: {
      "loading": "ກຳ ລ ັ ງ ໂ ຫ ລ ດ",
      "noMatch": "ບໍ່ມີ ຂໍ້ ມູນ ທີ່ ກົງ ກັນ",
      "noData": "ບໍ່ມີ ຂໍ້ ມູນ",
      "placeholder": "ກະ ລຸ ນາ ເລືອກ"
  },
    cascader: {
      "noMatch": "ບໍ່ມີ ຂໍ້ ມູນ ທີ່ ກົງ ກັນ",
      "loading": "ກຳ ລ ັ ງ ໂ ຫ ລ ດ",
      "placeholder": "ກະ ລຸ ນາ ເລືອກ",
      "noData": "ບໍ່ມີ ຂໍ້ ມູນ"
  },
    pagination:{
      "goto": "ໄປ",
      "pagesize": "ແຖບ/ໜ້າ",
      "total": "ຈຳ ນວນ ທັງ ໝ ົ ດ {total}",
      "pageClassifier": "页"
  },
    messagebox: {
      "title": "ຄຳ ແນະ ນຳ",
      "confirm": "ຕົກລົງ",
      "cancel": "ຍົກເລີກ",
      "error": "ຂໍ້ ມູນ ທີ່ ປ້ອນ ເຂົ້າ ແມ່ນ ຜິດ ກົດ ໝາ ຍ!"
  },
    upload: {
      "deleteTip": "ກົດ ປຸ່ມ ລົບ ເພື່ອ ລຶບ",
      "delete": "ລົບ",
      "preview": "ເບິ່ງ ຮູບ",
      "continue": "ສືບຕໍ່ ອ ັ ບ ໂ ຫ ລ ດ"
  },
    table: {
      "emptyText": "ບໍ່ມີ ຂໍ້ ມູນ",
      "confirmFilter": "ການ ຄັດເລືອກ",
      "resetFilter": "ຕັ້ງ ຄ່າ ໃ ໝ ່",
      "clearFilter": "ທັງ ໝ ົ ດ",
      "sumText": "ລວມ"
  },
    tree: {
      "emptyText": "ບໍ່ມີ ຂໍ້ ມູນ"
  },
    transfer: {
      noMatch: "ບໍ່ມີ ຂໍ້ ມູນ ທີ່ ກົງ ກັນ",
      noData: "ບໍ່ມີ ຂໍ້ ມູນ",
      titles: ['ລາຍການ 1', 'ລາຍການ ທີ 2'],
      filterPlaceholder: "ກະ ລຸ ນາ ໃສ່ ການຄົ້ນຫາ",
      noCheckedFormat: '共 {total} 项',
      hasCheckedFormat: 'ເລືອກ {checked}/{total} 项'
    },
    image:{
      "error": "ການ ໂ ຫ ຼ ດ ລົ້ມ ເ ຫ ລ ວ"
  },
    pageHeader:{
      "title": "ກັບ ຄືນ"
  },
    popconfirm: {
      "confirmButtonText": "ຕົກລົງ",
      "cancelButtonText": "ຍົກເລີກ"
  },
    empty: {
      "description": "ບໍ່ມີ ຂໍ້ ມູນ"
  }
  }
};