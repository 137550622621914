import request from "@/utils/request"
import request1 from "@/utils/request1"
import request2 from "@/utils/request2"
import request4 from "@/utils/request4"

var common = {
  fanyi(data){
    return request4.postJSON("/t_list",data)
  },
  getWeather(data){
    return request.get("/weather",data)
  },
  getSystemConfig(data){
    return request.get("/system-config",data)
  },
  get_dictionary_cate(data){
    return request.get("/get_dictionary_cate",data)
  },
  get_link_list(data){
    return request.get("/link/list",data)
  },
  userLogin(data){
    return request.postFROM("/login",data)
  },
  userRegister(data){
    return request.postFROM("/register",data)
  },
  getWinXin(data){
    return request.get("/wechat_oath_url",data)
  },
  sendCode(data){
    return request.postFROM("/send-code",data)
  },
  // uploadImage(data){
  //   return request.postFROM("/upload_image",data)
  // },
  getArea(data){
    return request.get("/get_area",data)
  },
  // 换绑手机号
  changeMobile(data){
    return request.postFROM("/change-mobile",data)
  },
  // 忘记密码
  forgetMobile(data){
    return request.postFROM("/forget-password",data)
  },
}

var home = {
  articleList(data){
    return request.get("/article/article-list",data)
  },
}

var article = {
  articleList(data){
    return request.get("/article/list",data)
  },
  articleDetail(data){
    return request.get("/article/article",data)
  }
}

var kjcg = {
  kjcgFilter(data){
    return request.get("/technology-achieve/classify-list",data)
  },
  kjcgAuthList(data){
    return request.get("/technology-achieve/auth-list",data)
  },
  kjcgList(data){
    return request.get("/technology-achieve/list",data)
  },
  kjcgDetail(data){
    return request.get("/technology-achieve/detail",data)
  },
  getKjcgEdit(data){
    return request.get("/technology-achieve/edit",data)
  },
  addKjcgEdit(data){
    return request.postFROM("/technology-achieve/edit",data)
  },
  kjcgDelete(data){
    return request.get("/technology-achieve/delete",data)
  }
}



var jsxq = {
  jsxqList(data){
    return request.get("/technical-need/list",data)
  },
  jsxqAuthList(data){
    return request.get("/technical-need/auth-list",data)
  },
  getJsxqEdit(data){
    return request.get("/technical-need/edit",data)
  },
  addJsxqEdit(data){
    return request.postFROM("/technical-need/edit",data)
  },
  jsxqDetail(data){
    return request.get("/technical-need/detail",data)
  },
  jsxqDelete(data){
    return request.get("/technical-need/delete",data)
  },
}

var zjzk = {
  zjzkList(data){
    return request.get("/expert/list",data)
  },
  zjzkAuthList(data){
    return request.get("/expert/auth-list",data)
  },
  // getZjzkEdit(data){
  //   return request.get("",data)
  // },
  zjzkDetail(data){
    return request.get("/expert/detail",data)
  },
  addZjzkEdit(data){
    return request.postFROM("/register-expert",data)
  },
  editZjzkEdit(data){
    return request.postFROM("/edit-expert",data)
  },
  zjzkDelete(data){
    return request.get("/expert/delete",data)
  }
}


var usercenter = {
  getUserMenu(data){
    return request.get("/user-menu",data)
  },
  getUserInfo(data){
    return request.get("/get-user-info",data)
  },
  updatePassword(data){
    return request.postFROM("/update-password",data)
  },
  updateUserBasic(data){
    return request.postFROM("/update-user-basic",data)
  },
  updateUserInfo(data){
    return request.postFROM("/update-user-info",data)
  },
  updateExpertInfo(data){
    return request.postFROM("/update-expert-basic",data)
  },

  updateCorporate(data){
    return request.postFROM("/update-corporate",data)
  },
}


var wxxz = {
  wxxzkList(data){
    return request.get("/literature_list",data)
  },
}
var kjjr = {
  kjjrClassList(data){
    return request.get("/get_dictionary_cate?cate_type=technology_finance",data)
  },
}

var shenbao = {
  // 获取项目分类
  getProjectClassList(data){
    return request.get("/get_dictionary_cate",data)
  },
  // 获取项目列表
  getProjectList(data){
    return request.get("/get_project",data)
  },
  getDeclarerList(data){
    return request.get("/get-project-peoples",data)
  },
  addDeclarer(data){
    return request.postFROM("/register-project-people",data)
  },
  delDeclarer(data){
    return request.postFROM("/del-project-peoples",data)
  },
}


var leavemsg ={
  addLeavemsg(data){
    return request.postFROM("/set-message",data)
  },
  replyLeavemsg(data){
    return request.postFROM("/reply-message",data)
  },
  getMessage(data){
    return request.get("/get-message",data)
  },
  getMessageDetail(data){
    return request.get("/get-message-detail",data)
  },
  delMessage(data){
    return request.get("/del-message",data)
  },
  getMessagePeople(data){
    return request.get("/get-message-people",data)
  },
}

var kjtj = {
  kjtjList(data){
    return request.get("/get_technology_finance",data)
  },
  kjtjDetail(data){
    return request.get("/get_technology_finance_detail",data)
  }
}


var glkjtj = {
  glLjtjList(data){
    return request.get("/entry-statistic/auth-list",data)
  },
  glkjtjClass(data){
    return request.get("/entry-statistic/classify-list",data)
  },
  glkjtjDel(data){
    return request.get("/entry-statistic/delete",data)
  },
  glkjtjShow(data){
    return request.get("/entry-statistic/edit",data)
  },
  glkjtjEdit(data){
    return request.postFROM("/entry-statistic/edit",data)
  }
}

var glkjtj1 = {
  glLjtjList(data){
    return request.get("/kjtj/auth-list",data)
  },
  glkjtjDel(data){
    return request.get("/kjtj/delete",data)
  },
  glkjtjShow(data){
    return request.get("/kjtj/edit",data)
  },
  glkjtjEdit(data){
    return request.postFROM("/kjtj/edit",data)
  },
  setkjcgAllEdit(data){
    return request.postFROM("/set-kjcg-all",data)
  },
//
  getKjcgAll(data){
    return request.get("/get-kjcg-all",data)
  },
  getkjcgAllDetail(data){
    return request.get("/set-kjcg-all",data)
  },
  getKjcgOathDetail(data){
    return request.get("/get-kjcg-auth-detail",data)
  },
  delKjtj(data){
    return request.postFROM("/kjcg-all-delete",data)
  }
}


var glkjtj2 = {
  glLjtjList(data){
    return request.get("/demand-acquisition/auth-list",data)
  },
  glkjtjDel(data){
    return request.get("/demand-acquisition/delete",data)
  },
  glkjtjShow(data){
    return request.get("/demand-acquisition/edit",data)
  },
  glkjtjShow1(data){
    return request1.get("/demand-acquisition/detail",data)
  },
  glkjtjEdit(data){
    return request.postFROM("/demand-acquisition/edit",data)
  },
}

var xmsb = {
  getTzList(data){
    return request2.get("/flw/model/allListV2",data)
  },
}

export default {
  common,
  home,
  article,
  kjcg,
  jsxq,
  zjzk,
  wxxz,
  leavemsg,
  usercenter,
  shenbao,
  kjtj,
  kjjr,
  glkjtj,
  glkjtj1,
  glkjtj2,
  xmsb
}